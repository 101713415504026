import React from "react";
import ReactDOM from "react-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import useHttp from "../../hooks/useHttp";
import style from "../../assets/style/modal.module.css";
import { IoIosSave } from "react-icons/io";
import MenuGroup from "../menugroup/MenuGroups";

const AddMenuItemModal = ({ saveClick, cancleClick }) => {
  const [hasError, handleHttpRequest] = useHttp();

  const addMenuItem = async (values) => {
    const response = await handleHttpRequest({
      page: "menuitem/add",
      method: "POST",
      data: values,
    });
    if (!response.status) {
      saveClick(response);
    } else {
      saveClick(response.data);
    }
  };
  const closeModal = () => {
    formik.resetForm();
    cancleClick();
  };
  const formik = useFormik({
    initialValues: {
      name: "",
      price: "",
      menu_group_id: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Menu item name is required.")
        .max(100, "Menu item name is too long"),
      price: Yup.number()
        .typeError("Price must be a number")
        .required("Enter the price"),
      menu_group_id: Yup.number().required("Select a menu group for the menu."),
      // budget_percentage: Yup.number().typeError("Budget % must be a number"),
      // budget_percentage: Yup.string().required(
      //   "Select a Measurement for the Recipe."
    }),
    onSubmit: (values) => {
      addMenuItem(values);
    },
  });

  return (
    <>
      <div className={style["modal-overlay"]}></div>
      <div className={style["modal-card-wrapper"]}>
        <div className={`card  ${style["modal-card"]} w-75`} role="dialog">
          <div className="modal-dialog w-100">
            <div className="modal-content">
              <div
                className={` ${style["modal-header"]} text-bg-secondary`}
                data-bs-theme="dark"
              >
                <h5 className="modal-title" id="staticBackdropLabel">
                  NEW MENU ITEM
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={closeModal}
                  aria-label="Close"
                ></button>
              </div>
              <div className={style["modal-content"]}>
                <div className="modal-body row g-3">
                  <form className="" onSubmit={formik.handleSubmit}>
                    <div className="row">
                      <div className="col-sm-6">
                        <fieldset>
                          <label
                            className="lblrequired lbltheme"
                            htmlFor="name"
                          >
                            Menu Item Name*
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </fieldset>
                      </div>

                      <div className="col-lg-3 col-sm-5">
                        <fieldset>
                          <label htmlFor="menu_group_id" className="lbltheme">
                            Menu Group
                          </label>
                          <MenuGroup
                            onMenuGroupSave={false}
                            value={formik.values.menu_group_id}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </fieldset>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-3 col-sm-6">
                        <fieldset>
                          <label
                            htmlFor="ingred-dfltunitmeas"
                            className="lblrequired lbltheme"
                          >
                            $Sales Price*
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="price"
                            name="price"
                            value={formik.values.price}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </fieldset>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div
                        id="qadd-ingred-errmsg"
                        className="col-sm-6 align-self-center error-msg"
                      >
                        {formik.errors.name
                          ? formik.errors.name
                          : formik.errors.price
                          ? formik.errors.price
                          : formik.errors.menu_group_id
                          ? formik.errors.menu_group_id
                          : null}
                      </div>
                      <div className="col-sm-6 d-flex align-self-end justify-content-end">
                        <button
                          type="submit"
                          className="btn btn-link btn-just-icon"
                          id="SaveIngred"
                          name="SaveIngred"
                          title="Save"
                        >
                          <IoIosSave className="save-icon" />
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const AddMenuItem = (props) => {
  return ReactDOM.createPortal(
    <AddMenuItemModal
      saveClick={props.saveClick}
      cancleClick={props.onClick}
    />,
    document.getElementById("modal")
  );
};

export default AddMenuItem;
