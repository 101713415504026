import React, { useState, useEffect } from "react";
import useHttp from "../../hooks/useHttp";

const SuppliersList = ({ value, onChange, onBlur }) => {
  const [suppliers, setSuppliers] = useState([]);
  const [hasError, handleHttpRequest] = useHttp();

  const getSuppliers = async () => {
    // const response = await handleHttpRequest('suppliers')
    const response = await handleHttpRequest({
      page: "suppliers",
      method: "GET",
    });

    if (!hasError) {
      setSuppliers(response.message);
    }
  };
  useEffect(() => {
    getSuppliers();
  }, []);

  return (
    <select
      class="form-select"
      name="supplier_id"
      id="supplier_id"
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    >
      <option selected>Select Supplier</option>
      {suppliers.map((supplier) => (
        <option key={supplier.id} value={supplier.id}>
          {supplier.name}
        </option>
      ))}
    </select>
  );
};
export default SuppliersList;

const SuppliersListByName = () => {
  return (
    <div>
      <input />
      <ul></ul>
    </div>
  );
};
