import React, { useState } from "react";

const FileImportMapping = ({ headers, handleColumnMapping, files }) => {
  const [itemFieldMapping, setItemFieldMapping] = useState(files);
  const handleMapping = (event) => {
    const { name, value } = event.target;
    setItemFieldMapping((prev) => ({ ...prev, [name]: value }));
    handleColumnMapping(event);
  };

  return (
    <>
      <div className="">
        <div className="row">
          <div className="col-sm-12 mt-2">
            <h5>Map import fields to the system</h5>
          </div>
          <div className="col-sm-9">
            <div className="row m-2">
              <div className="col-sm-6">
                <strong>Fields in the system</strong>
              </div>
              <div className="col-sm-6">
                <strong>Fields in file</strong>
              </div>
            </div>
            {files?.map((file, index) => {
              return (
                <div className="row m-2">
                  <div className="col-sm-6">
                    <label>{file.lable}</label>
                  </div>
                  <div className="col-sm-6">
                    <select
                      className="form-select"
                      id={file.name}
                      name={file.name}
                      value={itemFieldMapping[file.name]}
                      onChange={handleMapping}
                    >
                      <option value="">Select Column</option>
                      {headers.map((header) => {
                        return (
                          <option key={header} value={header}>
                            {header}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
export default FileImportMapping;
