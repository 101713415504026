import React, { useState, useEffect, useRef } from "react";
import MainPanel from "../../components/MainPanel";
import Table from "../../components/Table";
import QuickAddIngredients from "./QuickAddIngredients";
import AddCategory from "../categories/AddCategory";
import DeleteIngredient from "../ingredients/DeleteIngredient";
import AddSupplierIngredient from "../supplierIngredients/AddSupplierIngredient";
import Notification from "../../components/Notification";
import { FaRegEdit, FaPlus } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import AddIngredient from "./AddIngredient";
import { Toast } from "bootstrap";
import useHttp from "../../hooks/useHttp";
import EditSupplierIngredient from "../supplierIngredients/EditSupplierIngredient";
import { Link } from "react-router-dom";
import ImportExcel from "../imports/ImportExcel";
import { ToastContainer, toast } from "react-toastify";

const Ingredients = () => {
  /* Get Ingredients */
  const [ingredient, setIngredient] = useState([]);
  const [beUpdated, setBEUpdated] = useState(false);

  const [hasError, handleHttpRequest] = useHttp();
  const getIngredients = async () => {
    const response = await handleHttpRequest({
      page: "ingredients",
      method: "GET",
    });
    if (!hasError) {
      setIngredient(response.message);
    }
  };
  useEffect(() => {
    getIngredients();
  }, [beUpdated]);

  /* Get columns for the table of ingredients */
  const COLUMNS = [
    {
      Header: "Ingredient Name",
      accessor: "name",
    },
    {
      Header: "Ingredient Category",
      accessor: "category.name",
    },
    {
      Header: "Default Unit",
      accessor: "default_unit_measure",
    },
    {
      Header: "Last Price Default Unit",
      accessor: "default_unit_price",
      align: "right",
      Cell: ({ value }) => (value ? "$" + value : ""),
    },
    {
      Header: "Primary Supplier",
      accessor: "primary_supplier.name",
    },
    {
      Header: "Actions",
      accessor: "id",
      disableSortBy: true,
      Cell: (cell) => (
        <div>
          <button
            className="btn btn-link btn-just-icon icontheme editcount"
            style={{ color: "#FF7300" }}
          >
            <Link to={`/edit-ingredient/${cell.value}`} className="themecolor">
              <FaRegEdit />{" "}
            </Link>
          </button>
          <button
            className="btn btn-link btn-just-icon iconthemedel delcount"
            style={{ color: "#676767" }}
            onClick={() => {
              openDeleteModal(cell.value);
            }}
          >
            <RiDeleteBin6Line />
          </button>
        </div>
      ),
    },
  ];

  /** Add Ingredient Modal */
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const openAddModal = () => {
    setIsAddModalOpen((state) => !state);
  };
  const saveHandler = ({ status, message }) => {
    setIsAddModalOpen(false);
    // notificationMessage.current = message;
    // notificationStatus.current = status;
    status == 200 ? toast.info(message) : toast.error(message);
    setBEUpdated((state) => !state);
  };

  /* Quick Add Ingredient */
  const quickAddIngredient = (data) => {
    // notificationMessage.current = data.message;
    // notificationStatus.current = data.status;
    data.status == 200 ? toast.info(data.message) : toast.error(data.message);
    setBEUpdated((state) => !state);
  };

  /* Add Category Modal */
  const [isAddCategoryModalOpen, setIsAddCategoryModalOpen] = useState(false);
  const [categorySaved, setCategorySaved] = useState(false);
  const addCategory = () => {
    setIsAddCategoryModalOpen(true);
  };
  const closeAddCategory = () => {
    setIsAddCategoryModalOpen(false);
  };
  const addCategoryHandler = () => {
    setCategorySaved((state) => !state);
    setIsAddCategoryModalOpen(false);
  };

  /* Delete Ingredient Modal */
  const deleteIdIs = useRef(0);
  const [isdeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const openDeleteModal = (id) => {
    deleteIdIs.current = id;
    setIsDeleteModalOpen((state) => !state);
  };
  const deleteHandler = (data) => {
    setIsDeleteModalOpen(false);
    // notificationMessage.current = data[0];
    // notificationStatus.current = data[1];
    toast.info(data[0]);
    setBEUpdated((state) => !state);
  };

  /** Edit Ingredient Modal */
  // const editIdIs = useRef(0);
  // const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  // const openEditModal = (id) => {
  //   editIdIs.current = id;
  //   setIsEditModalOpen((state) => !state);
  // };
  // const editHandler = ({ status, message }) => {
  //   setIsEditModalOpen(false);
  //   notificationMessage.current = message;
  //   notificationStatus.current = status;
  //   setToast(true);
  //   setToastUpdated((state) => !state);
  //   setBEUpdated((state) => !state);
  // };

  /* Add Supplier Ingredient for an Ingredient */
  const [isAddSupplierIngModalOpen, setIsAddSupplierIngModalOpen] =
    useState(false);
  const ingId = useRef(0);
  const openAddSupplierIngredient = (id) => {
    ingId.current = id;
    setIsAddSupplierIngModalOpen(true);
  };
  const closeAddSupplierIngredient = () => {
    setIsAddSupplierIngModalOpen(false);
  };
  const saveSuppIng = () => {
    setIsAddSupplierIngModalOpen(false);
    // notificationMessage.current = "Supplier Ingredient created";
    // notificationStatus.current = 200;
    toast.info("Supplier Ingredient created.");
    setBEUpdated((state) => !state);
  };

  /* Add Supplier Ingredient for an Ingredient */
  const [isEditSupplierIngModalOpen, setIsEditSupplierIngModalOpen] =
    useState(false);
  const supplierIngId = useRef(0);
  const openEditSupplierIngredient = (id) => {
    supplierIngId.current = id;
    setIsEditSupplierIngModalOpen(true);
  };
  const closeEditSupplierIngredient = () => {
    setIsEditSupplierIngModalOpen(false);
  };
  const saveEditSuppIng = () => {
    setIsEditSupplierIngModalOpen(false);
    // notificationMessage.current = "Supplier Ingredient updated";
    // notificationStatus.current = 200;
    toast.info("Supplier Ingredient updated");
    setBEUpdated((state) => !state);
  };
  /** ----------------------------------------- */

  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const toggleImport = (type) => {
    setIsImportModalOpen((state) => !state);
  };
  const handleImportResponse = (response) => {
    setIsImportModalOpen(false);
  };
  const handleErrorResponse = (message) => {
    setIsImportModalOpen(false);
  };
  return (
    <MainPanel>
      {isImportModalOpen && (
        <ImportExcel
          onClose={toggleImport}
          onImport={handleImportResponse}
          onError={handleErrorResponse}
        />
      )}
      {isEditSupplierIngModalOpen && (
        <EditSupplierIngredient
          supplierIngId={supplierIngId.current}
          saveClick={saveEditSuppIng}
          onClick={closeEditSupplierIngredient}
        />
      )}
      {isAddSupplierIngModalOpen && (
        <AddSupplierIngredient
          ingredient_id={ingId}
          saveClick={saveSuppIng}
          onClick={closeAddSupplierIngredient}
        />
      )}
      {isAddCategoryModalOpen && (
        <AddCategory
          saveClick={addCategoryHandler}
          onClick={closeAddCategory}
        />
      )}
      {isdeleteModalOpen && (
        <DeleteIngredient
          deleteId={deleteIdIs.current}
          onClick={openDeleteModal}
          onDelete={deleteHandler}
        />
      )}
      {isAddModalOpen && (
        <AddIngredient
          categorySaved={categorySaved}
          onCategoryOpen={addCategory}
          onClick={openAddModal}
          saveClick={saveHandler}
        />
      )}

      <div className="card">
        <div className="card-header text-bg-secondary">
          <div className="row">
            <h4 className="card-title">
              INGREDIENTS
              <button
                type="button"
                className="btn btn-outline-light ms-3"
                name="AddIngredient"
                title="New Ingredient"
                style={{ padding: "4px 8px 7px" }}
                onClick={openAddModal}
              >
                <FaPlus />
              </button>
            </h4>
          </div>
        </div>
        <div className="card-body">
          <QuickAddIngredients saveIngredient={quickAddIngredient} />
          <hr></hr>
          <div className="toolbar"></div>
          <Table data={ingredient} columns={COLUMNS} />
        </div>
      </div>
      {/* <Notification
        toastRef={toastRef}
        toast={toast}
        setToast={setToast}
        notificationStatus={notificationStatus.current}
      >
        {notificationMessage.current}
      </Notification> */}
      <ToastContainer position="bottom-right" hideProgressBar />
    </MainPanel>
  );
};

export default Ingredients;
