import MainPanel from "../../components/MainPanel";
import { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import useHttp from "../../hooks/useHttp";
import Table from "../../components/Table";
import { FaRegEdit, FaPlus, FaFileImport } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdOutlineDoNotDisturbAlt } from "react-icons/md";
import AddMenuItem from "./AddMenuItem";
import DeleteModal from "../DeleteModal";
import SetStatusModal from "../SetStatusModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ImportMenuItems from "../imports/ImportMenuItems";

const MenuItems = () => {
  // const menuItems = [];
  const [_, handleHttpRequest] = useHttp();
  const [isMenuItemUpdated, setIsMenuItemUpdated] = useState();
  const [menuItems, setMenuItems] = useState([]);
  const getMenuItems = async () => {
    const response = await handleHttpRequest({
      page: "menuitems",
      method: "GET",
    });
    if (!response.status) {
      setMenuItems(response);
    }
  };
  useEffect(() => {
    getMenuItems();
  }, [isMenuItemUpdated]);

  const COLUMNS = [
    {
      Header: "Menu Group",
      accessor: "menu_group",
    },
    {
      Header: "Menu Item",
      accessor: "name",
    },
    {
      Header: "Sales price",
      accessor: "price",
      align: "right",
      Cell: ({ value }) => (value != undefined ? "$" + value.toFixed(2) : ""),
    },
    {
      Header: "Cost $",
      accessor: "menuitem_cost",
      align: "right",
      Cell: ({ value }) => (value != undefined ? "$" + value.toFixed(2) : ""),
    },
    // {
    //   Header: "Cost %",
    //   accessor: "menuitem_cost_perc",
    //   Cell: ({ value }) => (value != undefined ? value.toFixed(2) + "%" : ""),
    // },
    {
      Header: "Status",
      accessor: "status",
      disableSortBy: true,
      Cell: (cell) => (
        <span
          className={`badge rounded-pill ${
            cell.value ? "text-bg-primary" : "text-bg-danger"
          }`}
          style={{ color: "#000" }}
        >
          {cell.value == 1 ? "Active" : "In-active"}
        </span>
      ),
    },
    {
      Header: "Actions",
      accessor: "id",
      disableSortBy: true,
      Cell: (cell) => (
        <div>
          <button
            className="btn btn-link btn-just-icon icontheme editcount"
            style={{ color: "#FF7300" }}
          >
            <Link to={`/edit-menu-item/${cell.value}`} className="themecolor">
              <FaRegEdit />{" "}
            </Link>
          </button>
          {/* <button
            className="btn btn-link btn-just-icon iconthemedel delcount"
            style={{ color: "#676767" }}
            onClick={() => {
              openDeleteModal(cell.value);
            }}
          >
            <RiDeleteBin6Line />
          </button> */}
          <button
            className="btn btn-link btn-just-icon iconthemedel delcount"
            style={{ color: "#676767" }}
            onClick={() => {
              openDeactivateModal(cell.value, cell.row.original.status);
            }}
          >
            <MdOutlineDoNotDisturbAlt />
          </button>
        </div>
      ),
    },
  ];
  const recipeId = useRef(0);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const openAddModal = () => {
    setIsAddModalOpen((state) => !state);
  };
  const saveMenuItem = (message) => {
    setIsAddModalOpen(false);
    toast.info(message);
    setIsMenuItemUpdated((state) => !state);
  };

  /* Delete Modal */
  const deleteIdIs = useRef(0);
  const [isdeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const openDeleteModal = (id) => {
    deleteIdIs.current = id;
    setIsDeleteModalOpen((state) => !state);
  };
  const deleteHandler = (data) => {
    setIsDeleteModalOpen(false);
    toast.info(data[0]);
    setIsMenuItemUpdated((state) => !state);
  };

  /** Deactivate Modal   */
  const deactivateIdIs = useRef(0);
  const menuItemStatus = useRef();
  const [isdeactivateModalOpen, setIsDeactivateModalOpen] = useState(false);
  const openDeactivateModal = (id, status) => {
    deactivateIdIs.current = id;
    menuItemStatus.current = status;
    setIsDeactivateModalOpen((state) => !state);
  };
  const deactivateHandler = (response) => {
    // console.log(response);
    setIsDeactivateModalOpen(false);
    response[1] === 200 ? toast.info(response[0]) : toast.error(response[0]);
    setIsMenuItemUpdated((state) => !state);
  };

  /* Import Modal */
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const toggleImport = (type) => {
    setIsImportModalOpen((state) => !state);
  };
  const handleImportResponse = (response) => {
    setIsImportModalOpen(false);
    // toast.info(response);
    // setIsSalesUpdated((state) => !state);
  };
  const handleErrorResponse = (message) => {
    setIsImportModalOpen(false);
    // toast.error(message);
  };

  return (
    <>
      <MainPanel>
        {isImportModalOpen && (
          <ImportMenuItems
            onClose={toggleImport}
            onImport={handleImportResponse}
            onError={handleErrorResponse}
          />
        )}
        {
          isAddModalOpen && (
            <AddMenuItem saveClick={saveMenuItem} onClick={openAddModal} />
          )
          //recipe_id={recipeId}
        }

        {/* {isdeleteModalOpen && (
          <DeleteModal
            deleteId={deleteIdIs.current}
            onClick={openDeleteModal}
            onDelete={deleteHandler}
            item="menuitem"
            name="menu item"
          />
        )} */}
        {isdeactivateModalOpen && (
          <SetStatusModal
            currentStatus={menuItemStatus.current}
            itemId={deactivateIdIs.current}
            onClick={openDeactivateModal}
            onToggle={deactivateHandler}
            item="menuitem"
            name="menu item"
          />
        )}

        <div className="card">
          <div className="card-header text-bg-secondary">
            <div className="row">
              <h4 className="card-title">
                MENU ITEMS
                <button
                  type="button"
                  className="btn btn-outline-light ms-3"
                  name="AddMenuItems"
                  title="New Menu Items"
                  style={{ padding: "4px 8px 7px" }}
                  onClick={openAddModal}
                >
                  <FaPlus />
                </button>
                {/* <button
                  type="button"
                  className="btn btn-outline-light ms-3"
                  name="AddRecipe"
                  title="New Recipe"
                  style={{ padding: "4px 8px 7px" }}
                  onClick={toggleImport}
                >
                  <FaFileImport />
                </button> */}
              </h4>
            </div>
          </div>
          <div className="card-body">
            {/* {menuItems.map((menus) => { */}
            {/* // <div className="card">
              //   <div className="card-header text-bg-secondary">
              //     <div className="row">
              //       <h4 className="card-title">{menuItems.name}</h4>
              //     </div>
              //   </div>
              //   <div className="card-body">
              //     <div className="toolbar"></div> */}
            <Table data={menuItems} columns={COLUMNS} />
            {/* </div>
              </div>; */}
            {/* })} */}
          </div>
        </div>
      </MainPanel>
      <ToastContainer position="bottom-right" hideProgressBar />
    </>
  );
};

export default MenuItems;
