const ListCardHeader = ({ title, children, buttonInput, onClick }) => {
  return (
    <div className="card-header text-bg-secondary">
      <div className="row">
        <h4 className="card-title text-uppercase">
          {title}
          {children}
          {/* <button
            type="button"
            {...buttonInput}
            style={{ padding: "4px 8px 7px" }}
            onClick={onClick}
          >
            <FaPlus />
          </button> */}
        </h4>
      </div>
    </div>
  );
};
export default ListCardHeader;
