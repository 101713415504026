const SelectIngredientsByLocation = ({
  ingredientsForLocation,
  value,
  onChange,
  onBlur,
  setUnitMeasure,
  setIngredient,
}) => {
  const handleChange = (event) => {
    let ing = ingredientsForLocation.filter(
      (ingredientForLocation) =>
        ingredientForLocation.ingredient.id == event.target.value
    );
    onChange(event);
    setUnitMeasure(ing[0].ingredient.default_unit_measure);
    setIngredient(ing[0].ingredient);
  };

  return (
    <select
      class="form-select"
      name="ingredient_id"
      id="ingredient_id"
      value={value}
      onChange={handleChange}
      onBlur={onBlur}
    >
      <option selected>Select Ingredients</option>
      {ingredientsForLocation == undefined
        ? ""
        : ingredientsForLocation.length == 0
        ? "No ingredients in this location"
        : ingredientsForLocation.map((invIng) => (
            <>
              <option key={invIng.ingredient.id} value={invIng.ingredient.id}>
                {invIng.ingredient.name}{" "}
                <input
                  type="hidden"
                  name="ingredient"
                  value={invIng.ingredient}
                />
              </option>
            </>
          ))}
    </select>
  );
};
export default SelectIngredientsByLocation;
