import { FaRegEdit, FaPlus, FaMinus } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdOutlineDoNotDisturbAlt } from "react-icons/md";

const InventoryCountTable = ({
  data,
  setdata,
  storagelocation,
  editIngInventory,
}) => {
  const incrementQty = (index) => {
    const updatedInv = [...data];
    updatedInv[index] = {
      ...data[index],
      qty_on_hand: data[index].qty_on_hand + 1,
    };

    // setInventories(updatedQty);
    setdata(updatedInv);
  };
  const decrementQty = (index) => {
    const updatedInv = [...data];
    updatedInv[index] = {
      ...data[index],
      qty_on_hand:
        data[index].qty_on_hand > 0 ? data[index].qty_on_hand - 1 : 0,
    };

    // setInventories(updatedQty);
    setdata(updatedInv);
  };
  const setOutOfStock = (index) => {
    const updatedInv = [...data];
    updatedInv[index] = {
      ...data[index],
      qty_on_hand: 0,
    };

    // setInventories(updatedQty);
    setdata(updatedInv);
  };
  const deleteInv = (index) => {
    const updatedItem = data.filter((_, i) => i !== index);
    setdata(updatedItem);
  };

  return (
    <table className="table">
      <thead>
        <tr>
          <th>Location</th>
          <th>Ingredient</th>
          <th>Size On-Hand</th>
          <th>Qty On-Hand</th>
          <th>Qty in Dflt Unit</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {data.map((inventory, index) => {
          return (
            <tr>
              <td>{storagelocation}</td>
              <td>{inventory.ingredient.name}</td>
              <td>{inventory.size_on_hand}</td>
              <td>
                <button
                  className="btn btn-link btn-just-icon icontheme"
                  onClick={() => decrementQty(index, inventory.qty_on_hand)}
                >
                  <FaMinus className="icontheme" />
                </button>
                {inventory.qty_on_hand == 0 ? (
                  " "
                ) : (
                  <span>{inventory.qty_on_hand}</span>
                )}
                <button
                  className="btn btn-link btn-just-icon icontheme"
                  onClick={() => incrementQty(index, inventory.qty_on_hand)}
                >
                  <FaPlus className="icontheme" />
                </button>
              </td>
              <td>
                {inventory.qty_on_hand} {inventory.size_on_hand}
              </td>
              <td>
                <button
                  className="btn btn-link btn-just-icon icontheme editcount"
                  style={{ color: "#FF7300" }}
                  onClick={() => editIngInventory(inventory, index)}
                >
                  <FaRegEdit />{" "}
                </button>
                <button
                  className="btn btn-link btn-just-icon icontheme editcount"
                  style={{ color: "#FF7300" }}
                  onClick={() => setOutOfStock(index)}
                >
                  <MdOutlineDoNotDisturbAlt />{" "}
                </button>
                <button
                  className="btn btn-link btn-just-icon iconthemedel delcount"
                  style={{ color: "#676767" }}
                  onClick={() => {
                    deleteInv(index);
                  }}
                >
                  <RiDeleteBin6Line />
                </button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default InventoryCountTable;
