import axios from "axios";
import { useState } from "react";

const useHttp = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const csrfUrl = process.env.REACT_APP_API_URL_SANCTUM;
  const [hasError, setHasError] = useState(false);
  const token = localStorage.getItem("auth_token");

  const getCsrfToken = async () => {
    await axios.get(csrfUrl + "/csrf-cookie", {
      withCredentials: true,
    });
  };
  const handleHttpRequest = async ({ page, method = "GET", data = null }) => {
    try {
      // const response = await axios(`${url}/${page}`, {
      //   method: type,
      //   params: (type = "GET" ? data : null),
      //   data: (type = !"GET" ? data : null),
      //   headers: {
      //     Authorization: `Bearer  ${token}`,
      //   },
      // });

      const response = await axios(`${apiUrl}/${page}`, {
        method: method,
        data: data,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.statusText) {
        setHasError(true);
        // throw new Error('Couldn\'t fetch data');
      }
      return response.data;
    } catch (error) {
      //set is loading false
      //set error
      setHasError(true);
      return error.response;
    }
  };
  return [hasError, handleHttpRequest, getCsrfToken];
};

export default useHttp;
