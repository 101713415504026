import React from "react";
import { useState, useEffect } from "react";
import useHttp from "../../hooks/useHttp";
import { useFormik, ErrorMessage } from "formik";
import * as Yup from "yup";

const Category = ({ value, onChange, onBlur, categorySaved }) => {
  const [categories, setCategories] = useState([]);
  const [hasError, handleHttpRequest] = useHttp();
  // const [beUpdated, setBEUpdated] = useState(false);

  const getCategories = async () => {
    const response = await handleHttpRequest({
      page: "categories",
      method: "GET",
    });
    if (!hasError) {
      setCategories(response);
    }
  };
  useEffect(() => {
    getCategories();
  }, [categorySaved, value]);

  return (
    <select
      class="form-select"
      name="category_id"
      id="category_id"
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    >
      {/* value={formik.values.category_id} onChange={formik.handleChange} onBlur={formik.handleBlur}> */}
      <option selected>Select Category</option>
      {categories == undefined
        ? ""
        : categories.length == 0
        ? "No data"
        : categories.map((cateogry) => (
            <option key={cateogry.id} value={cateogry.id}>
              {cateogry.name}
            </option>
          ))}
    </select>
  );
};

export default Category;
