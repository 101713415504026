import React, { useState } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import useHttp from "../../hooks/useHttp";
import style from "../../assets/style/modal.module.css";

export const DeleteSupplierModal = ({ deleteId, onCancle, onDelete }) => {
  const [hasError, handleHttpRequest] = useHttp();

  const closeModal = () => {
    // deleteSupplier()
    onCancle();
  };
  const deleteSupplier = async (id) => {
    try {
      // const response = await handleHttpRequest('supplier/'+id, 'DELETE');

      const response = await handleHttpRequest({
        page: "supplier/" + id,
        method: "DELETE",
      });
      //const response = await axios.delete('http://127.0.0.1:9000/api/supplier/'+id);

      onDelete([response.message, response.status]);
    } catch (err) {
      onDelete([err.response.message, err.response.status]);
    }
  };

  return (
    <>
      <div className={style["modal-overlay"]}></div>
      <div className={style["modal-card-wrapper"]}>
        <div
          id={style["deleteModal"]}
          className={`card  ${style["modal-card"]}`}
          role="dialog"
        >
          <div className="modal-dialog w-100">
            <div className="modal-content">
              <div className={` ${style["modal-header"]}`}>
                <h5 className="modal-title" id="staticBackdropLabel">
                  Delete Supplier
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={closeModal}
                  aria-label="Close"
                ></button>
              </div>
              <div className={style["modal-content"]}>
                Delete this supplier?
              </div>
              <div className={style["modal-footer"]}>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => deleteSupplier(deleteId)}
                >
                  Confirm
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={closeModal}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const DeleteSupplier = (props) => {
  return ReactDOM.createPortal(
    <DeleteSupplierModal
      deleteId={props.deleteId}
      onCancle={props.onClick}
      onDelete={props.onDelete}
    />,
    document.getElementById("modal")
  );
};
export default DeleteSupplier;
