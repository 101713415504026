import MainPanel from "../../components/MainPanel";
import useHttp from "../../hooks/useHttp";
import Table from "../../components/Table";
import { useEffect, useState, useRef } from "react";
import AddNewInventoryCount from "./AddNewInventoryCount";
import { ToastContainer, toast } from "react-toastify";
import { Link, useLocation } from "react-router-dom";
import DeleteModal from "../DeleteModal";
import { FaRegEdit, FaPlus } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdViewList } from "react-icons/md";
import ImportIngredientsInventory from "../imports/ImportIngredientsInventory";

const Inventory = () => {
  const [_, handleHttpRequest] = useHttp();
  const [inventories, setInventories] = useState([]);
  const [isInventoryUpdated, setIsInventoryUpdated] = useState(false);

  const location = useLocation();
  const { response } = location.state || {};

  const getInventory = async () => {
    const response = await handleHttpRequest({
      page: "inventory-counts",
      method: "GET",
    });
    if (!response.status) {
      setInventories(response);
    } else {
      // saveClick(response, response.status); toast notification
    }
  };

  useEffect(() => {
    getInventory();
    response && toast.info(response);
  }, [isInventoryUpdated]);

  const COLUMNS = [
    {
      Header: "Inventory Date",
      accessor: "date_time",
    },
    {
      Header: "Inventory Location",
      accessor: "storagelocation.name",
      // Cell: ({value}) => formatPhoneNumber(value)
    },
    {
      Header: "Actions",
      accessor: "id",
      disableSortBy: true,
      Cell: (cell) => (
        <div>
          {cell.row.original.is_commited !== 1 ? (
            <button
              className="btn btn-link btn-just-icon icontheme editcount"
              style={{ color: "#FF7300" }}
            >
              <Link
                to={`/update-inventory-count/${cell.value}`}
                className="themecolor"
              >
                <FaRegEdit />{" "}
              </Link>
            </button>
          ) : (
            <button
              className="btn btn-link btn-just-icon icontheme editcount"
              style={{ color: "#FF7300" }}
            >
              <Link
                to={`/view-inventory-count/${cell.value}`}
                className="themecolor"
              >
                <MdViewList />{" "}
              </Link>
            </button>
          )}
          <button
            className="btn btn-link btn-just-icon iconthemedel delcount"
            style={{ color: "#676767" }}
            onClick={() => {
              openDeleteModal(cell.value);
            }}
          >
            <RiDeleteBin6Line />
          </button>
        </div>
      ),
    },
  ];

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const openAddModal = () => {
    setIsAddModalOpen((state) => !state);
  };
  const saveInventoryCount = (message, status) => {
    setIsAddModalOpen(false);
    setIsInventoryUpdated((state) => !state);
    status != 200 ? toast.error(message) : toast.info(message);
  };

  /* Delete Modal */
  const deleteIdIs = useRef(0);
  const [isdeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const openDeleteModal = (id) => {
    deleteIdIs.current = id;
    setIsDeleteModalOpen((state) => !state);
  };
  const deleteHandler = (data) => {
    setIsDeleteModalOpen(false);
    toast.info(data[0]);
    setIsInventoryUpdated((state) => !state);
  };

  /** Import File */
  const [isIngredientInventoryImportOpen, setIsIngredientInventoryImportOpen] =
    useState(false);
  const toggleIngredientInventoryImport = (type) => {
    setIsIngredientInventoryImportOpen((state) => !state);
  };
  const handleIngredientInventoryImportResponse = (response) => {
    setIsIngredientInventoryImportOpen(false);
    toast.info(response);
    // setIsSalesUpdated((state) => !state);
  };
  const handleIngredientInventoryErrorResponse = (message) => {
    setIsIngredientInventoryImportOpen(false);
    toast.error(message);
  };

  return (
    <>
      <MainPanel>
        {isIngredientInventoryImportOpen && (
          <ImportIngredientsInventory
            onClose={toggleIngredientInventoryImport}
            onImport={handleIngredientInventoryImportResponse}
            onError={handleIngredientInventoryErrorResponse}
          />
        )}
        {isAddModalOpen && (
          <AddNewInventoryCount
            saveClick={saveInventoryCount}
            onClick={openAddModal}
          />
        )}
        {isdeleteModalOpen && (
          <DeleteModal
            deleteId={deleteIdIs.current}
            onClick={openDeleteModal}
            onDelete={deleteHandler}
            item="inventory-count"
            name="inventory count"
          />
        )}
        <div className="card">
          <div className="card-header text-bg-secondary">
            <div className="row">
              <h4 className="card-title">
                Inventory Count
                <button
                  type="button"
                  className="btn btn-outline-light ms-3"
                  name="AddInventoryCount"
                  title="New InventoryCount"
                  style={{ padding: "4px 8px 7px" }}
                  onClick={openAddModal}
                >
                  <FaPlus />
                </button>
              </h4>
            </div>
          </div>
          <div className="card-body">
            <div className="toolbar"></div>
            {/* <TrackInventory /> */}
            <Table data={inventories} columns={COLUMNS} />
          </div>
        </div>
      </MainPanel>
      <ToastContainer position="bottom-right" hideProgressBar />
    </>
  );
};

export default Inventory;
