import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import style from "../../assets/style/modal.module.css";
import { IoIosSave } from "react-icons/io";
import useHttp from "../../hooks/useHttp";

export const EditSupplier = ({ editId, onClick, updateClick }) => {
  const [hasError, handleHttpRequest] = useHttp();
  const [initialValues, setInitialValues] = useState({
    name: "",
    account: "",
    address: "",
    note: "",
    sales_person: "",
    email: "",
    phone: "",
    sales_person_email: "",
    sales_person_phone: "",
    ar_email: "",
    ar_phone: "",
  });

  const closeModal = () => {
    formik.resetForm();
    setInitialValues({
      name: "",
      account: "",
      address: "",
      note: "",
      sales_person: "",
      email: "",
      phone: "",
      sales_person_email: "",
      sales_person_phone: "",
      ar_email: "",
      ar_phone: "",
    });
    onClick();
  };
  const editSuppliers = async (values) => {
    const response = await handleHttpRequest({
      page: "supplier/edit/" + editId,
      method: "PUT",
      data: values,
    });
    if (!response.status) {
      updateClick(response, 200);
    } else {
      updateClick(response.data, response.status);
    }
  };

  const getSupplier = async (id) => {
    const response = await handleHttpRequest({
      page: "supplier/" + id,
      method: "GET",
    });
    if (!response.status) {
      setInitialValues(response);
      response.suppliercontacts.forEach((element) => {
        switch (element.type) {
          case "supplier": {
            setInitialValues((prev) => {
              return { ...prev, email: element.email, phone: element.phone };
            });
            break;
          }
          case "sales_person": {
            setInitialValues((prev) => {
              return {
                ...prev,
                sales_person_email: element.email,
                sales_person_phone: element.phone,
              };
            });
            break;
          }
          case "ar": {
            setInitialValues((prev) => {
              return {
                ...prev,
                ar_email: element.email,
                ar_phone: element.phone,
              };
            });
            break;
          }
        }
      });
    } else {
      console.error("Error", response.data);
    }
  };

  useEffect(() => {
    getSupplier(editId);
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Supplier Name is required")
        .max(100, "Supplier name is too long"),
      account: Yup.string().max(20, "Account is too long").notRequired(),
      address: Yup.string().notRequired().max(50, "Address is too long"),
      note: Yup.string().max(200, "Note is too long").notRequired(),
      sales_person: Yup.string()
        .max(100, "Sales person name is too long")
        .notRequired(),
      email: Yup.string()
        .email("Invalid email format")
        .required("Email Address is required."),
      phone: Yup.number()
        .positive()
        .typeError("Phone must be a number")
        .notRequired()
        .when([], (phone, validationSchema) =>
          phone && phone.length > 0
            ? validationSchema.min(10, "Phone number must be 10 digits")
            : validationSchema
        ),
      sales_person_email: Yup.string()
        .email("Invalid email format")
        .notRequired(),
      sales_person_phone: Yup.number()
        .positive()
        .typeError("Phone must be a number")
        .notRequired()
        .when([], (sales_person_phone, validationSchema) =>
          sales_person_phone && sales_person_phone.length > 0
            ? validationSchema.min(10, "Phone number must be 10 digits")
            : validationSchema
        ),
      ar_email: Yup.string().email("Invalid email format").notRequired(),
      ar_phone: Yup.number()
        .positive()
        .typeError("Phone must be a number")
        .notRequired()
        .when([], (ar_phone, validationSchema) =>
          ar_phone && ar_phone.length > 0
            ? validationSchema.min(10, "Phone number must be 10 digits")
            : validationSchema
        ),
    }),
    onSubmit: (values) => {
      editSuppliers(values);
      formik.resetForm();
    },
  });

  return (
    <>
      <div className={style["modal-overlay"]}></div>
      <div className={style["modal-card-wrapper"]}>
        <div className={`card  ${style["modal-card"]} w-75`} role="dialog">
          <div className="modal-dialog w-100">
            <div className="modal-content">
              <div
                className={` ${style["modal-header"]} text-bg-secondary`}
                data-bs-theme="dark"
              >
                <h5 className="modal-title" id="staticBackdropLabel">
                  Edit Supplier
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={closeModal}
                  aria-label="Close"
                ></button>
              </div>
              <form class="" onSubmit={formik.handleSubmit}>
                <div className={style["modal-content"]}>
                  <div className="modal-body row g-3">
                    <div className="row mt-4">
                      <div className="col-md-8">
                        <label
                          className="form-label lblrequired"
                          htmlFor="name"
                        >
                          Supplier Name *
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          name="name"
                          value={formik.values.name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      <div class="col-md-4">
                        <label className="form-label" htmlFor="account">
                          Account Number
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="account"
                          name="account"
                          value={formik.values.account}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-md-8">
                        <div className="row">
                          <div className="col-md-6">
                            <label
                              className="form-label lblrequired"
                              htmlFor="email"
                            >
                              Email*
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              id="email"
                              name="email"
                              value={formik.values.email}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                          <div className="col-md-6">
                            <label className="form-label" htmlFor="phone">
                              Phone
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="phone"
                              name="phone"
                              value={formik.values.phone}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div class="col-md-12">
                            <label htmlFor="address" class="form-label">
                              Business Address
                            </label>
                            <textarea
                              class="form-control"
                              placeholder="Leave a comment here"
                              style={{ height: "87px" }}
                              id="address"
                              name="address"
                              value={formik.values.address}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="row">
                          <div class="col-md-12">
                            <label htmlFor="note" class="form-label">
                              Personalized Notes
                            </label>
                            <textarea
                              class="form-control"
                              placeholder="Leave a comment here"
                              style={{ height: "160px" }}
                              id="note"
                              name="note"
                              value={formik.values.note}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-4">
                      <div className="col-md-8">
                        <h5>Sale's Representative</h5>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-8">
                        <label className="form-label" htmlFor="sales_person">
                          Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="sales_person"
                          name="sales_person"
                          value={formik.values.sales_person}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <label
                          className="form-label"
                          htmlFor="sales_person_email"
                        >
                          Email
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="sales_person_email"
                          name="sales_person_email"
                          value={formik.values.sales_person_email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      <div className="col-md-6">
                        <label
                          className="form-label"
                          htmlFor="sales_person_phone"
                        >
                          Phone
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="sales_person_phone"
                          name="sales_person_phone"
                          value={formik.values.sales_person_phone}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                    </div>

                    <div className="row mt-4">
                      <div className="col-md-8">
                        <h5>AR Contact Information</h5>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <label className="form-label" htmlFor="ar_email">
                          Email
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="ar_email"
                          name="ar_email"
                          value={formik.values.ar_email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      <div className="col-md-6">
                        <label className="form-label" htmlFor="ar_phone">
                          Phone
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="ar_phone"
                          name="ar_phone"
                          value={formik.values.ar_phone}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={style["modal-footer"]}>
                  <span>
                    {formik.touched.phone && formik.errors.phone ? (
                      <span className="">{formik.errors.phone}</span>
                    ) : null}
                    {formik.touched.email && formik.errors.email
                      ? formik.errors.email
                      : formik.touched.address && formik.errors.address
                      ? formik.errors.address
                      : formik.touched.sales_person_email &&
                        formik.errors.sales_person_email
                      ? formik.errors.sales_person_email
                      : formik.touched.ar_email && formik.errors.ar_email
                      ? formik.errors.ar_email
                      : null}
                    {console.log("Formik errors: ", formik.errors)}
                  </span>
                  <button type="submit" className="btn btn-link btn-just-icon">
                    <IoIosSave className="save-icon" />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const EditSupplierModalW = (props) => {
  return ReactDOM.createPortal(
    <EditSupplier
      editId={props.editId}
      onClick={props.onClick}
      updateClick={props.updateClick}
    />,
    document.getElementById("modal")
  );
};
export default EditSupplierModalW;
