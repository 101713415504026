import React, { useState, useContext } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { NavLink, useNavigate } from "react-router-dom";
import useHttp from "../../hooks/useHttp";
import axios from "axios";
import { IoIosSave, IoMdAdd } from "react-icons/io";
import AuthContext from "../../store/LoginContext";

export const RegisterUser = () => {
  const [hasError, handleHttpRequest, getCsrfToken] = useHttp();
  const [registerMessage, setRegisterMessage] = useState();
  const { logIn } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const RegisterUser = async (values) => {
    // setRegisterMessage("");
    setLoading(true);
    try {
      getCsrfToken();
      const response = await handleHttpRequest({
        page: "register",
        method: "POST",
        data: values,
      });
      if (!response.status) {
        //redirect to confirm page
        return navigate("/verifiy-email");
      } else {
        setRegisterMessage(response.data.message);
      }
      setLoading(false);
    } catch (err) {
      // console.error("Error", err);
      setRegisterMessage("Unable to register your account, please try again.");
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      password_confirmation: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("User name is required")
        .max(100, "User name is too long"),
      email: Yup.string()
        .email("Invalid email format")
        .required("Email address is required."),
      password: Yup.string()
        .required("Password is required")
        .min(8, "Password must be at least 8 characters")
        .max(100, "Password is too long")
        .matches(
          /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&()*])/,
          "Must contain 8 characters, with minimum one uppercase, one lowercase, one number and a special case character."
        ),
      password_confirmation: Yup.string()
        .required("Password confirmation is required")
        .oneOf([Yup.ref("password"), null], "Password must match"),
    }),
    onSubmit: async (values) => {
      RegisterUser(values);
      // formik.resetForm();
    },
  });

  return (
    <div className="d-flex justify-content-center mt-4">
      <div className="card w-50">
        <div className="card-header text-bg-secondary">
          <div className="row">
            <h4 className="card-title">Create an account</h4>
          </div>
        </div>
        <div className="card-body">
          {/* {console.log("REgister message .. ", registerMessage)} */}
          {registerMessage ? (
            <div class="alert alert-danger" role="alert">
              {registerMessage}
            </div>
          ) : null}
          <form class="" onSubmit={formik.handleSubmit}>
            <div className="">
              <div className="row g-3">
                <div className="row mt-4">
                  <div className="col-md-12">
                    <label className="form-label lblrequired" htmlFor="name">
                      User Name *
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-md-12">
                    <label className="form-label lblrequired" htmlFor="email">
                      Email *
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-md-12">
                    <label
                      className="form-label lblrequired"
                      htmlFor="password"
                    >
                      Password *
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="password"
                      name="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-md-12">
                    <label
                      className="form-label lblrequired"
                      htmlFor="password_confirmation"
                    >
                      Confirm password *
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="password_confirmation"
                      name="password_confirmation"
                      value={formik.values.password_confirmation}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-12">
                <span className="error-msg">
                  {" "}
                  {formik.touched.name && formik.errors.name
                    ? "Invalid: " + formik.errors.name
                    : formik.touched.email && formik.errors.email
                    ? "Invalid: " + formik.errors.email
                    : formik.touched.password && formik.errors.password
                    ? "Invalid: " + formik.errors.password
                    : formik.touched.password_confirmation &&
                      formik.errors.password_confirmation
                    ? "Invalid: " + formik.errors.password_confirmation
                    : null}
                </span>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-12">
                {!loading ? (
                  <button
                    type="submit"
                    className="btn btntheme"
                    disabled={!formik.isValid || !formik.dirty}
                  >
                    Sign Up
                  </button>
                ) : (
                  <button class="btn btntheme" type="button" disabled>
                    <span
                      class="spinner-border spinner-border-sm"
                      aria-hidden="true"
                    ></span>
                    <span role="status">Sign Up...</span>
                  </button>
                )}
              </div>
            </div>
            <div className="d-flex mt-3">
              <span> Already have an account? </span>
              {/* <Link to="">Login</Link>  */}
              <NavLink className="nav-link" to="/login">
                Sign In
              </NavLink>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RegisterUser;
