const useUnitConversion = () => {
  //Global conversion values of Ounce(oz)
  const conversionRates = {
    tsp: 6,
    tbsp: 2,
    ml: 29.57353193,
    c: 0.120095,
    pt: 0.0625,
    qt: 0.03125,
    l: 0.0295735,
    gal: 0.0078125,
    g: 28.3495,
    kg: 0.0283495,
    lb: 0.0625,
    "fl oz": 0.958611418535,
    oz: 1,
  };

  const IsStandardUnit = (unit) =>
    conversionRates[unit.toLowerCase()] !== undefined;

  const convertUnit = (value, fromUnit, toUnit) => {
    const tunit = toUnit.toLowerCase();
    const funit = fromUnit.toLowerCase();
    //If not in conversion rate it's non-standard
    if (!IsStandardUnit(funit) || !IsStandardUnit(tunit)) return;

    //If is Standard unit, then Convert fromUnit to ounce and from ounce to tunit
    if (funit === tunit) return value;

    const valueInOunce =
      funit === "oz" ? value : value / conversionRates[funit];
    //Convert from ounce to tunit
    const valueInToUnit =
      tunit === "oz" ? valueInOunce : valueInOunce * conversionRates[tunit];

    return valueInToUnit;
  };

  return [IsStandardUnit, convertUnit];
};

export default useUnitConversion;
