import React, { useRef, useEffect, useState, useContext } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { NavLink, useNavigate } from "react-router-dom";
import useHttp from "../../hooks/useHttp";
import { IoIosSave } from "react-icons/io";
import axios from "axios";
import AuthContext from "../../store/LoginContext";
import { ResendVerificationEmail } from "./ConfirmEmail";

export const Login = () => {
  const [hasError, handleHttpRequest, getCsrfToken] = useHttp();
  const { logIn } = useContext(AuthContext);
  const [loginStatus, setLoginStatus] = useState();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [registerMessage, setRegisterMessage] = useState();
  const [unverifiedMessage, setUnVerifiedMessage] = useState();

  const loginHandler = async (values) => {
    setLoading(true);
    try {
      getCsrfToken();
      const response = await handleHttpRequest({
        page: "login",
        method: "POST",
        data: values,
      });
      if (!response.status) {
        //check-email-verified/
        logIn(response.token, response.user.id);
        // const user = await axios.get("http://127.0.0.1/api/user", {
        //   headers: {
        //     Authorization: `Bearer ${response.token}`,
        //   },
        // });
        // console.log("user", user);
        setLoginStatus(true);
        return navigate("/");
      } else if (response.status == 403) {
        setUnVerifiedMessage(
          "Your email address is not verified, please verify to login."
        );
      } else {
        setRegisterMessage(response.data.message);
        setLoginStatus(false);
      }
      setLoading(false);
    } catch (err) {
      console.error("Login Error: ", err);
      //loginStatus = false;
      //return err;
      setRegisterMessage("Unable to log you in, please try again.");
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email format")
        .required("Email address is required."),
      password: Yup.string().required("Password is required"),
      // .min(6, "Password must be at least 6 characters")
      // .max(100, "Password is too long"),
    }),
    onSubmit: async (values) => {
      setLoginStatus(true);
      loginHandler(values);
      // formik.resetForm();
    },
  });

  return (
    <div className="d-flex justify-content-center mt-4">
      <div className="card">
        <div className="card-header text-bg-secondary">
          <div className="row">
            <h4 className="card-title">Login</h4>
          </div>
        </div>
        <div className="card-body">
          {registerMessage && (
            <div class="alert alert-danger" role="alert">
              {registerMessage}
            </div>
          )}
          {unverifiedMessage && (
            <div class="alert alert-danger" role="alert">
              {unverifiedMessage}{" "}
              {
                <ResendVerificationEmail
                  email={formik.values.email}
                  password={formik.values.password}
                >
                  Resend Code
                </ResendVerificationEmail>
              }
            </div>
          )}
          <form class="" onSubmit={formik.handleSubmit}>
            <div className="">
              <div className="row g-3">
                <div className="row mt-4">
                  <div className="col-md-12">
                    <label className="form-label lblrequired" htmlFor="email">
                      Email *
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-md-12">
                    <label
                      className="form-label lblrequired"
                      htmlFor="password"
                    >
                      Password *
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="password"
                      name="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-12">
                <span className="error-msg">
                  {" "}
                  {/* {!formik.isValid && "Invalid: "} */}
                  {formik.touched.email && formik.errors.email
                    ? "Invalid: " + formik.errors.email
                    : formik.touched.password && formik.errors.password
                    ? "Invalid: " + formik.errors.password
                    : null}
                </span>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-12">
                {!loading ? (
                  <button
                    type="submit"
                    className="btn btntheme"
                    disabled={!formik.isValid || !formik.dirty}
                  >
                    Sign In
                  </button>
                ) : (
                  <button class="btn btntheme" type="button" disabled>
                    <span
                      class="spinner-border spinner-border-sm"
                      aria-hidden="true"
                    ></span>
                    <span role="status">Sign In...</span>
                  </button>
                )}
              </div>
            </div>

            <div className="d-flex mt-3">
              <NavLink className="nav-link" to="/forgot-password">
                Forgot password?
              </NavLink>
            </div>

            <div className="d-flex mt-3">
              <span> Don't have an account? </span>
              {/* <Link to="">Login</Link>  */}
              <NavLink className="nav-link" to="/register">
                Sign Up
              </NavLink>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
