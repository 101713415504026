import React from "react";

const MainPanel=(props)=>{
    return(
        <div className="main-panel">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12" >
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
            <div id="notifarea"></div>
            <footer className="footer"></footer>
        </div>
    )
}

export default MainPanel;