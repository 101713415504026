import MainPanel from "../../components/MainPanel";
import { Link, useParams } from "react-router-dom";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaRegEdit, FaPlus, FaList } from "react-icons/fa";
import { useEffect, useRef, useState } from "react";
import { Toast } from "bootstrap";
import Notification from "../../components/Notification";
import { useFormik, ErrorMessage } from "formik";
import * as Yup from "yup";
import useHttp from "../../hooks/useHttp";
import tableStyle from "../../assets/style/table.module.css";
import { array } from "yup";

const AddPermissionToRole = () => {
  const [_, handleHttpRequest] = useHttp();
  const { id: role_id } = useParams();
  const [role, setRole] = useState({ name: "", permissions: [] });
  const [permissions, setPermissions] = useState([]);
  const [models, setModels] = useState([]);
  const [actions, setActions] = useState([]);
  const [checkedValues, setCheckedValues] = useState([]);
  // const permissionsList;

  const notificationStatus = useRef();
  const notificationMessage = useRef();
  const toastRef = useRef();
  var [toast, setToast] = useState(false);
  const [toastUpdated, setToastUpdated] = useState(false);
  useEffect(() => {
    var myToast = toastRef.current;
    var bsToast = Toast.getInstance(myToast);
    if (!bsToast) {
      // initialize Toast
      bsToast = new Toast(myToast, { delay: 4000, autohide: true });
      // hide after init
      bsToast.hide();
      setToast(false);
    } else {
      // toggle
      toast ? bsToast.show() : bsToast.hide();
      // setToast(state => !state);
    }
  }, [toastUpdated, setToastUpdated]);

  const getRole = async () => {
    const response = await handleHttpRequest({
      page: "role/" + role_id,
      method: "GET",
    });
    if (!response.status) {
      setRole(response);
      response.permissions.map((permission) => {
        setCheckedValues((prev) => [...prev, permission.id]);
      });
      // setCheckedValues(response.permissions);
    }
  };
  const getPermissions = async () => {
    const response = await handleHttpRequest({
      page: "permissions",
      method: "GET",
    });
    if (!response.status) {
      // console.log(response);
      setActions(response.actions);
      setModels(response.models);
      setPermissions(response.model_action);
    }
  };
  useEffect(() => {
    getRole();
    getPermissions();
  }, []);

  const updatePermissionsForRole = async () => {
    const response = await handleHttpRequest({
      page: "role-permissions/edit/" + role_id,
      method: "PUT",
      data: { permission: checkedValues },
    });
    if (!response.status) {
      notificationMessage.current = response;
      notificationStatus.current = 200;
      setToast(true);
      setToastUpdated((state) => !state);
      //update status
    } else {
      //update status
      notificationMessage.current = "Unable to assign permission.";
      notificationStatus.current = 400;
      setToast(true);
      setToastUpdated((state) => !state);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updatePermissionsForRole();
  };

  const handleChange = (value) => {
    setCheckedValues((prev) => {
      if (prev.includes(value)) {
        //remove
        return prev.filter((pr) => pr != value);
      } else {
        return [...prev, value];
      }
    });
  };
  const sortedActions = (actionsVal) =>
    actionsVal.sort((a, b) => actions.indexOf(a[0]) - actions.indexOf(b[0]));

  const listPermissions = (listOfPermission) => {
    const listOfSortedPermission = sortedActions(listOfPermission);
    return listOfSortedPermission.map(([key, value]) => (
      <td>
        <input
          type="checkbox"
          id={value}
          name="permission[]"
          value={value}
          checked={checkedValues.includes(value)}
          onChange={() => handleChange(value)}
        />
        {key}
      </td>
    ));
  };

  return (
    <MainPanel>
      <div className="card">
        <div className="card-header text-bg-secondary">
          <div className="row">
            <h4 className="card-title">Assign Permissions to {role.name}</h4>
          </div>
        </div>
        <div className="card-body">
          <div className="toolbar"></div>
          <form class="" onSubmit={handleSubmit}>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col" class="col-2"></th>
                  {/* <th>Add</th>
                <th>Delete</th>
                <th>Edit</th>
                <th>Set status</th>
                <th>View</th> */}
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
                {/* <tr>
                {actions.map((action) => (
                  <th>{action}</th>
                ))}
              </tr> */}
              </thead>
              <tbody>
                {/* <tr>
                <td scope="col" class="col-1">
                  <label>Supplier</label>
                </td>
                <td>
                  <input type="checkbox" name="permission[]" />
                </td>
                <td>
                  <input type="checkbox" name="permission[]" />
                </td>
                <td>
                  <input type="checkbox" name="permission[]" />
                </td>
                <td>
                  <input type="checkbox" name="permission[]" />
                </td>
                <td>
                  <input type="checkbox" name="permission[]" />
                </td>
              </tr> */}
                {Object.entries(permissions).map((permission) => {
                  return (
                    <tr>
                      <td
                        style={{
                          textTransform: "capitalize",
                          fontWeight: "bold",
                        }}
                      >
                        {permission[0]}
                      </td>
                      {listPermissions(Object.entries(permission[1]))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div></div>
            <button type="submit" className="btn btn-primary">
              Update
            </button>
          </form>
        </div>
      </div>
      <Notification
        toastRef={toastRef}
        toast={toast}
        setToast={setToast}
        notificationStatus={notificationStatus.current}
      >
        {notificationMessage.current}
      </Notification>
    </MainPanel>
  );
};

export default AddPermissionToRole;
