import { FaRegEdit, FaPlus } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";

const CartTable = ({ item, onEditClick, onDeleteClick }) => {
  return (
    <>
      <tr>
        <td>{item.ingredient}</td>
        <td>
          {item.quantityToOrder}
          {item.unitMeasure}
        </td>
        <td>
          {item.qtyPerSellBy} {item.sellBy}
        </td>
        <td>{item.pricePerSellBy ? "$" + item.pricePerSellBy : ""}</td>
        <td>{item.extendedPrice ? "$" + item.extendedPrice : ""}</td>
        <td>{item.minOrderQty ? item.minOrderQty + item.sellBy : ""} </td>
        <td>{item.description}</td>
        <td>
          <button
            className="btn btn-link btn-just-icon themecolor fs-5 p-0"
            onClick={() => {
              onEditClick(item);
            }}
          >
            <FaRegEdit />
          </button>
          <button
            className="btn btn-link btn-just-icon iconthemedel delcount fs-5"
            style={{ color: "#676767" }}
            onClick={() => {
              onDeleteClick(item.id);
            }} //item.id //cartitem id
          >
            <RiDeleteBin6Line />
          </button>
        </td>
      </tr>
    </>
  );
};

export default CartTable;
