import ReactDOM from "react-dom";
import style from "../../assets/style/modal.module.css";

const DiscardOnHandPromptModal = ({ message, onCancle, onConfirm }) => {
  return (
    <>
      <div className={style["modal-overlay"]}></div>
      <div className={style["modal-card-wrapper"]}>
        <div
          id={style["deleteModal"]}
          className={`card  ${style["modal-card"]}`}
          role="dialog"
        >
          <div className="modal-dialog w-100">
            <div className="modal-content">
              <div
                className={` ${style["modal-header"]} text-bg-secondary`}
                data-bs-theme="dark"
              >
                <h5 className="modal-title" id="staticBackdropLabel">
                  Alert
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={onCancle}
                  aria-label="Close"
                ></button>
              </div>
              <div className={style["modal-content"]}>{message}</div>
              <div className={style["modal-footer"]}>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={onConfirm}
                >
                  Confirm
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={onCancle}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const DiscardOnHandPrompt = (props) => {
  return ReactDOM.createPortal(
    <DiscardOnHandPromptModal
      message={props.message}
      onCancle={props.onCancle}
      onConfirm={props.onConfirm}
    />,
    document.getElementById("modal")
  );
};
export default DiscardOnHandPrompt;
