import { Link } from "react-router-dom";
import { useState } from "react";
import { FaRegEdit, FaPlus } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaStar, FaStarHalfAlt } from "react-icons/fa";
import { BiMessageSquareDetail } from "react-icons/bi";
import { MdOutlineDoNotDisturbAlt } from "react-icons/md";

const UsersButtonGroup = ({ cell, isActive, openSetStatusModal }) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const ontoggle = () => {
    setShowDropDown((state) => !state);
  };
  return (
    <div class="btn-group">
      <button type="button" className="btn btntheme" title="Edit">
        <Link
          className="text-decoration-none text-white"
          to={`/edit-user/${cell}`}
        >
          <FaRegEdit /> Edit
        </Link>
        {/* <FaRegEdit className="themecolor" /> Edit */}
      </button>
      {/* <button type="button" className="btn btntheme" onClick={()=>{onEditHandler(cell)}}> <FaRegEdit /> Edit</button> */}
      <button
        type="button"
        className={`btn btntheme dropdown-toggle  ${
          showDropDown ? "show" : ""
        }`}
        data-bs-toggle="dropdown"
        aria-expanded={`${showDropDown ? "true" : "false"}`}
        onClick={ontoggle}
      >
        <span className="visually-hidden">Toggle Dropdown</span>
      </button>
      <ul className={`dropdown-menu ${showDropDown ? "show" : ""}`}>
        <li>
          <button
            type="button"
            className="dropdown-item btn btn-light"
            title="Inactive"
            onClick={() => {
              openSetStatusModal(cell, isActive);
            }}
          >
            <MdOutlineDoNotDisturbAlt />
            {isActive ? "Set to Inactive" : "Set to Active"}
          </button>
        </li>
      </ul>
    </div>
  );
};
export default UsersButtonGroup;
