import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import UserBtnGroup from "../groupButtons/UserBtnGroup";
import { FaRegUserCircle } from "react-icons/fa";
import { FaCartShopping } from "react-icons/fa6";
import { useContext } from "react";
import AuthContext from "../../store/LoginContext";
import useHttp from "../../hooks/useHttp";

const Navigation = () => {
  const [hasError, handleHttpRequest] = useHttp();
  const { authToken, logOut, roleAndPermissions, notification } =
    useContext(AuthContext);
  const navigate = useNavigate();
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  const handleLogout = async () => {
    const response = await handleHttpRequest({
      page: "logout",
      method: "POST",
    });
    //change this
    if (!hasError) {
      logOut();
      //refresh first b/c /login sees and redirects to home page!
      window.location.reload();
      return navigate("/login");
    } else {
      alert("Can't logout.");
    }
  };

  const handleToggleNavbar = () => {
    setIsNavbarOpen((state) => !state);
  };

  return (
    <nav
      class="navbar bg-dark navbar-expand-lg bg-body-tertiary nav-bar-collapse mb-4"
      data-bs-theme="dark"
    >
      <div class="container-fluid">
        <NavLink className="navbar-brand" to="/">
          PRICING TOOL
        </NavLink>
        <div className="d-flex">
          <ul class="d-flex d-lg-none me-auto mb-2 mb-lg-0">
            <li className="nav-item dropdown">
              <UserBtnGroup onLogout={handleLogout} />
            </li>
            <NavLink className="nav-link p-0" to="/carts">
              <button
                type="button"
                className="btn btn-link btn-just-icon"
                style={{ color: "#fff", fontSize: "1.3em" }}
                title="Shopping Cart"
              >
                <FaCartShopping />
                {notification && (
                  <span
                    class="badge rounded-pill text-bg-danger translate-middle top-0"
                    style={{ fontSize: "12px" }}
                  >
                    {notification}
                  </span>
                )}
              </button>
            </NavLink>
          </ul>
          <button
            className="navbar-toggler"
            type="button"
            onClick={handleToggleNavbar}
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded={`${isNavbarOpen ? "true" : "false"}`}
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>
        {authToken.authentication_token ? (
          <div
            className={`collapse navbar-collapse  ${
              isNavbarOpen ? "show" : ""
            }`}
          >
            {/* id="navbarNav"> */}
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
              {roleAndPermissions.permissions != undefined &&
                roleAndPermissions.permissions.includes("view ingredient") && (
                  <li class="nav-item">
                    <NavLink className="nav-link" to="/ingredients">
                      Ingredients
                    </NavLink>
                  </li>
                )}
              {roleAndPermissions.permissions != undefined &&
                roleAndPermissions.permissions.includes("view ingredient") && (
                  <li class="nav-item">
                    <NavLink className="nav-link" to="/inventorycount">
                      Inventory
                    </NavLink>
                  </li>
                )}
              {roleAndPermissions.permissions != undefined &&
                roleAndPermissions.permissions.includes("view supplier") && (
                  <li class="nav-item">
                    <NavLink className="nav-link" to="/suppliers">
                      Suppliers
                    </NavLink>
                  </li>
                )}
              {/* {roleAndPermissions.permissions != undefined &&
                roleAndPermissions.permissions.includes("view ingredient") && (
                  <li class="nav-item">
                    <NavLink className="nav-link" to="/budgetcategory">
                      Budget Categories
                    </NavLink>
                  </li>
                )} */}
              {roleAndPermissions.permissions != undefined &&
                roleAndPermissions.permissions.includes("view recipe") && (
                  <li class="nav-item">
                    <NavLink className="nav-link" to="/recipes">
                      Recipes
                    </NavLink>
                  </li>
                )}
              {roleAndPermissions.permissions != undefined &&
                roleAndPermissions.permissions.includes("view menu item") && (
                  <li class="nav-item">
                    <NavLink className="nav-link" to="/menuitems">
                      Menu Items
                    </NavLink>
                  </li>
                )}
              {roleAndPermissions.permissions != undefined &&
                roleAndPermissions.permissions.includes("view sales") && (
                  <li class="nav-item">
                    <NavLink className="nav-link" to="/saleshistory">
                      Sales History
                    </NavLink>
                  </li>
                )}
              {roleAndPermissions.permissions != undefined &&
                roleAndPermissions.permissions.includes("view ingredient") && (
                  <li class="nav-item">
                    <NavLink className="nav-link" to="/generateorders">
                      Generate Orders
                    </NavLink>
                  </li>
                )}
              {roleAndPermissions.permissions != undefined &&
                roleAndPermissions.permissions.includes("view ingredient") && (
                  <li class="nav-item">
                    <NavLink className="nav-link" to="/orders">
                      Orders
                    </NavLink>
                  </li>
                )}
              {roleAndPermissions.permissions != undefined &&
                roleAndPermissions.permissions.includes("view ingredient") && (
                  <li class="nav-item">
                    <NavLink className="nav-link" to="/imports">
                      Import
                    </NavLink>
                  </li>
                )}
              {roleAndPermissions.permissions != undefined &&
                roleAndPermissions.permissions.includes("view user") && (
                  <li class="nav-item">
                    <NavLink className="nav-link" to="/users">
                      Users
                    </NavLink>
                  </li>
                )}
              {roleAndPermissions.permissions != undefined &&
                roleAndPermissions.permissions.includes("view role") && (
                  <li class="nav-item">
                    <NavLink className="nav-link" to="/roles">
                      Roles
                    </NavLink>
                  </li>
                )}
            </ul>
            <div class="d-lg-flex d-none">
              <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item dropdown">
                  <UserBtnGroup onLogout={handleLogout} />
                </li>
                <NavLink className="nav-link p-0" to="/carts">
                  <button
                    type="button"
                    className="btn btn-link btn-just-icon"
                    style={{ color: "#fff", fontSize: "1.3em" }}
                    title="Shopping Cart"
                  >
                    <FaCartShopping />
                    {notification && (
                      <span
                        class="badge rounded-pill text-bg-danger translate-middle top-0"
                        style={{ fontSize: "12px" }}
                      >
                        {notification}
                      </span>
                    )}
                  </button>
                </NavLink>
              </ul>
            </div>
          </div>
        ) : (
          <div
            // class="collapse navbar-collapse "
            // id="navbarNav"
            className={`collapse navbar-collapse justify-content-end ${
              isNavbarOpen ? "show" : ""
            }`}
          >
            <div class="d-flex" role="">
              {/* <button
                type="button"
                className="btn btn-outline-light mx-2"
                title="Login"
              >
                Login
              </button> */}
              {/* <button
                type="button"
                className=""
                title="Register"
              >
                Register
              </button> */}
              <NavLink className="btn btn-outline-light mx-2" to="/login">
                Sign In
              </NavLink>
              <NavLink className="btn btn-outline-light" to="/register">
                Sign Up
              </NavLink>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navigation;
