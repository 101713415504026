import React, { useState } from "react";
import ReactDOM from "react-dom";
import style from "../../assets/style/modal.module.css";
import useHttp from "../../hooks/useHttp";

export const SetUserStatusModal = ({
  userId,
  currentStatus,
  onCancle,
  onToggle,
}) => {
  const [_, handleHttpRequest] = useHttp();
  const toggleUserStatus = async (id) => {
    const response = await handleHttpRequest({
      page: "user/setstatus/" + id,
      method: "PUT",
    });
    if (!response.status) {
      onToggle(response, 200);
    } else {
      onToggle(response, 404);
    }
  };

  return (
    <>
      <div className={style["modal-overlay"]}></div>
      <div className={style["modal-card-wrapper"]}>
        <div
          id={style["deleteModal"]}
          className={`card  ${style["modal-card"]}`}
          role="dialog"
        >
          <div className="modal-dialog w-100">
            <div className="modal-content">
              <div className={` ${style["modal-header"]}`}>
                <h5 className="modal-title" id="staticBackdropLabel">
                  User Status
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={onCancle}
                  aria-label="Close"
                ></button>
              </div>
              <div className={style["modal-content"]}>
                {currentStatus == 0
                  ? "Set User as active?"
                  : "Set User as in-active?"}
              </div>
              <div className={style["modal-footer"]}>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => toggleUserStatus(userId)}
                >
                  Confirm
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={onCancle}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const SetUserStatus = (props) => {
  return ReactDOM.createPortal(
    <SetUserStatusModal
      currentStatus={props.currentStatus}
      userId={props.userId}
      onCancle={props.onClick}
      onToggle={props.onToggle}
    />,
    document.getElementById("modal")
  );
};
export default SetUserStatus;
