import { createContext, useState } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  // const [isLoggedIn, setIsLoggedIn]
  const [authToken, setAuthToken] = useState({
    authentication_token: localStorage.getItem("auth_token"),
    user_id: localStorage.getItem("user"),
  });

  const [roleAndPermissions, setRoleAndPermissions] = useState({
    role: "",
    permissions: [],
  });

  const [notification, setNotification] = useState();

  const logIn = (token, user_id) => {
    localStorage.setItem("auth_token", token);
    localStorage.setItem("user", user_id);
    setAuthToken({
      authentication_token: token,
      user_id: user_id,
    });
  };
  const logOut = () => {
    localStorage.removeItem("auth_token");
    localStorage.removeItem("user");
    // setIsLoggedIn(false);
  };

  const storeRolesAndPermissions = (role, permissions) => {
    setRoleAndPermissions({
      role: role,
      permissions: permissions,
    });
  };

  const updateNotification = (value) => {
    value == 0 ? setNotification(null) : setNotification(value);
  };

  return (
    <AuthContext.Provider
      value={{
        authToken,
        logIn,
        logOut,
        roleAndPermissions,
        storeRolesAndPermissions,
        notification,
        updateNotification,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
// {
//   isLoggedIn: true,
//   token: "",
// }
//   isLoggedIn: false,
//   token: "",
//   onLogout: logOutHandler
