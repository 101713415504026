import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { IoIosSave } from "react-icons/io";
import useHttp from "../../hooks/useHttp";
import MainPanel from "../../components/MainPanel";
import { ToastContainer, toast } from "react-toastify";

export const AddSupplierPage = () => {
  const [_, handleHttpRequest] = useHttp();
  const [initialValues, setInitialValues] = useState({
    name: "",
    account: "",
    address: "",
    note: "",
    sales_person: "",
    email: "",
    phone: "",
    sales_person_email: "",
    sales_person_phone: "",
    ar_email: "",
    ar_phone: "",
  });

  const addSuppliers = async (values) => {
    const response = await handleHttpRequest({
      page: "supplier/add",
      method: "POST",
      data: values,
    });
    console.log("Add supplier response: ", response);
    if (!response.status) {
      toast.info(response);
    } else {
      toast.error(response.data);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Supplier Name is required")
        .max(100, "Supplier name is too long"),
      account: Yup.string().max(20, "Account is too long").notRequired(),
      address: Yup.string().notRequired().max(50, "Address is too long"),
      note: Yup.string().max(200, "Note is too long").notRequired(),
      sales_person: Yup.string()
        .max(100, "Sales person name is too long")
        .notRequired(),
      email: Yup.string()
        .email("Invalid email format")
        .required("Email Address is required."),
      phone: Yup.number()
        .positive()
        .typeError("Phone must be a number")
        .notRequired()
        .when([], (phone, validationSchema) =>
          phone && phone.length > 0
            ? validationSchema.min(10, "Phone number must be 10 digits")
            : validationSchema
        ),
      sales_person_email: Yup.string()
        .email("Invalid email format")
        .notRequired(),
      sales_person_phone: Yup.number()
        .positive()
        .typeError("Phone must be a number")
        .notRequired()
        .when([], (sales_person_phone, validationSchema) =>
          sales_person_phone && sales_person_phone.length > 0
            ? validationSchema.min(10, "Phone number must be 10 digits")
            : validationSchema
        ),
      ar_email: Yup.string().email("Invalid email format").notRequired(),
      ar_phone: Yup.number()
        .positive()
        .typeError("Phone must be a number")
        .notRequired()
        .when([], (ar_phone, validationSchema) =>
          ar_phone && ar_phone.length > 0
            ? validationSchema.min(10, "Phone number must be 10 digits")
            : validationSchema
        ),
    }),
    onSubmit: (values) => {
      addSuppliers(values);
    },
  });

  return (
    <>
      <MainPanel>
        <div className="card">
          <div className="card-header text-bg-secondary">
            <div className="row">
              <h5 className="card-title text-uppercase">Add Supplier</h5>
            </div>
          </div>
          <div className="card-body">
            <form class="" onSubmit={formik.handleSubmit}>
              <div className="row mt-4">
                <div className="col-lg-6 col-sm-8">
                  <label className="form-label lblrequired" htmlFor="name">
                    Supplier Name *
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                <div class="col-lg-3 col-sm-4">
                  <label className="form-label" htmlFor="account">
                    Account Number
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="account"
                    name="account"
                    value={formik.values.account}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-lg-6 col-sm-8">
                  <div className="row">
                    <div className="col-lg-6 col-sm-6">
                      <label className="form-label lblrequired" htmlFor="email">
                        Email*
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                    <div className="col-lg-6 col-sm-6">
                      <label className="form-label" htmlFor="phone">
                        Phone
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="phone"
                        name="phone"
                        value={formik.values.phone}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-md-12">
                      <label htmlFor="address" class="form-label">
                        Business Address
                      </label>
                      <textarea
                        class="form-control"
                        placeholder="Leave a comment here"
                        style={{ height: "87px" }}
                        id="address"
                        name="address"
                        value={formik.values.address}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-4">
                  <div className="row">
                    <div class="col-md-12">
                      <label htmlFor="note" class="form-label">
                        Personalized Notes
                      </label>
                      <textarea
                        class="form-control"
                        placeholder="Leave a comment here"
                        style={{ height: "160px" }}
                        id="note"
                        name="note"
                        value={formik.values.note}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-lg-6 col-sm-8">
                  <h5>Sale's Representative</h5>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-sm-8">
                  <label className="form-label" htmlFor="sales_person">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="sales_person"
                    name="sales_person"
                    value={formik.values.sales_person}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3 col-sm-6">
                  <label className="form-label" htmlFor="sales_person_email">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="sales_person_email"
                    name="sales_person_email"
                    value={formik.values.sales_person_email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                <div className="col-lg-3 col-sm-6">
                  <label className="form-label" htmlFor="sales_person_phone">
                    Phone
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="sales_person_phone"
                    name="sales_person_phone"
                    value={formik.values.sales_person_phone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-lg-6 col-sm-8">
                  <h5>AR Contact Information</h5>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3 col-sm-6">
                  <label className="form-label" htmlFor="ar_email">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="ar_email"
                    name="ar_email"
                    value={formik.values.ar_email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                <div className="col-lg-3 col-sm-6">
                  <label className="form-label" htmlFor="ar_phone">
                    Phone
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="ar_phone"
                    name="ar_phone"
                    value={formik.values.ar_phone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
              <hr></hr>
              <span>
                {formik.touched.phone && formik.errors.phone ? (
                  <span className="">{formik.errors.phone}</span>
                ) : null}
                {formik.touched.email && formik.errors.email
                  ? formik.errors.email
                  : formik.touched.address && formik.errors.address
                  ? formik.errors.address
                  : formik.touched.sales_person_email &&
                    formik.errors.sales_person_email
                  ? formik.errors.sales_person_email
                  : formik.touched.ar_email && formik.errors.ar_email
                  ? formik.errors.ar_email
                  : null}
                {console.log("Formik errors: ", formik.errors)}
              </span>
              <button type="submit" className="btn btn-link btn-just-icon">
                <IoIosSave className="save-icon" />
              </button>
            </form>
          </div>
        </div>
      </MainPanel>
      <ToastContainer position="bottom-right" hideProgressBar />
    </>
  );
};

export default AddSupplierPage;
