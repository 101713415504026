import { useState, useEffect } from "react";
import useHttp from "../../hooks/useHttp";

const MenuGroups = ({ value, onChange, onBlur, onMenuGroupSave }) => {
  const [menugroups, setMenuGroups] = useState([]);
  const [_, handleHttpRequest] = useHttp();

  const getMenuGroups = async () => {
    const response = await handleHttpRequest({
      page: "menu-groups",
      method: "GET",
    });
    if (!response.status) {
      setMenuGroups(response);
    }
  };
  useEffect(() => {
    getMenuGroups();
  }, [onMenuGroupSave]);

  return (
    <select
      class="form-select"
      name="menu_group_id"
      id="menu_group_id"
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    >
      {/* value={formik.values.category_id} onChange={formik.handleChange} onBlur={formik.handleBlur}> */}
      <option selected>Select Menu Group</option>
      {menugroups == undefined
        ? ""
        : menugroups.length == 0
        ? "No data"
        : menugroups.map((menugroup) => (
            <option key={menugroup.id} value={menugroup.id}>
              {menugroup.name}
            </option>
          ))}
    </select>
  );
};

export default MenuGroups;
