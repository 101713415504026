import React from "react";
import ReactDOM from "react-dom";
import { useFormik } from "formik";
import { FaSave } from "react-icons/fa";
import * as Yup from "yup";
import style from "../../assets/style/modal.module.css";

export const EditIngredientInventoryModal = ({
  storagelocation,
  onClick,
  IngInventory,
  IngInventoryIndex,
  updateIngInventory,
}) => {
  const closeModal = () => {
    formik.resetForm();
    onClick();
  };

  const formik = useFormik({
    initialValues: {
      size_on_hand: IngInventory.size_on_hand,
      qty_on_hand: IngInventory.qty_on_hand,
    },
    validationSchema: Yup.object({
      size_on_hand: Yup.string().required(
        "Select a size on hand for the Ingredient."
      ),
      qty_on_hand: Yup.number()
        .typeError("Quantity must be a number")
        .required("Quantity is required."),
    }),
    onSubmit: (values) => {
      // updateIngInventory(values);
      updateIngInventory(
        {
          inventory_id: IngInventory.inventory_id,
          ingredient: IngInventory.ingredient,
          ingredient_id: IngInventory.ingredient_id,
          size_on_hand: values.size_on_hand,
          qty_on_hand: values.qty_on_hand,
        },
        IngInventoryIndex
      );
    },
  });

  return (
    <>
      <div className={style["modal-overlay-overlay"]}></div>
      <div className={style["modal-card-wrapper"]}>
        <div className={`card  ${style["modal-card"]} w-75`} role="dialog">
          <div className="modal-dialog w-100">
            <div className="modal-content">
              <div
                className={` ${style["modal-header"]} text-bg-secondary `}
                data-bs-theme="dark"
              >
                <h5 className="modal-title" id="staticBackdropLabel">
                  INVENTORY COUNT
                </h5>
                <button
                  type="button"
                  className="btn-close dark-mode"
                  onClick={closeModal}
                  aria-label="Close"
                ></button>
              </div>
              <div
                className="modal-body row g-3"
                style={{ padding: "1em 2em" }}
              >
                <form onSubmit={formik.handleSubmit}>
                  <div class="row">
                    <div class="col-sm-3">
                      <fieldset>
                        <label class="lbltheme text-uppercase">Location</label>
                        <label class="lblrequired lbltheme text-uppercase">
                          {storagelocation}
                        </label>
                      </fieldset>
                    </div>
                    <div class="col-sm-3">
                      <fieldset>
                        <label class="lbltheme text-uppercase">
                          Ingredient
                        </label>
                        <label class="lblrequired lbltheme text-uppercase">
                          {IngInventory.ingredient.name}
                        </label>
                      </fieldset>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-sm-2">
                      <fieldset>
                        <label
                          htmlFor="size_on_hand"
                          class="lblrequired lbltheme"
                        >
                          Size*
                        </label>
                        <select
                          className="form-select"
                          name="size_on_hand"
                          id="size_on_hand"
                          value={formik.values.size_on_hand}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        >
                          <option checked></option>
                          <option
                            // key={IngInventory.inventory_id.id}
                            value={IngInventory.size_on_hand}
                          >
                            {IngInventory.size_on_hand}
                          </option>
                        </select>
                      </fieldset>
                    </div>
                    <div class="col-sm-2">
                      <fieldset>
                        <label
                          htmlFor="qty_on_hand"
                          class="lblrequired lbltheme"
                        >
                          Add Count*
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="qty_on_hand"
                          name="qty_on_hand"
                          value={formik.values.qty_on_hand}
                          onChange={formik.handleChange}
                        />
                      </fieldset>
                    </div>
                    <div class="col d-flex align-self-end justify-content-start">
                      <button
                        type="submit"
                        class="btn btn-link btn-just-icon"
                        id="QuickAddInventory"
                        name="QuickAddInventory"
                        title="Add"
                      >
                        <FaSave className="save-icon" />
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const EditIngredientInventory = (props) => {
  return ReactDOM.createPortal(
    <EditIngredientInventoryModal
      onClick={props.onClick}
      storagelocation={props.storagelocation}
      IngInventory={props.IngInventory}
      IngInventoryIndex={props.IngInventoryIndex}
      updateIngInventory={props.updateIngInventory}
    />,
    document.getElementById("modal")
  );
};
export default EditIngredientInventory;
