import React, { useEffect, useState } from "react";
import MainPanel from "../../components/MainPanel";
import Table from "../../components/Table";
import { useParams } from "react-router-dom";
import useHttp from "../../hooks/useHttp";

const SupplierDetail = () => {
  const { id } = useParams();
  const [hasError, handleHttpRequest] = useHttp();

  const [initialValues, setInitialValues] = useState({
    name: "",
    account: "",
    address: "",
    note: "",
    sales_person: "",
    email: "",
    phone: "",
    sales_person_email: "",
    sales_person_phone: "",
    ar_email: "",
    ar_phone: "",
  });
  const [supplierIngredients, setSupplierIngredients] = useState([]);

  const getSupplier = async (id) => {
    try {
      const response = await handleHttpRequest({
        page: "supplier/detail/" + id,
        method: "GET",
      });
      setInitialValues(response.message);
      response.message.suppliercontacts.forEach((element) => {
        switch (element.type) {
          case "supplier": {
            setInitialValues((prev) => {
              return { ...prev, email: element.email, phone: element.phone };
            });
            break;
          }
          case "sales_person": {
            setInitialValues((prev) => {
              return {
                ...prev,
                sales_person_email: element.email,
                sales_person_phone: element.phone,
              };
            });
            break;
          }
          case "ar": {
            setInitialValues((prev) => {
              return {
                ...prev,
                ar_email: element.email,
                ar_phone: element.phone,
              };
            });
            break;
          }
        }
      });

      setSupplierIngredients(response.message.supplieringredient);
    } catch (err) {
      console.error("Error", err);
    }
  };

  useEffect(() => {
    getSupplier(id);
  }, []);

  const COLUMNS = [
    {
      Header: "Supplier Item Description",
      accessor: "supplier_item_desc",
    },
    {
      Header: "Supplier Item #",
      accessor: "supplier_item_number",
    },
    {
      Header: "Supplier Sales By",
      accessor: "sold_by",
    },
    {
      Header: "Price per Default Item",
      accessor: "price_per_item",
      Cell: ({ value }) => "$" + value,
    },
  ];

  const formatPhoneNumber = (phone) => {
    const match = phone?.match(/^(\d{3})(\d{3})(\d{4})/);
    if (match) {
      return "(" + match[1] + ")" + " " + match[2] + " " + match[3];
    }
  };

  return (
    <MainPanel>
      <div className="card">
        <div className="card-header text-bg-secondary">
          <div className="row">
            <h4 className="card-title text-uppercase">{initialValues.name}</h4>
          </div>
        </div>
        <div className="card-body" style={{ padding: "1em 3em" }}>
          <div className="row mt-4">
            <div className="col-md-6">
              <div className="row">
                <div className="col-lg-3 col-md-6 text-end">
                  <label className="form-label lblrequired">
                    Supplier Name:
                  </label>
                </div>
                <div className="col-lg-3 col-md-6 text-start">
                  <label className="form-label">{initialValues.name}</label>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3 col-md-6 text-end">
                  <label className="form-label lblrequired">Address:</label>
                </div>
                <div className="col-lg-3 col-md-6 text-start">
                  <label className="form-label">{initialValues.address}</label>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3 col-md-6 text-end">
                  <label className="form-label lblrequired">
                    Phone Number:
                  </label>
                </div>
                <div className="col-lg-3 col-md-6 text-start">
                  <label className="form-label">
                    {formatPhoneNumber(initialValues.phone)}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3 col-md-6 text-end">
                  <label className="form-label lblrequired">Email:</label>
                </div>
                <div className="col-lg-3 col-md-6 text-start">
                  <label className="form-label">{initialValues.email}</label>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-lg-3 col-md-6 text-end">
                  <label className="form-label lblrequired">Account:</label>
                </div>
                <div className="col-lg-3 col-md-6 text-start">
                  <label className="form-label">{initialValues.account}</label>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3 col-md-6 text-end">
                  <label className="form-label lblrequired">
                    Supplier notes:
                  </label>
                </div>
                <div className="col-lg-3 col-md-6 text-start">
                  <label className="form-label">{initialValues.note}</label>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-12">
                  <h5>Sales Person Representative</h5>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3 col-md-6 text-end">
                  <label className="form-label lblrequired">Name:</label>
                </div>
                <div className="col-lg-3 col-md-6 text-start">
                  <label className="form-label">
                    {initialValues.sales_person}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3 col-md-6 text-end">
                  <label className="form-label lblrequired">Email:</label>
                </div>
                <div className="col-lg-3 col-md-6 text-start">
                  <label className="form-label">
                    {initialValues.sales_person_email}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3 col-md-6 text-end">
                  <label className="form-label lblrequired">
                    Phone Number:
                  </label>
                </div>
                <div className="col-lg-3 col-md-6 text-start">
                  <label className="form-label">
                    {initialValues.sales_person_phone}
                  </label>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-12">
                  <h5>AR Contact Information</h5>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3 col-md-6 text-end">
                  <label className="form-label lblrequired">
                    Phone Number:
                  </label>
                </div>
                <div className="col-lg-3 col-md-6 text-start">
                  <label className="form-label">{initialValues.ar_phone}</label>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3 col-md-6 text-end">
                  <label className="form-label lblrequired">Email:</label>
                </div>
                <div className="col-lg-3 col-md-6 text-start">
                  <label className="form-label">{initialValues.ar_email}</label>
                </div>
              </div>
            </div>
          </div>

          <div className="toolbar"></div>
          <div className="row mt-4">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a class="nav-link active" aria-current="page" href="#">
                  Option for Ingredients
                </a>
              </li>
            </ul>
            <div class="tab-content mt-4" id="myTabContent">
              <div
                class="tab-pane fade show active"
                id="supplieringreds-tab-pane"
                role="tabpanel"
                aria-labelledby="supplieringreds-tab"
                tabindex="0"
              >
                <Table data={supplierIngredients} columns={COLUMNS} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainPanel>
  );
};

export default SupplierDetail;
