import React, { useState } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import useHttp from "../../hooks/useHttp";
import style from "../../assets/style/modal.module.css";
import { read, utils } from "xlsx";
import CSVReader from "react-csv-reader";
// import style from '../../assets/style/table.module.css';

export const ImportExcelModal = ({ onClose, onImport, onError }) => {
  const [hasError, handleHttpRequest] = useHttp();
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("auth_token");
  const [errorMessage, setErrorMessage] = useState("");

  const [data, setData] = useState([]);
  const [sliceData, setSliceData] = useState([]);
  const [file, setFile] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [fieldMapping, setFieldMapping] = useState({
    name: "",
    uom: "",
    price: "",
    category: "",
    primary_supplier: "",
    secondary_supplier: "",
  });

  // const handleFileLoad = (data, fileInfo, file) => {
  //   // const reader = new FileReader();
  //   // reader.onload = (event) => {
  //   //   const binaryStr = event.target.result;
  //   //   const workbook = read(binaryStr, { type: "binary" });

  //   //   //assuming the first sheet is the one you want to work with
  //   //   const sheetName = workbook.SheetNames[0];
  //   //   const sheet = workbook.Sheets[sheetName];

  //   //   const jsonData = utils.sheet_to_json(sheet);
  //   //   setData(jsonData);
  //   // };
  //   // reader.readAsArrayBuffer(fileInfo);
  //   setData(data);
  //   // setFile(file);
  //   // if (data.length > 0) setErrorMessage("");
  // };

  const handleFileLoad = (event) => {
    console.log(event.target.files[0]);
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const binaryStr = event.target.result;
        const workbook = read(binaryStr, { type: "binary" });
        //assuming the first sheet is the one you want to work with
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        const jsonData = utils.sheet_to_json(sheet, { header: 1 });

        const fileHeaders = jsonData[0];
        const fileData = jsonData.slice(1);
        const slice = jsonData.slice(1, 4);

        console.log("file headers", fileHeaders);
        setHeaders(fileHeaders);
        setData(fileData);
        setSliceData(slice);
      };
      reader.readAsBinaryString(file); //readAsArrayBuffer(fileInfo);
    }
    setFile(file);
  };

  const handleColumnSelection = (index) => {
    setSelectedColumns((prev) => {
      if (prev.includes(index)) {
        return prev.filter((i) => i !== index);
      } else {
        return [...prev, index];
      }
    });
  };
  const handleColumnMapping = (event) => {
    const { name, value } = event.target;
    setFieldMapping((prev) => ({ ...prev, [name]: value }));
  };

  const uploadFile = async () => {
    // const columnsToImport = selectedColumns;
    // const rowsToImport = data.map((row) => {
    //   const rowData = {};
    //   columnsToImport.forEach((col, index) => {
    //     rowData[col] = row[headers.indexOf(col)];
    //   });
    //   return rowData;
    // });
    if (file.length == 0) {
      //data.length == 0 ||
      setErrorMessage("File is required.");
      return;
    }
    const formData = new FormData();
    formData.append("file", file);
    formData.append("rows", JSON.stringify(fieldMapping));
    console.log(...formData);

    const mappedData = data.map((row) => {
      return {
        name: row[headers.indexOf(fieldMapping.name)],
        uom: row[headers.indexOf(fieldMapping.uom)],
        price: row[headers.indexOf(fieldMapping.price)],
        category: row[headers.indexOf(fieldMapping.category)],
        primary_supplier: row[headers.indexOf(fieldMapping.primary_supplier)],
        secondary_supplier:
          row[headers.indexOf(fieldMapping.secondary_supplier)],
      };
    });
    axios
      .post(
        apiUrl + "/ingredient/import",
        formData,
        // [{ rows: fieldMapping }, formData],
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log("File successfully uploaded", response);
        onImport(response.data);
      })
      .catch((error) => {
        console.log("error:", error);
        // error.response.data.errors.file
        //   ? error.response.data.errors.file.map((element) => onError(element))
        //   : error.response.data.errors.map((element) =>
        //       onError(element.errors[0])
        //     );
        // console.log(
        //   "There was an error uploading the file!",
        //   error.response.data.errors.file
        // );
      });
  };

  return (
    <>
      <div className={style["modal-overlay"]}></div>
      <div className={style["modal-card-wrapper"]}>
        <div
          id={style["deleteModal"]}
          className={`card  ${style["modal-card"]} ${
            data.length > 0 && "w-75"
          }`}
          role="dialog"
        >
          <div className="modal-dialog w-100">
            <div className="modal-content">
              <div
                className={` ${style["modal-header"]} text-bg-secondary`}
                data-bs-theme="dark"
              >
                <h5 className="modal-title" id="staticBackdropLabel">
                  Import Ingredient
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={onClose}
                  aria-label="Close"
                ></button>
              </div>
              <div className={style["modal-content"]}>
                {/* <CSVReader onFileLoaded={handleFileLoad} /> */}
                <input
                  type="file"
                  name="file"
                  accept=".xlsx, .xls, .csv"
                  onChange={handleFileLoad}
                />
                <div className="row">
                  <div className="col-sm-12 mt-2">
                    <h5>Map import fields to the system</h5>
                  </div>
                  <div className="col-sm-10">
                    <div className="row m-2">
                      <div className="col-sm-6">
                        <label>Fields in the system</label>
                      </div>
                      <div className="col-sm-6">
                        <label>Fields in file</label>
                      </div>
                    </div>
                    {/* Ingredient Name */}
                    <div className="row m-2">
                      <div className="col-sm-6">
                        <label>Ingredient Name</label>
                      </div>
                      <div className="col-sm-6">
                        <select
                          className="form-select"
                          id="name"
                          name="name"
                          value={fieldMapping.name}
                          onChange={handleColumnMapping}
                        >
                          <option value="">Select Column</option>
                          {headers.map((header) => {
                            return (
                              <option key={header} value={header}>
                                {header}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    {/* UOM */}
                    <div className="row m-2">
                      <div className="col-sm-6">
                        <label>Unit of measure</label>
                      </div>
                      <div className="col-sm-6">
                        <select
                          className="form-select"
                          id="uom"
                          name="uom"
                          value={fieldMapping.uom}
                          onChange={handleColumnMapping}
                        >
                          <option value="">Select Column</option>
                          {headers.map((header) => {
                            return (
                              <option key={header} value={header}>
                                {header}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    {/* Price */}
                    <div className="row m-2">
                      <div className="col-sm-6">
                        <label>Price</label>
                      </div>
                      <div className="col-sm-6">
                        <select
                          className="form-select"
                          id="price"
                          name="price"
                          value={fieldMapping.price}
                          onChange={handleColumnMapping}
                        >
                          <option value="">Select Column</option>
                          {headers.map((header) => {
                            return (
                              <option key={header} value={header}>
                                {header}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    {/* Category */}
                    <div className="row m-2">
                      <div className="col-sm-6">
                        <label>Category</label>
                      </div>
                      <div className="col-sm-6">
                        <select
                          className="form-select"
                          id="category"
                          name="category"
                          value={fieldMapping.category}
                          onChange={handleColumnMapping}
                        >
                          <option value="">Select Column</option>
                          {headers.map((header) => {
                            return (
                              <option key={header} value={header}>
                                {header}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    {/* Primary Supplier */}
                    <div className="row m-2">
                      <div className="col-sm-6">
                        <label>Primary Supplier</label>
                      </div>
                      <div className="col-sm-6">
                        <select
                          className="form-select"
                          id="primary_supplier"
                          name="primary_supplier"
                          value={fieldMapping.primary_supplier}
                          onChange={handleColumnMapping}
                        >
                          <option value="">Select Column</option>
                          {headers.map((header) => {
                            return (
                              <option key={header} value={header}>
                                {header}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    {/* Secondary Supplier */}
                    <div className="row m-2">
                      <div className="col-sm-6">
                        <label>Secondary Supplier</label>
                      </div>
                      <div className="col-sm-6">
                        <select
                          className="form-select"
                          id="secondary_supplier"
                          name="secondary_supplier"
                          value={fieldMapping.secondary_supplier}
                          onChange={handleColumnMapping}
                        >
                          <option value="">Select Column</option>
                          {headers.map((header) => {
                            return (
                              <option key={header} value={header}>
                                {header}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    {/* <table className="table display compact mt-4" width="100%">
                      <thead>
                        <tr>
                          {
                            //headers.length > 0 &&
                            headers.map((header) => {
                              return (
                                <th key={header}>
                                  <input
                                    type="checkbox"
                                    checked={selectedColumns.includes(header)}
                                    onChange={() =>
                                      handleColumnSelection(header)
                                    }
                                  />
                                  <lable>{header}</lable>
                                </th>
                              );
                            })
                          }
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          {sliceData.map((row, index) => {
                            return (
                              <tr key={index} class="col-2">
                                
                                {Object.values(row).map((value, cellIndex) => (
                                  <td key={cellIndex}>{value}</td>
                                ))}
                              </tr>
                            );
                          })}
                        </tr>
                      </tbody>
                    </table> */}

                    {/* <table>
                      <thead>
                        <tr>
                          <th class="col-2" scope="col">
                            Name
                          </th>
                          <th class="col-2" scope="col">
                            UOM
                          </th>
                          <th class="col-2" scope="col">
                            Price
                          </th>
                          <th class="col-2" scope="col">
                            Category
                          </th>
                          <th class="col-2" scope="col">
                            Primary Supplier
                          </th>
                          <th class="col-2" scope="col">
                            Secondary Supplier
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr></tr>
                      </tbody>
                    </table> */}
                  </div>

                  {/* <div className="col-sm-3">
                    <table>
                      <thead>
                        <th>Select Header</th>
                      </thead>
                      <tbody>
                        {headers.map((header) => {
                          return (
                            <tr key={header}>
                              <td>
                                <input
                                  type="checkbox"
                                  checked={selectedColumns.includes(header)}
                                  onChange={() => handleColumnSelection(header)}
                                />
                                {header}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div> */}
                </div>
              </div>
              <div
                className={`${style["modal-footer"]} justify-content-between`}
              >
                <div className="d-flex">
                  {errorMessage != "" && (
                    <span className="error-msg"> {errorMessage}</span>
                  )}
                </div>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={uploadFile}
                >
                  Upload
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ImportExcel = (props) => {
  return ReactDOM.createPortal(
    <ImportExcelModal
      onClose={props.onClose}
      onImport={props.onImport}
      onError={props.onError}
    />,
    document.getElementById("modal")
  );
};
export default ImportExcel;
