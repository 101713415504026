import ReactDOM from "react-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import style from "../../assets/style/modal.module.css";
import { IoIosSave } from "react-icons/io";
import SuppliersForIngredients from "./SuppliersForIngredients";
import useHttp from "../../hooks/useHttp";
import useConversion from "../../hooks/useConversion";

const EditCartItemModal = ({ cartItem, onEdit, onCancle }) => {
  const [IsStandardUnit, convertWeight] = useConversion();
  const [initialValues, setInitialValues] = useState({
    qty_persellby: cartItem.qtyPerSellBy,
    supplier_id: cartItem.supplierId, //cartItem.
    supplier_ingredient_id: cartItem.supplierIngredientId,
  });
  const [caseSizeIndelfUnit, setCaseSizeIndelfUnit] = useState(
    cartItem.caseSizeIndelfUnit
  );
  const [cartDescription, setCartDescription] = useState(cartItem.description);
  const [_, handleHttpRequest] = useHttp();

  const handleSupplierIngreident = (suppIng) => {
    setInitialValues((prev) => ({
      ...prev,
      supplier_ingredient_id: suppIng?.id,
    }));

    let sizeIndelfUnit;
    let caseSizeInPackUOM = suppIng.packs_per_sold_by * suppIng.pack_unit_size;
    if (IsStandardUnit(cartItem.unitMeasure) == "Y") {
      // sizeIndelfUnit = convertWeight(
      //   caseSizeInPackUOM,
      //   suppIng.pack_unit_measure,
      //   cartItem.unitMeasure
      // );
      sizeIndelfUnit = convertWeight(
        suppIng.pack_unit_measure,
        suppIng.pack_unit_size,
        suppIng.packs_per_sold_by,
        cartItem.unitMeasure
      ); // e.g. 4800g is 10.58lbs (if default unit is lb)
    } else {
      sizeIndelfUnit = suppIng.conv_factor; //if default unit is each, 1 case could be e.g 20 each
    }
    const description =
      suppIng.packs_per_sold_by +
      " x (" +
      suppIng.pack_unit_size +
      "" +
      suppIng.pack_unit_desc +
      " )";
    setCartDescription(description);
    setCaseSizeIndelfUnit(sizeIndelfUnit); //calculate the case size in default unit.
  };

  const updateCart = async (values) => {
    // onEdit
    const response = await handleHttpRequest({
      page: "cart/" + cartItem.id,
      method: "PUT",
      data: values,
    });
    onEdit(response);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      supplier_id: Yup.number().required("Supplier Name is required"),
      qty_persellby: Yup.number().required("Insert quantity to order"),
      supplier_ingredient_id: Yup.number(),
    }),
    onSubmit: async (values) => {
      console.log("Put values are: ", values);
      updateCart(values);
      formik.resetForm();
    },
  });

  return (
    <>
      <div className={style["modal-overlay"]}></div>
      <div className={style["modal-card-wrapper"]}>
        <div className={`card  ${style["modal-card"]}`} role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div
                className={` ${style["modal-header"]} text-bg-secondary`}
                data-bs-theme="dark"
              >
                <h5 className="modal-title" id="staticBackdropLabel">
                  Edit Cart Item
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={onCancle}
                  aria-label="Close"
                ></button>
              </div>
              <form class="" onSubmit={formik.handleSubmit}>
                <div className={style["modal-content"]}>
                  <div className="modal-body row g-3">
                    <div className="row mt-4">
                      <div className="col-md-8">
                        <div>
                          <strong>Item</strong>
                        </div>
                        <div>{cartItem.ingredient}</div>
                      </div>
                      <div class="col-md-4">
                        <div>
                          <strong>Item Description</strong>
                        </div>
                        <div> {cartDescription}</div>
                      </div>
                    </div>

                    <div className="row mt-4">
                      <div className="col-md-8">
                        <div>
                          <strong>Qty needed</strong>
                        </div>
                        <div>
                          {cartItem.quantityToOrder} {cartItem.unitMeasure}
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div>
                          <strong>Case size in defalut unit</strong>
                        </div>
                        <div>
                          {" "}
                          {caseSizeIndelfUnit}
                          {cartItem.unitMeasure}
                        </div>
                      </div>
                    </div>

                    <div className="row mt-2">
                      <div className="col-md-8">
                        <label
                          className="form-label lblrequired"
                          htmlFor="name"
                        >
                          Supplier Name *
                        </label>
                        <SuppliersForIngredients
                          value={formik.values.supplier_id}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          ingId={cartItem.ingredientId}
                          supplierIngreident={handleSupplierIngreident}
                        />
                      </div>
                      <div class="col-md-4">
                        <label className="form-label" htmlFor="account">
                          Quantity
                        </label>
                        <input
                          type="number"
                          class="form-control"
                          id="qty_persellby"
                          name="qty_persellby"
                          value={formik.values.qty_persellby}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={style["modal-footer"]}>
                  <span>
                    {formik.touched.qty_persellby && formik.errors.qty_persellby
                      ? formik.errors.qty_persellby
                      : formik.touched.supplier_id && formik.errors.supplier_id
                      ? formik.errors.supplier_id
                      : null}
                    {console.log("Formik errors: ", formik.errors)}
                  </span>
                  <button type="submit" className="btn btn-link btn-just-icon">
                    <IoIosSave className="save-icon" />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const EditCartItem = (props) => {
  return ReactDOM.createPortal(
    <EditCartItemModal
      // cartId={props.cartId}
      // ingId={props.ingredientId}
      cartItem={props.cartItem}
      onEdit={props.onEdit}
      onCancle={props.onCancle}
    />,
    document.getElementById("modal")
  );
};

export default EditCartItem;
