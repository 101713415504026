import { Link } from "react-router-dom";
import { useState } from "react";
import { FaRegEdit, FaPlus } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaStar, FaStarHalfAlt } from "react-icons/fa";
import { FaListCheck } from "react-icons/fa6";
import { BiMessageSquareDetail } from "react-icons/bi";
import { MdOutlineDoNotDisturbAlt } from "react-icons/md";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";

const RolesButtonGroup = ({
  cell,
  editRole,
  openSetDefaultRoleModal,
  openDeleteModal,
}) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const ontoggle = () => {
    setShowDropDown((state) => !state);
  };
  return (
    <div class="btn-group">
      <button
        type="button"
        className="btn btntheme"
        onClick={() => {
          editRole(cell.value);
        }}
      >
        {" "}
        <FaRegEdit /> Edit
      </button>
      <button
        type="button"
        className={`btn btntheme dropdown-toggle  ${
          showDropDown ? "show" : ""
        }`}
        data-bs-toggle="dropdown"
        aria-expanded={`${showDropDown ? "true" : "false"}`}
        onClick={ontoggle}
      >
        <span className="visually-hidden">Toggle Dropdown</span>
      </button>
      <ul className={`dropdown-menu ${showDropDown ? "show" : ""}`}>
        <li>
          <button
            className="btn btn-link btn-just-icon themecolor delcount"
            title="Add Permissions"
          >
            <Link
              className="text-decoration-none"
              to={`/role-permissions/${cell.value}`}
            >
              <FaListCheck
                className="themecolor"
                style={{ fontSize: "16px" }}
              />{" "}
              Add permissions
            </Link>
          </button>
        </li>
        {cell.row.original.is_default != true && (
          <li>
            <button
              type="button"
              className="dropdown-item btn btn-light text-decoration-none"
              title="Inactive"
              onClick={() => {
                openSetDefaultRoleModal(cell.value);
              }}
            >
              <IoMdCheckmarkCircleOutline className="themecolor" />
              Set role as default
            </button>
          </li>
        )}
        {cell.row.original.name != "admin" && (
          <li>
            <button
              className="btn btn-link btn-just-icon delcount text-decoration-none"
              style={{ color: "#676767" }}
              title="Delete Role"
              onClick={() => {
                openDeleteModal(cell.value);
              }}
            >
              <RiDeleteBin6Line /> Delete role
            </button>
          </li>
        )}
      </ul>
    </div>
  );
};
export default RolesButtonGroup;
