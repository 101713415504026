import React, { useRef, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import SuppliersList from "../suppliers/supplierslist";
import units from "../../utils/units";
import useHttp from "../../hooks/useHttp";
// import useConversion from "../../hooks/useConversion";
import useUnitConversion from "../../hooks/useUnitConversion";
import { FaList } from "react-icons/fa";
import { IoIosSave } from "react-icons/io";
import style from "../../assets/style/modal.module.css";
import FormInput from "../../components/form/FormInput";
import FormSelect from "../../components/form/FormSelect";

export const AddSupplierIngredientModal = ({
  ingredient_id,
  saveClick,
  onClick,
}) => {
  const [hasError, handleHttpRequest] = useHttp();

  const closeModal = () => {
    formik.resetForm();
    onClick();
  };

  const addSupplierIngredient = async (values) => {
    const response = await handleHttpRequest({
      page: "supplieringredient/add",
      method: "POST",
      data: {
        ...values,
        use_for_last_price: useLastPrice,
      },
    });

    if (!response.status) {
      saveClick(response, 200);
    } else {
      saveClick(response.data, response.status);
    }
  };

  const [showConvFac, setShowConvFac] = useState(false);
  const [IsStandardUnit, convertUnit] = useUnitConversion();
  const dfltUnits = useRef();
  const convFactor = useRef(1);

  const [initialValues, setInitialValues] = useState({
    ingredient_id: 0,
    supplier_id: 0,
    supplier_item_number: 0,
    supplier_item_desc: 0,
    min_order_qty: 0,
    sold_by: 0,
    price_per_sold_by: 0,
    packs_per_sold_by: 0,
    pack_unit_measure: "",
    pack_unit_size: 0,
    pack_unit_desc: 0,
    price_opt_price: 0,
    price_opt_measure: 0,
    conv_factor: 0,
  });
  const getIngredient = async (id) => {
    // const response = await handleHttpRequest("ingredient/" + id);
    const response = await handleHttpRequest({
      page: "ingredient/" + id,
      method: "GET",
    });
    if (!hasError) {
      dfltUnits.current = response.message.default_unit_measure;
      setInitialValues({
        ingredient_id: id,
        supplier_id: "",
        supplier_item_number: "",
        supplier_item_desc: response.message.name,
        min_order_qty: "",
        sold_by: "",
        price_per_sold_by: 0.0,
        packs_per_sold_by: 1,
        pack_unit_measure: "",
        pack_unit_size: 1,
        pack_unit_desc: "",
        price_per_item: 0,
        price_opt: "op",
        price_opt_price: 0.0,
        price_opt_measure: "",
        conv_factor: 1,
      });
    }
  };
  useEffect(() => {
    getIngredient(ingredient_id.current);
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      ingredient_id: Yup.number().required("Ingredient is required."),
      supplier_id: Yup.number().required(
        "Select a supplier for the Ingredient."
      ),
      supplier_item_number: Yup.string().max(100, "Item number is too long"),
      supplier_item_desc: Yup.string()
        .required("Supplier item description is required")
        .max(100, "Description is too long"),
      min_order_qty: Yup.number().notRequired(),
      sold_by: Yup.string().max(100, "Name is too long"),
      price_per_sold_by: Yup.number(), //.required("Price per sold by is required"),
      packs_per_sold_by: Yup.number(), //.required("Pack per sold by is required"),
      pack_unit_measure: Yup.string().required("Pack unit measure is required"),
      pack_unit_size: Yup.number().required("Pack unit size is required"),
      pack_unit_desc: Yup.string().max(100, "Description is too long"),
      //price_opt: Yup.string().required("Supplier Name is required").max(100, "Name is too long"),
      price_opt_price: Yup.number(),
      price_opt_measure: Yup.string().max(100, "Name is too long"),
      conv_factor: Yup.number().positive(),
    }),
    onSubmit: (values) => {
      values.sold_by = values.sold_by == "" ? "case" : values.sold_by;
      addSupplierIngredient(values);
      formik.resetForm();
    },
  });

  const pricePerCase = useRef(0);
  //latest sol
  const calcCasePrice = (e) => {
    if (
      byCase != "bycustom" ||
      formik.values.price_opt_measure === "" ||
      !IsStandardUnit(formik.values.pack_unit_measure)
    ) {
      return;
    }
    const totalUnit =
      formik.values.packs_per_sold_by * formik.values.pack_unit_size;
    pricePerCase.current = (
      formik.values.price_opt_price *
      convertUnit(
        totalUnit,
        formik.values.pack_unit_measure,
        formik.values.price_opt_measure
      )
    ).toFixed(2);
    //formik.setFieldValue("price_per_sold_by", pricePerCase);
  };

  //Latest sol
  const getPricePerItem = () => {
    if (formik.values.pack_unit_measure === "") {
      return;
    }
    calcCasePrice();
    convFactor.current = formik.values.conv_factor;
    let price_per_item;
    //Units are the same, no conversion needed
    if (dfltUnits.current == formik.values.pack_unit_measure) {
      price_per_item = calPricePerItem({
        dfltUnitQty: formik.values.pack_unit_size,
      }); //packUnitSize.current
      //setShowConvFac(false);
    } // else do conversion
    else {
      if (
        !IsStandardUnit(dfltUnits.current) ||
        !IsStandardUnit(formik.values.pack_unit_measure)
      ) {
        //setShowConvFac(true);
        //Non-standard unit, like Each, was selected
        price_per_item = calPricePerItem({
          packsPerSoldBy: convFactor.current,
        });
      } else {
        // setShowConvFac(false);
        let dfltUnitQty = convertUnit(
          formik.values.pack_unit_size,
          formik.values.pack_unit_measure,
          dfltUnits.current
        );

        price_per_item = calPricePerItem({
          dfltUnitQty: dfltUnitQty,
        });
      }
    }
    formik.values.price_per_item = price_per_item; //pricePerItem.current;
    //setPricePerItem(price_per_item);
    return price_per_item; //pricePerItem.current;
  };

  //Latest sol
  const calPricePerItem = ({
    packsPerSoldBy = formik.values.packs_per_sold_by,
    dfltUnitQty = 1,
  }) => {
    const price =
      byCase == "bycase"
        ? formik.values.price_per_sold_by
        : pricePerCase.current;
    return (price / (packsPerSoldBy * dfltUnitQty)).toFixed(2);
  };

  //Latest sol
  const showConversionFactor = (e) => {
    if (e.target.name === "pack_unit_measure") {
      if (e.target.value === dfltUnits.current) {
        setShowConvFac(false);
      } else if (
        !IsStandardUnit(dfltUnits.current) ||
        (IsStandardUnit(dfltUnits.current) && !IsStandardUnit(e.target.value))
      ) {
        setShowConvFac(true);
      } else {
        setShowConvFac(false);
      }
    }
  };

  //Latest sol
  const handleBlur = (e) => {
    // calculatePricePerItem();
    showConversionFactor(e);
    getPricePerItem();
  };

  //updated ... latest sol
  const handleCalculate = (e) => {
    formik.setFieldValue(e.target.name, e.target.value);
    // formik.handleChange(e);
    // calculatePricePerItem(e);
    showConversionFactor(e);
    getPricePerItem();
  };

  const [byCase, setByCase] = useState("bycase");
  const setByHandler = (event) => {
    setByCase(event.target.value);
  };

  const [useLastPrice, setUseLastPrice] = useState(true);
  const setUseLastPriceHandler = () => {
    setUseLastPrice((state) => !state);
  };

  return (
    <>
      <div className={style["modal-overlay-overlay"]}></div>
      <div className={style["modal-card-wrapper"]}>
        <div className={`card  ${style["modal-card"]} mx-5`} role="dialog">
          <div className="modal-dialog w-100">
            <div className="modal-content">
              <div
                className={` ${style["modal-header"]} text-bg-secondary`}
                data-bs-theme="dark"
              >
                <h5
                  className="modal-title text-uppercase"
                  id="staticBackdropLabel"
                >
                  Supplier Option for {initialValues.supplier_item_desc}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={closeModal}
                  aria-label="Close"
                ></button>
              </div>
              <form className="" onSubmit={formik.handleSubmit}>
                <div className={style["modal-content"]}>
                  <div className="modal-body row g-3">
                    <div className="row mt-4">
                      <div className="col-sm-6">
                        <fieldset>
                          <label
                            className="lblrequired lbltheme"
                            htmlFor="supplier_id"
                          >
                            Supplier*
                          </label>
                          <SuppliersList
                            value={formik.values.supplier_id}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </fieldset>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-4">
                        <FormInput
                          label="Order Item Description*"
                          name="supplier_item_desc"
                          value={formik.values.supplier_item_desc}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      <div className="col-sm-4 col-lg-3">
                        <FormInput
                          label="Order Item #"
                          name="supplier_item_number"
                          value={formik.values.supplier_item_number}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      <div className="col-sm-4 col-lg-3">
                        <FormInput
                          label="Min Order Qty (by sellby)"
                          name="min_order_qty"
                          value={formik.values.min_order_qty}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          type="number"
                        />
                      </div>
                    </div>

                    <div className="row mt-4">
                      <div class="col-lg-3 col-sm-4 form-check">
                        <fieldset>
                          <input
                            type="radio"
                            className="form-check-input"
                            id="name"
                            name="name"
                            value="bycase"
                            checked={byCase == "bycase"}
                            onChange={setByHandler}
                          />
                          <label
                            className="form-check-label lbltheme"
                            htmlFor="flexRadioDefault1"
                          >
                            Supplier prices by{" "}
                            {formik.values.sold_by == ""
                              ? "case"
                              : formik.values.sold_by}
                          </label>
                        </fieldset>
                      </div>
                      <div class="col-lg-3 col-sm-6 form-check">
                        <fieldset>
                          <input
                            type="radio"
                            className="form-check-input"
                            id="name"
                            name="name"
                            value="bycustom"
                            checked={byCase == "bycustom"}
                            onChange={setByHandler}
                          />
                          <label
                            className="form-check-label lbltheme"
                            htmlFor="flexRadioDefault2"
                          >
                            Supplier prices by alternate unit
                          </label>
                        </fieldset>
                      </div>
                    </div>
                    {byCase == "bycustom" && (
                      <div className="row">
                        <div className="col-lg-3 col-sm-4">
                          <FormInput
                            label="$Price per Alternate Unit*"
                            name="price_opt_price"
                            value={formik.values.price_opt_price}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                        <div className="col-lg-3 col-sm-4">
                          <FormSelect
                            label="Unit of Measurement*"
                            name="price_opt_measure"
                            value={formik.values.price_opt_measure}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            title="Select Measurement"
                            optionListing={units.map((unit) => (
                              //only standard unit of measurements
                              <option key={unit.id} value={unit.unit}>
                                {unit.name}
                              </option>
                            ))}
                          />
                        </div>
                      </div>
                    )}
                    <hr></hr>

                    <div className="row">
                      <div className="col-lg-2 col-sm-4">
                        <FormInput
                          label="#Packs per case*"
                          name="packs_per_sold_by"
                          value={formik.values.packs_per_sold_by}
                          onChange={handleCalculate}
                          onBlur={handleBlur}
                          type="number"
                        />
                      </div>
                      <div className="col-lg-2 col-sm-4">
                        <FormInput
                          label="#Pack Unit Size*"
                          name="pack_unit_size"
                          value={formik.values.pack_unit_size}
                          onChange={handleCalculate}
                          onBlur={handleBlur}
                        />
                      </div>
                      <div className="col-lg-2 col-sm-4">
                        <FormSelect
                          label="Pack Unit Measurement*"
                          name="pack_unit_measure"
                          value={formik.values.pack_unit_measure}
                          onChange={handleCalculate}
                          onBlur={handleBlur}
                          title="Select Measurement"
                          optionListing={units.map((unit) => (
                            <option key={unit.id} value={unit.unit}>
                              {unit.name}
                            </option>
                          ))}
                        />
                      </div>
                      <div className="col-lg-2 col-sm-4">
                        <FormInput
                          label="Pack Unit Description"
                          name="pack_unit_desc"
                          value={formik.values.pack_unit_desc}
                          onChange={handleCalculate}
                          onBlur={handleBlur}
                        />
                      </div>
                      <div className="col-lg-2 col-sm-4">
                        {byCase == "bycase" ? (
                          <FormInput
                            label="$Price per case*"
                            name="price_per_sold_by"
                            value={formik.values.price_per_sold_by}
                            onChange={handleCalculate}
                            onBlur={handleBlur}
                          />
                        ) : (
                          <FormInput
                            label="$Price per case*"
                            name="price_per_sold_by"
                            value={pricePerCase.current}
                            disabled
                          />
                        )}
                      </div>
                      <div className="col-lg-2 col-sm-4">
                        <FormInput
                          label="Sells By Description"
                          name="sold_by"
                          value={formik.values.sold_by}
                          onChange={handleCalculate}
                          onBlur={handleBlur}
                        />
                      </div>
                    </div>

                    {showConvFac && formik.values.pack_unit_measure != "" && (
                      <div className="row">
                        <div className="col-lg-3 col-sm-4">
                          <label
                            htmlFor="conv_factor"
                            class="lblrequired lbltheme"
                          >
                            Conversion Factor*:
                          </label>
                          <div class="input-group mb-3">
                            <span class="input-group-text">
                              {formik.values.packs_per_sold_by == 1
                                ? "(" +
                                  formik.values.pack_unit_size +
                                  " " +
                                  formik.values.pack_unit_measure +
                                  ")"
                                : formik.values.packs_per_sold_by +
                                  "*" +
                                  " (" +
                                  formik.values.pack_unit_size +
                                  " " +
                                  formik.values.pack_unit_measure +
                                  ")"}
                            </span>
                            <input
                              type="text"
                              className="form-control"
                              id="conv_factor"
                              name="conv_factor"
                              value={formik.values.conv_factor}
                              onChange={formik.handleChange}
                              onBlur={handleBlur}
                            />
                            <span class="input-group-text">
                              {dfltUnits.current}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="row mt-4">
                      <span className="lblrequired lbltheme fw-bold fst-italic">
                        {formik.values.pack_unit_measure !== "" ? (
                          <>
                            {formik.values.packs_per_sold_by == 1
                              ? "(" +
                                formik.values.pack_unit_size +
                                " " +
                                formik.values.pack_unit_measure +
                                ")"
                              : formik.values.packs_per_sold_by +
                                "*" +
                                " (" +
                                formik.values.pack_unit_size +
                                " " +
                                formik.values.pack_unit_measure +
                                ")" +
                                " = $" +
                                formik.values.price_per_sold_by +
                                " " +
                                formik.values.sold_by +
                                "( $" +
                                getPricePerItem() + //isPricePerItem
                                " per " +
                                dfltUnits.current +
                                ")"}
                          </>
                        ) : (
                          <>Example: case = 4 * (5 lb packs)</>
                        )}
                      </span>

                      <input
                        type="hidden"
                        className="form-control"
                        id="price_per_item"
                        name="price_per_item"
                        value={formik.values.price_per_item}
                      />

                      <input
                        type="hidden"
                        className="form-control"
                        id="price_opt"
                        name="price_opt"
                        value={formik.values.price_opt}
                      />

                      <input
                        type="hidden"
                        className="form-control"
                        id="ingredient_id"
                        name="ingredient_id"
                        value={formik.values.ingredient_id}
                      />
                    </div>

                    <div class="form-check form-switch">
                      <input
                        className="form-check-input ckbtheme"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckChecked"
                        checked={useLastPrice}
                        onChange={setUseLastPriceHandler}
                      />
                      <label
                        className="form-check-label"
                        for="flexSwitchCheckChecked"
                      >
                        Use for Ingredient's Last Paid Price per Default Unit
                      </label>
                    </div>
                  </div>
                </div>
                <div className={style["modal-footer"]}>
                  <div className="row mt-2">
                    <div id="qadd-ingred-errmsg" className="error-msg">
                      {formik.errors.supplier_id
                        ? formik.errors.supplier_id
                        : formik.errors.supplier_item_desc
                        ? formik.errors.supplier_item_desc
                        : formik.errors.pack_unit_measure
                        ? formik.errors.pack_unit_measure
                        : null}
                    </div>
                  </div>

                  <div className="col d-flex align-self-end justify-content-end">
                    <button
                      type="submit"
                      className="btn btn-link btn-just-icon"
                      id="SaveIngred"
                      name="SaveIngred"
                      title="Save"
                    >
                      <IoIosSave className="save-icon" />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const AddSupplierIngredient = (props) => {
  return ReactDOM.createPortal(
    <AddSupplierIngredientModal
      ingredient_id={props.ingredient_id}
      saveClick={props.saveClick}
      onClick={props.onClick}
    />,
    document.getElementById("modal")
  );
};
export default AddSupplierIngredient;
