const FormSelect = ({
  label,
  onBlur,
  onChange,
  value,
  name,
  title,
  optionListing,
  ...input
}) => {
  return (
    <fieldset>
      <label htmlFor={name} className="lblrequired lbltheme">
        {label}
      </label>
      <select
        className="form-select"
        name={name}
        id={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        {...input}
      >
        <option selected>{title}</option>
        {optionListing}
      </select>
    </fieldset>
  );
};

export default FormSelect;
