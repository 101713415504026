import MainPanel from "../../components/MainPanel";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormInput from "../../components/form/FormInput";
import ListCardHeader from "../../templates/listpagetemplate/ListCardHeader";
import { MdOutlineCalculate } from "react-icons/md";
import Table from "../../components/Table";
import { useRef, useState, useContext } from "react";
import useHttp from "../../hooks/useHttp";
import { FaCartPlus } from "react-icons/fa6";
import { ToastContainer, toast } from "react-toastify";
import DiscardOnHandPrompt from "./DiscardOnHandPrompt";
import AuthContext from "../../store/LoginContext";

const GenerateInventoryReOrder = () => {
  const [_, handleHttpRequest] = useHttp();
  const [ingredientsReOrder, setIngredientsReOrder] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [discandOnHandPrompt, setDiscandOnHandPrompt] = useState();
  const [filterValue, setFilterValue] = useState({
    fromdate: "",
    todate: "",
    salesforecast: "",
    discardOnHandQty: null,
  });
  const message = useRef();
  const { updateNotification } = useContext(AuthContext);

  const generateInvReorder = async (values) => {
    const response = await handleHttpRequest({
      page: "inventory-reorder",
      method: "POST",
      data: values,
    });
    if (!response.status) {
      setIngredientsReOrder(response);
    } else if (response.status === 403) {
      setDiscandOnHandPrompt(true);
      message.current = response.data;
    } else {
      toast.error(response.data);
    }
  };

  const handleCanclePrompt = () => {
    setDiscandOnHandPrompt(false);
    // message.current = null;
  };

  const handleConfirmPrompt = () => {
    setDiscandOnHandPrompt(false);
    setFilterValue((prev) => ({ ...prev, discardOnHandQty: true }));
    generateInvReorder({ ...filterValue, discardOnHandQty: true });
  };

  const loadCart = async () => {
    const response = await handleHttpRequest({
      page: "cart",
      method: "POST",
      data: { items: ingredientsReOrder },
    });

    if (!response.status) {
      //update notification state
      const notificationResponse = await handleHttpRequest({
        page: "cart-qty",
        method: "GET",
      });
      !notificationResponse.status && updateNotification(notificationResponse);
      toast.info(response);
    } else {
      toast.error(response);
    }
  };

  const formik = useFormik({
    initialValues: filterValue,
    validationSchema: Yup.object({
      fromdate: Yup.date().required("Enter the starting sales date"),
      todate: Yup.date().required("Enter the ending sales date"),
      salesforecast: Yup.number().required("Enter sales forecast"),
    }),
    onSubmit: (values) => {
      setFilterValue(values);
      generateInvReorder(values);
    },
  });

  const COLUMNS = [
    {
      Header: "Ingredient Name",
      accessor: "name",
    },
    {
      Header: "Qty Required",
      accessor: "qtyRequired", //+uom
      Cell: ({ cell }) =>
        cell ? cell.value + " " + cell.row.original.unit_measure : "",
    },
    {
      Header: "Qty on hand",
      accessor: "qtyOnHand",
    },
    {
      Header: "Par Qty",
      accessor: "par",
    },
    {
      Header: "Purchase Order",
      accessor: "quantity_to_order",
    },
    {
      Header: "Supplier Type",
      accessor: "supplierType",
      Cell: ({ value }) =>
        value === "No supplier found" ? (
          <div className="error-msg">{value}</div>
        ) : (
          value
        ),
    },
    {
      Header: "Supplier Name",
      accessor: "supplierName",
    },
    {
      Header: "Supplier Ingredient Desc",
      accessor: "supplierIngDescription",
    },
    {
      Header: "Order Qty in Supplier UOM",
      accessor: "qty_persellby", //+cell.row.original.supplierSellBy
      Cell: ({ cell }) =>
        cell ? cell.value + " " + cell.row.original.supplierSellBy : "",
    },
    {
      Header: "Unit Price",
      accessor: "pricePerSellBy",
      Cell: ({ value }) => (value ? "$" + value : ""),
    },
    {
      Header: "Extended Price",
      accessor: "extendedPrice",
      Cell: ({ value }) => (value ? "$" + value : ""),
    },
    {
      Header: "Note",
      accessor: "flag",
    },
  ];

  // const MenuItemCOLUMNS = [
  //   {
  //     Header: "Ingredient Name",
  //     accessor: "name",
  //   },
  //   {
  //     Header: "Qty Required",
  //     accessor: "qtyRequired", //+uom
  //     Cell: ({ cell }) =>
  //       cell ? cell.value + " " + cell.row.original.uom : "",
  //   },
  //   {
  //     Header: "Qty on hand",
  //     accessor: "qtyOnHand",
  //   },
  //   {
  //     Header: "Par Qty",
  //     accessor: "par",
  //   },
  //   {
  //     Header: "Purchase Order",
  //     accessor: "purchaseOrder",
  //   },
  //   {
  //     Header: "Supplier Type",
  //     accessor: "supplierType",
  //     Cell: ({ value }) =>
  //       value === "No supplier found" ? (
  //         value
  //       ) : (
  //         <div className="error-msg">{value}</div>
  //       ),
  //   },
  //   {
  //     Header: "Supplier Name",
  //     accessor: "supplierName",
  //   },
  //   {
  //     Header: "Supplier Ingredient Description",
  //     accessor: "supplierIngDescription",
  //   },
  //   {
  //     Header: "Qty to order in sell by unit",
  //     accessor: "qtyToOrderInSaleByUnit", //+cell.row.original.supplierSellBy
  //     Cell: ({ cell }) =>
  //       cell ? cell.value + " " + cell.row.original.supplierSellBy : "",
  //   },
  //   {
  //     Header: "Supplier Price per Sell By",
  //     accessor: "pricePerSellBy",
  //     Cell: ({ value }) => (value ? "$" + value : ""),
  //   },
  //   {
  //     Header: "Extended Price",
  //     accessor: "extendedPrice",
  //     Cell: ({ value }) => (value ? "$" + value : ""),
  //   },
  // ];

  return (
    <>
      {discandOnHandPrompt && (
        <DiscardOnHandPrompt
          message={message.current}
          onCancle={handleCanclePrompt}
          onConfirm={handleConfirmPrompt}
        />
      )}
      <MainPanel>
        <div className="card">
          <ListCardHeader title="Generate Inventory Reorder"></ListCardHeader>
          <div className="card-body">
            <form className="" onSubmit={formik.handleSubmit}>
              <div className="row mt-3">
                <div className="col-sm-3">
                  <FormInput
                    label="Starting Sales Date*"
                    type="date"
                    name="fromdate"
                    value={formik.values.fromdate}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                <div className="col-sm-3">
                  <FormInput
                    label="Starting Sales Date*"
                    type="date"
                    name="todate"
                    value={formik.values.todate}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                <div className="col-sm-3">
                  <label
                    className="lblrequired lbltheme"
                    htmlFor="salesforecast"
                  >
                    Sales Forecast
                  </label>
                  <div className="input-group">
                    <span class="input-group-text">$</span>
                    <input
                      type="text"
                      class="form-control"
                      aria-label="Amount (to the nearest dollar)"
                      name="salesforecast"
                      value={formik.values.salesforecast}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {/* <span class="input-group-text">.00</span> */}
                  </div>
                </div>
                <div className="col-sm-1 d-flex align-items-end">
                  <button
                    type="submit"
                    className="btn btn-link btn-just-icon"
                    name="calculate"
                    title="Calculate"
                  >
                    <MdOutlineCalculate className="themecolor fs-2" />
                  </button>
                </div>
                <div className="col-sm-2 d-flex align-items-end">
                  {ingredientsReOrder.length !== 0 && (
                    <button
                      type="button"
                      className="btn btntheme"
                      name="loadcart"
                      title="Load Cart"
                      onClick={loadCart}
                    >
                      <FaCartPlus className="fs-4" />
                      Load Cart
                    </button>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-sm-3 error-msg mt-2">
                  {formik.errors.fromdate
                    ? formik.errors.fromdate
                    : formik.errors.todate
                    ? formik.errors.todate
                    : formik.errors.salesforecast
                    ? formik.errors.salesforecast
                    : null}
                </div>
              </div>
            </form>
            <hr></hr>
            {/* <div className="row">
              <div className="col-sm-8">
                <Table columns={MenuItemCOLUMNS} data={menuItems} />
              </div>
              <div className="col-sm-8">
                <h2>Projected Total Sale</h2>
              </div>
            </div> */}
            <Table columns={COLUMNS} data={ingredientsReOrder} />
          </div>
        </div>
      </MainPanel>
      <ToastContainer position="bottom-right" hideProgressBar />
    </>
  );
};

export default GenerateInventoryReOrder;
