let UNIT_OF_MEASUREMENT = [
  {
    id: "1",
    unit: "oz",
    name: "Ounce(oz)",
  },
  {
    id: "2",
    unit: "tsp",
    name: "Teaspoon(tsp)",
  },
  {
    id: "3",
    unit: "Tbsp",
    name: "Tablespoon(Tbsp)",
  },
  {
    id: "4",
    unit: "C",
    name: "Cup(C)",
  },
  {
    id: "5",
    unit: "fl oz",
    name: "Fluid Oz(fl oz)",
  },
  {
    id: "6",
    unit: "L",
    name: "Liter(L)",
  },
  {
    id: "7",
    unit: "mL",
    name: "Milliliter(mL)",
  },
  {
    id: "8",
    unit: "pt",
    name: "Pint(pt)",
  },
  {
    id: "9",
    unit: "qt",
    name: "Quart(qt)",
  },
  {
    id: "10",
    unit: "gal",
    name: "Gallon(gal)",
  },
  {
    id: "11",
    unit: "g",
    name: "Gram(g)",
  },
  {
    id: "12",
    unit: "Kg",
    name: "Kilogram(Kg)",
  },
  {
    id: "13",
    unit: "lb",
    name: "Pound(lb)",
  },
  {
    id: "14",
    unit: "ea",
    name: "Each(ea)",
  },
  {
    id: "15",
    unit: "pkg",
    name: "Package(pkg)",
  },
  {
    id: "16",
    unit: "box",
    name: "Box(box)",
  },
  {
    id: "15",
    unit: "EA",
    name: "EA",
  },
];

export default UNIT_OF_MEASUREMENT;
