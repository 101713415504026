const FormInput = ({
  label,
  onBlur,
  onChange,
  value,
  name,
  type,
  step,
  ...input
}) => {
  return (
    <fieldset>
      <label className="lblrequired lbltheme" htmlFor={name}>
        {label}
      </label>
      <input
        {...input}
        className="form-control"
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        name={name}
        id={name}
        type={type ?? "text"}
        step={step ?? 1}
      />
    </fieldset>
  );
};

export default FormInput;
