import React, { useState, useEffect } from "react";
import useHttp from "../../hooks/useHttp";

const SuppliersForIngredients = ({
  ingId,
  supplierIngreident,
  value,
  onChange,
  onBlur,
}) => {
  const [suppliers, setSuppliers] = useState();
  const [supplieringredients, setSuppliersIngredients] = useState();
  const [hasError, handleHttpRequest] = useHttp();

  const getSuppliers = async () => {
    // const response = await handleHttpRequest('suppliers')
    const response = await handleHttpRequest({
      page: "supplieringredient/suppliers/" + ingId,
      method: "GET",
    });
    console.log("Get suppliers: ", response);
    if (!response.status) {
      setSuppliers(response.suppliers);
      setSuppliersIngredients(response.supplierIng);
    } else {
      // toast.error(response.data);
    }
  };

  const handleOnChange = (e) => {
    let supplieringredient = supplieringredients.filter(
      (suppIng) => suppIng.supplier_id == e.target.value
    )[0];
    console.log("Supp Ings ", supplieringredients);
    console.log("Supp Ing ", supplieringredient);
    console.log("Target val ", e.target.value);
    supplierIngreident(supplieringredient);
    onChange(e);
  };

  useEffect(() => {
    getSuppliers();
  }, []);

  return (
    <select
      class="form-select"
      name="supplier_id"
      id="supplier_id"
      value={value}
      onChange={handleOnChange}
      onBlur={onBlur}
    >
      <option selected>Select Supplier</option>
      {suppliers?.map((supplier) => (
        <option key={supplier.id} value={supplier.id}>
          {supplier.name}
        </option>
      ))}
    </select>
  );
};
export default SuppliersForIngredients;
