import { Navigate, Outlet } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../store/LoginContext";

const PrivateRoutes = () => {
  const { authToken } = useContext(AuthContext);
  return authToken.authentication_token ? <Outlet /> : <Navigate to="/login" />;
};

export const PublicRoutes = () => {
  const { authToken } = useContext(AuthContext);
  return authToken.authentication_token ? <Navigate to="/" /> : <Outlet />;
};

export const PermissionForRoute = ({ permission }) => {
  const { roleAndPermissions } = useContext(AuthContext);

  if (roleAndPermissions.permissions != undefined) {
    return roleAndPermissions.permissions.includes(permission) ? (
      <Outlet />
    ) : (
      <Navigate to="/" />
    );
  }
};

export default PrivateRoutes;
