import MainPanel from "../../components/MainPanel";
import Table from "../../components/Table";
import { Link } from "react-router-dom";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaRegEdit, FaPlus, FaList } from "react-icons/fa";
import { FaListCheck } from "react-icons/fa6";
import { useEffect, useRef, useState } from "react";
import Notification from "../../components/Notification";
import { Toast } from "bootstrap";
import useHttp from "../../hooks/useHttp";
import AddRole from "./AddRoles";
import EditRole from "./EditRoles";
import DeleteModal from "../DeleteModal";
import RolesButtonGroup from "../../components/groupButtons/RolesButtonGroup";
import SetRoleDefault from "./SetRoleDefault";

const Roles = () => {
  const [_, handleHttpRequest] = useHttp();
  const [roles, setRoles] = useState([]);

  const notificationStatus = useRef();
  const notificationMessage = useRef();
  const toastRef = useRef();
  var [toast, setToast] = useState(false);
  const [toastUpdated, setToastUpdated] = useState(false);
  const [beUpdated, setBEUpdated] = useState(false);
  useEffect(() => {
    var myToast = toastRef.current;
    var bsToast = Toast.getInstance(myToast);
    if (!bsToast) {
      // initialize Toast
      bsToast = new Toast(myToast, { delay: 4000, autohide: true });
      // hide after init
      bsToast.hide();
      setToast(false);
    } else {
      // toggle
      toast ? bsToast.show() : bsToast.hide();
      // setToast(state => !state);
    }
  }, [toastUpdated, setToastUpdated]);

  const getRoles = async () => {
    const response = await handleHttpRequest({ page: "roles", method: "GET" });
    if (!response.status) {
      setRoles(response);
    }
  };
  useEffect(() => {
    getRoles();
  }, [beUpdated]);

  /* Add Role */
  const [isAddRoleModalOpen, setIsAddRoleModalOpen] = useState(false);
  const addRole = (response) => {
    setIsAddRoleModalOpen(false);
    notificationMessage.current = response;
    notificationStatus.current = 200;
    setToast(true);
    setToastUpdated((state) => !state);
    setBEUpdated((state) => !state);
  };
  const openAddRole = () => {
    setIsAddRoleModalOpen(true);
  };
  const closeAddRole = () => {
    setIsAddRoleModalOpen(false);
  };

  /* Edit Roles */
  const [isEditRoleModalOpen, setIsEditRoleModalOpen] = useState(false);
  const role_id = useRef();
  const editRole = (id) => {
    role_id.current = id;
    setIsEditRoleModalOpen(true);
  };
  const saveEditRole = (response) => {
    setIsEditRoleModalOpen(false);
    notificationMessage.current = response;
    notificationStatus.current = 200;
    setToast(true);
    setToastUpdated((state) => !state);
    setBEUpdated((state) => !state);
  };
  const closeEditRole = () => {
    setIsEditRoleModalOpen(false);
  };

  /**Delete Role */
  /* Delete Modal */
  const deleteIdIs = useRef(0);
  const [isdeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const openDeleteModal = (id) => {
    deleteIdIs.current = id;
    setIsDeleteModalOpen((state) => !state);
  };
  const deleteHandler = (response) => {
    // console.log(response);
    setIsDeleteModalOpen(false);
    notificationMessage.current = response[0];
    notificationStatus.current = response[0];
    setToast(true);
    setToastUpdated((state) => !state);
    setBEUpdated((state) => !state);
  };

  //isRoleDefaultModalOpen
  const [isRoleDefaultModalOpen, setIsRoleDefaultModalOpen] = useState(false);
  const openSetDefaultRoleModal = (id) => {
    role_id.current = id;
    setIsRoleDefaultModalOpen((state) => !state);
  };
  const setDefaultRoleHandler = (message, status) => {
    setIsRoleDefaultModalOpen(false);
    setBEUpdated((state) => !state);
    // toast.info(message);
  };

  const COLUMNS = [
    {
      Header: "Role",
      accessor: "name",
    },
    {
      Header: "Actions",
      accessor: "id",
      disableSortBy: true,
      Cell: (cell) => (
        <div>
          <RolesButtonGroup
            cell={cell}
            editRole={editRole}
            openSetDefaultRoleModal={openSetDefaultRoleModal}
            openDeleteModal={openDeleteModal}
          />
          {/* <button
            className="btn btn-link btn-just-icon themecolor delcount"
            title="Add Permissions"
          >
            <Link className="themecolor" to={`/role-permissions/${cell.value}`}>
              <FaListCheck />
            </Link>
          </button>
          <button
            className="btn btn-link btn-just-icon themecolor delcount"
            title="Edit Role"
            onClick={() => editRole(cell.value)}
          >
            <FaRegEdit />
          </button>
          {cell.row.original.name != "admin" && (
            <button
              className="btn btn-link btn-just-icon delcount"
              style={{ color: "#676767" }}
              title="Delete Role"
              onClick={() => {
                openDeleteModal(cell.value);
              }}
            >
              <RiDeleteBin6Line />
            </button>
          )} */}
        </div>
      ),
    },
  ];
  return (
    <>
      <MainPanel>
        {isAddRoleModalOpen && (
          <AddRole saveClick={addRole} onClick={closeAddRole} />
        )}
        {isEditRoleModalOpen && (
          <EditRole
            id={role_id.current}
            saveClick={saveEditRole}
            onClick={closeEditRole}
          />
        )}
        {isdeleteModalOpen && (
          <DeleteModal
            deleteId={deleteIdIs.current}
            onClick={openDeleteModal}
            onDelete={deleteHandler}
            item="role"
            name="role"
          />
        )}
        {isRoleDefaultModalOpen && (
          <SetRoleDefault
            roleId={role_id.current}
            onClick={openSetDefaultRoleModal}
            onUpdate={setDefaultRoleHandler}
          />
        )}
        <div className="card">
          <div className="card-header text-bg-secondary">
            <div className="row">
              <h4 className="card-title">
                Roles
                <button
                  type="button"
                  className="btn btn-outline-light ms-3"
                  name="AddRecipe"
                  title="New Recipe"
                  style={{ padding: "4px 8px 7px" }}
                  onClick={openAddRole}
                >
                  <FaPlus />
                </button>
              </h4>
            </div>
          </div>
          <div className="card-body">
            <div className="toolbar"></div>
            <Table data={roles} columns={COLUMNS} />
          </div>
        </div>
        <Notification
          toastRef={toastRef}
          toast={toast}
          setToast={setToast}
          notificationStatus={notificationStatus.current}
        >
          {notificationMessage.current}
        </Notification>
      </MainPanel>
    </>
  );
};

export default Roles;
