import React, {useEffect, useRef, useState} from "react";
import { Toast } from "bootstrap";

const Notification =(props)=>{

//     useEffect(() => {
//         var myToast = props.toastRef.current
//         var bsToast = Toast.getInstance(myToast)
// console.log(bsToast);
//         if (!bsToast) {
//             // initialize Toast
//             bsToast = new Toast(myToast,{delay: 4000, autohide: true})
//             // hide after init
//             bsToast.hide()
//             props.setToast(false)
//             console.log("Hide after 4000",props.toast);
//         }
//         else {
//             // toggle
//             props.toast ? bsToast.show() : bsToast.hide()
//             console.log("Toggle toast",props.toast);
//         }
//     },[props.toast, props.setToast])

    return(
        <div className="toast position-fixed m-4 bottom-0 end-0" role="alert" ref={props.toastRef} style={{zIndex:"10"}}>
            <div className="toast-header" style={{backgroundColor:"#0d6efd",color:"white"}}>
                <strong>Notification</strong>
            </div>
            <div className="toast-body" style={{backgroundColor:"#fff"}}>
              {props.children}
            </div>
        </div>
    );
}

export default Notification;
