import React, { useState } from "react";
import ReactDOM from "react-dom";
import useHttp from "../../hooks/useHttp";
import style from "../../assets/style/modal.module.css";

export const DeleteIngredientModal = ({ deleteId, onCancle, onDelete }) => {
  const closeModal = () => {
    // deleteSupplier()
    onCancle();
  };

  const [hasError, handleHttpRequest] = useHttp();
  const deleteSupplier = async (id) => {
    // const response = await handleHttpRequest('ingredient/'+id, 'DELETE');
    const response = await handleHttpRequest({
      page: "ingredient/" + deleteId,
      method: "DELETE",
    });
    if (!hasError) {
      // console.log("Response has error ",response);
      // saveIngredient(response);
      onDelete([response.message, response.status]);
    } else {
      //onDelete([err.response.data, err.response.status]);
    }
  };

  return (
    <>
      <div className={style["modal-overlay"]}></div>
      <div className={style["modal-card-wrapper"]}>
        <div
          id={style["deleteModal"]}
          className={`card  ${style["modal-card"]}`}
          role="dialog"
        >
          <div className="modal-dialog w-100">
            <div className="modal-content">
              <div className={` ${style["modal-header"]}`}>
                <h5 className="modal-title" id="staticBackdropLabel">
                  Delete Ingredient
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={closeModal}
                  aria-label="Close"
                ></button>
              </div>
              <div className={style["modal-content"]}>
                Delete this ingredient?
              </div>
              <div className={style["modal-footer"]}>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => deleteSupplier(deleteId)}
                >
                  Confirm
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={closeModal}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const DeleteIngredient = (props) => {
  return ReactDOM.createPortal(
    <DeleteIngredientModal
      deleteId={props.deleteId}
      onCancle={props.onClick}
      onDelete={props.onDelete}
    />,
    document.getElementById("modal")
  );
};
export default DeleteIngredient;
