import { useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import { FaCartShopping } from "react-icons/fa6";
import { RiDeleteBin6Line } from "react-icons/ri";

const ReceiveOrderButtonGroup = ({
  cell,
  editItem,
  sendToCart,
  voidRemaining,
}) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const ontoggle = () => {
    setShowDropDown((state) => !state);
  };

  return (
    <div class="btn-group">
      <button
        type="button"
        className="btn btntheme"
        onClick={() => {
          editItem(cell.value);
        }}
      >
        {" "}
        <FaRegEdit /> Edit
      </button>
      <button
        type="button"
        className={`btn btntheme dropdown-toggle  ${
          showDropDown ? "show" : ""
        }`}
        data-bs-toggle="dropdown"
        aria-expanded={`${showDropDown ? "true" : "false"}`}
        onClick={ontoggle}
      >
        <span className="visually-hidden">Toggle Dropdown</span>
      </button>
      <ul className={`dropdown-menu ${showDropDown ? "show" : ""}`}>
        <li>
          <button
            className="dropdown-item btn btn-link iconthemerecv"
            title="Send to Cart"
            onClick={() => {
              sendToCart(cell.value);
            }}
          >
            <FaCartShopping style={{ fontSize: "16px" }} /> Send to Cart
          </button>
        </li>
        <li>
          <button
            className="dropdown-item btn btn-link iconthemerecv"
            title="Void Remaining"
            onClick={() => {
              voidRemaining(cell.value);
            }}
          >
            <RiDeleteBin6Line style={{ fontSize: "16px" }} /> Void
          </button>
        </li>
      </ul>
    </div>
  );
};
export default ReceiveOrderButtonGroup;
