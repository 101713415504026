import { useState } from "react";

const useConversion =()=>{

    //Global conversion values of Ounce(oz)
    const vTsp = 6;
    const vTbsp = 2;
    const vmL = 29.57353193;
    const vc = 8;
    const vpt = 0.0625;
    const vqt = 0.03125;
    const vL = 0.0295735;
    const vgal = 0.0078125;
    const vg = 28.3495;
    const vlb = 16;
    const vFloz = 0.958611418535;
    const vKg = 0.0283495;


    const [hasError, setHasError] = useState(false);

    const IsStandardUnit = (vUnit) => {
        if (vUnit == 'oz') {return 'Y';}
        if (vUnit == 'fl oz') {return 'Y';}
        if (vUnit == 'tsp') {return 'Y';}
        if (vUnit == 'Tbsp') {return 'Y';}
        if (vUnit == 'mL') {return 'Y';}
        if (vUnit == 'C') {return 'Y';}
        if (vUnit == 'pt') {return 'Y';}
        if (vUnit == 'qt') {return 'Y';}
        if (vUnit == 'L') {return 'Y';}
        if (vUnit == 'gal') {return 'Y';}
        if (vUnit == 'g') {return 'Y';}
        if (vUnit == 'Kg') {return 'Y';}
        if (vUnit == 'lb') {return 'Y';}
        return '';
    }

    const convertWeight = (unitsMeas, PackUnitSize, PacksPerSoldby, dfltUnits) => {
        //If unit is standard unit convert to Ounce
        let tempMeasureInOz = 0;
        let dfltUnitQty = 0;
        if (unitsMeas == 'oz' || unitsMeas == 'fl oz') {tempMeasureInOz = (PackUnitSize * PacksPerSoldby);}
        if (unitsMeas == 'tsp') {tempMeasureInOz = (PackUnitSize * PacksPerSoldby) / vTsp;}
        if (unitsMeas == 'Tbsp') {tempMeasureInOz = (PackUnitSize * PacksPerSoldby) / vTbsp;}
        if (unitsMeas == 'mL') {tempMeasureInOz = (PackUnitSize * PacksPerSoldby) / vmL;}
        if (unitsMeas == 'C') {tempMeasureInOz = (PackUnitSize * PacksPerSoldby) * vc;}
        if (unitsMeas == 'pt') {tempMeasureInOz = (PackUnitSize * PacksPerSoldby) / vpt;}
        if (unitsMeas == 'qt') {tempMeasureInOz = (PackUnitSize * PacksPerSoldby) / vqt;}
        if (unitsMeas == 'L') {tempMeasureInOz = (PackUnitSize * PacksPerSoldby) / vL;}
        if (unitsMeas == 'gal') {tempMeasureInOz = (PackUnitSize * PacksPerSoldby) / vgal;}
        if (unitsMeas == 'g') {tempMeasureInOz = (PackUnitSize * PacksPerSoldby) / vg;}
        if (unitsMeas == 'lb') {tempMeasureInOz = (PackUnitSize * PacksPerSoldby) * vlb;}
        if (unitsMeas == 'Kg') {tempMeasureInOz = (PackUnitSize * PacksPerSoldby) / vKg;}

        //Convert to default unit measure
        if (dfltUnits == 'oz' || dfltUnits == 'fl oz') {dfltUnitQty = tempMeasureInOz;}
        if (dfltUnits == 'tsp') {dfltUnitQty = tempMeasureInOz * vTsp;}
        if (dfltUnits == 'Tbsp') {dfltUnitQty = tempMeasureInOz * vTbsp;}
        if (dfltUnits == 'mL') {dfltUnitQty = tempMeasureInOz * vmL;}
        if (dfltUnits == 'C') {dfltUnitQty = tempMeasureInOz / vc;}
        if (dfltUnits == 'pt') {dfltUnitQty = tempMeasureInOz * vpt;}
        if (dfltUnits == 'qt') {dfltUnitQty = tempMeasureInOz * vqt;}
        if (dfltUnits == 'L') {dfltUnitQty = tempMeasureInOz * vL;}
        if (dfltUnits == 'gal') {dfltUnitQty = tempMeasureInOz * vgal;}
        if (dfltUnits == 'g') {dfltUnitQty = tempMeasureInOz * vg;}
        if (dfltUnits == 'lb') {dfltUnitQty = tempMeasureInOz / vlb;}
        if (dfltUnits == 'Kg') {dfltUnitQty = tempMeasureInOz * vKg;}
        
        return dfltUnitQty;
        
    }
    return [IsStandardUnit, convertWeight];
}

export default useConversion;