import React, { useRef, useEffect, useState } from "react";
import { useFormik, ErrorMessage } from "formik";
import * as Yup from "yup";
import useHttp from "../../hooks/useHttp";
import units from "../../utils/units";
import Category from "../categories/Category";
import AddCategory from "../categories/AddCategory";
import SupplierIngredients from "../supplierIngredients/SupplierIngredients";
import AddSupplierIngredient from "../supplierIngredients/AddSupplierIngredient";
import EditSupplierIngredient from "../supplierIngredients/EditSupplierIngredient";
import { FaList } from "react-icons/fa";
import { IoIosSave } from "react-icons/io";
import { useParams } from "react-router-dom";
import AddStorageLocation from "../inventory/AddStorageLocation";
import StorageLocations from "../inventory/StorageLocations";
import { ToastContainer, toast } from "react-toastify";

const EditIngredientNewPage = () => {
  const { id: ing_id } = useParams();
  const [allValues, setAllValues] = useState();
  const [initialValues, setInitialValues] = useState({
    name: "",
    default_unit_measure: "",
    default_unit_price: "",
    category_id: "",
    par_quantity: "",
  });
  const [hasError, handleHttpRequest] = useHttp();
  const [inventoryCount, setInventoryCount] = useState();
  const [qtyOrdered, setQtyOrdered] = useState();

  /** Add storage location for ingredients */
  const [ingLocations, setIngLocations] = useState([]);
  const setStorageLocation = (value) => {
    setIngLocations((prev) => [...prev, value]);
  };
  const removeStorageLocation = (id) => {
    const updatedItem = ingLocations.filter((inv) => inv.id !== id);
    setIngLocations(updatedItem);
  };
  // get ingredients //
  const getIngredient = async () => {
    // const response = await handleHttpRequest("ingredient/" + id);
    const response = await handleHttpRequest({
      page: "ingredient/" + ing_id,
      method: "GET",
    });
    if (!hasError) {
      // saveIngredient(response);
      setAllValues(response.message);
      setInitialValues({
        name: response.message.name,
        default_unit_measure: response.message.default_unit_measure,
        default_unit_price: response.message.default_unit_price,
        category_id: response.message.category_id,
        par_quantity: response.message.par_quantity,
      });
      setIngLocations(response.storage_location);
    }
  };
  const getIngredientInventoryCount = async () => {
    const response = await handleHttpRequest({
      page: "ingredient-inventorycount/" + ing_id,
      method: "GET",
    });
    if (!response.status) {
      setInventoryCount(response);
    }
  };
  const getQtyOrdered = async () => {
    const response = await handleHttpRequest({
      page: "ingredient-purchaseorder/" + ing_id,
      method: "GET",
    });
    if (!response.status) {
      setQtyOrdered(response);
    }
  };
  useEffect(() => {
    getIngredient();
    getIngredientInventoryCount();
    getQtyOrdered();
  }, []);

  const editIngredient = async (values) => {
    const response = await handleHttpRequest({
      page: "ingredient/edit/" + ing_id,
      method: "PUT",
      data: values,
    });
    response.status != 200
      ? toast.error("An error has occured while saving")
      : toast.info(response.message);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      name: Yup.string()
        .required(" Ingredient Name is required.")
        .max(100, "Ingredient name is too long"),
      default_unit_measure: Yup.string().required(
        "Select a Default Unit of Measurement for the Ingredient."
      ),
      default_unit_price: Yup.number(
        "Default unit price must be a number"
      ).required("Last price is required."),
      category_id: Yup.number().required(
        "Select a category for the Ingredient."
      ),
      par_quantity: Yup.number(),
    }),
    onSubmit: (values) => {
      editIngredient({
        name: values.name,
        default_unit_measure: values.default_unit_measure,
        default_unit_price: values.default_unit_price,
        category_id: values.category_id,
        par_quantity: values.par_quantity,
        storage_locations: ingLocations,
      });
    },
  });

  /* Add Storage Modal */
  const [isAddStorageModalOpen, setIsAddStorageModalOpen] = useState(false);
  const [storageSaved, setStorageSaved] = useState(false);
  const addStorage = () => {
    setIsAddStorageModalOpen(true);
  };
  const closeAddStorage = () => {
    setIsAddStorageModalOpen(false);
  };
  const addStorageHandler = (message = null) => {
    setIsAddStorageModalOpen(false);
    if (message) {
      setStorageSaved((state) => !state);
      toast.info(message);
    }
  };

  /* Add Category Modal */
  const [isAddCategoryModalOpen, setIsAddCategoryModalOpen] = useState(false);
  const [categorySaved, setCategorySaved] = useState(false);
  const addCategory = () => {
    setIsAddCategoryModalOpen(true);
  };
  const closeAddCategory = () => {
    setIsAddCategoryModalOpen(false);
  };
  const addCategoryHandler = (message, status) => {
    setIsAddCategoryModalOpen(false);
    if (status == 200) {
      toast.info(message);
      setCategorySaved((state) => !state);
    } else {
      toast.error(message);
    }
  };

  /* Add Supplier Ingredient for an Ingredient */
  const [isAddSupplierIngModalOpen, setIsAddSupplierIngModalOpen] =
    useState(false);
  const ingId = useRef(0);
  const openAddSupplierIngredient = (id) => {
    ingId.current = id;
    setIsAddSupplierIngModalOpen(true);
  };
  const closeAddSupplierIngredient = () => {
    setIsAddSupplierIngModalOpen(false);
  };
  const [supplierIngSaved, setSupplierIngSaved] = useState(false);
  const saveSuppIng = (message, status) => {
    setIsAddSupplierIngModalOpen(false);
    // status === 200 ? toast.info(message) : toast.error(message);
    if (status === 200) {
      toast.info(message);
      setSupplierIngSaved((state) => !state);
    } else {
      message?.map((msg) => {
        toast.error(msg);
      });
    }
  };

  /* Edit Supplier Ingredient for an Ingredient */
  const [isEditSupplierIngModalOpen, setIsEditSupplierIngModalOpen] =
    useState(false);
  const supplierIngId = useRef(0);
  const openEditSupplierIngredient = (id) => {
    supplierIngId.current = id;
    setIsEditSupplierIngModalOpen(true);
  };
  const closeEditSupplierIngredient = () => {
    setIsEditSupplierIngModalOpen(false);
  };

  const saveEditSuppIng = (message, status) => {
    setIsEditSupplierIngModalOpen(false);
    // status === 200 ? toast.info(message) : toast.error(message);
    if (status === 200) {
      toast.info(message);
      setSupplierIngSaved((state) => !state);
    } else {
      message?.map((msg) => {
        toast.error(msg);
      });
    }
    // setBEUpdated((state) => !state);
  };

  const [trackInventory, setTrackInventory] = useState(true);
  const setTrackInventoryHandler = () => {
    setTrackInventory((state) => !state);
  };

  return (
    <>
      {isEditSupplierIngModalOpen && (
        <EditSupplierIngredient
          supplierIngId={supplierIngId.current}
          saveClick={saveEditSuppIng}
          onClick={closeEditSupplierIngredient}
        />
      )}
      {isAddSupplierIngModalOpen && (
        <AddSupplierIngredient
          ingredient_id={ingId}
          saveClick={saveSuppIng}
          onClick={closeAddSupplierIngredient}
        />
      )}
      {isAddCategoryModalOpen && (
        <AddCategory
          saveClick={addCategoryHandler}
          onClick={closeAddCategory}
        />
      )}
      {isAddStorageModalOpen && (
        <AddStorageLocation
          saveClick={addStorageHandler}
          onClick={closeAddStorage}
        />
      )}
      <div className="card m-4">
        <div className="card-header text-bg-secondary">
          <div className="row">
            <h4 className="card-title">{initialValues.name}</h4>
          </div>
        </div>
        <div className="card-body">
          <form className="" onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-sm-6">
                <fieldset>
                  <label className="lblrequired lbltheme" htmlFor="name">
                    Ingredient Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </fieldset>
              </div>
              <div className="col-sm-3">
                <fieldset>
                  <label htmlFor="category_id" className="lbltheme">
                    Ingredient Category
                  </label>
                  <Category
                    categorySaved={categorySaved}
                    value={formik.values.category_id}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </fieldset>
              </div>
              <div className="col d-flex align-self-end">
                <button
                  type="button"
                  className="btn btn-link btn-just-icon"
                  id="AddIngredCatPop"
                  name="AddIngredCatPop"
                  onClick={addCategory}
                  title="New Ingredient Category"
                >
                  <FaList className="themecolor" />
                </button>
              </div>
              <div className="col d-flex align-self-end justify-content-end">
                <button
                  type="submit"
                  className="btn btn-link btn-just-icon"
                  id="SaveIngred"
                  name="SaveIngred"
                  title="Save"
                >
                  <IoIosSave className="save-icon" />
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-3">
                <fieldset>
                  <label
                    htmlFor="ingred-dfltunitmeas"
                    className="lblrequired lbltheme"
                  >
                    Default Unit of Measurement*
                  </label>
                  <select
                    className="form-select"
                    name="default_unit_measure"
                    id="default_unit_measure"
                    value={formik.values.default_unit_measure}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option selected>Select Measurement</option>
                    {units.map((unit) => (
                      <option key={unit.id} value={unit.unit}>
                        {unit.name}
                      </option>
                    ))}
                  </select>
                </fieldset>
              </div>
              <div className="col-sm-3">
                <fieldset>
                  <label
                    htmlFor="default_unit_price"
                    class="lblrequired lbltheme"
                  >
                    Last Price per Default Unit*
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="default_unit_price"
                    name="default_unit_price"
                    value={formik.values.default_unit_price}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </fieldset>
              </div>
            </div>
            <div className="row mt-3">
              <div id="qadd-ingred-errmsg">
                {formik.errors.name
                  ? formik.errors.name
                  : formik.errors.default_unit_measure
                  ? formik.errors.default_unit_measure
                  : formik.errors.default_unit_price
                  ? formik.errors.default_unit_price
                  : formik.errors.category_id
                  ? formik.errors.category_id
                  : null}
              </div>
            </div>

            <div class="row form-check form-switch m-2">
              <div className="col-sm-4">
                <input
                  className="form-check-input ckbtheme"
                  type="checkbox"
                  role="switch"
                  id="track_inventory"
                  checked={trackInventory}
                  onChange={setTrackInventoryHandler}
                />
                <label className="form-check-label" for="track_inventory">
                  Track Inventory
                </label>
              </div>
            </div>
            {trackInventory && (
              <div className="row mb-4">
                <div className="col-sm-6">
                  <div className="row">
                    <div className="col-sm-6">
                      <strong>QTY IN STOCK (in Default Units)</strong>
                      <div>{inventoryCount}</div>
                    </div>
                    <div className="col-sm-6">
                      <div>
                        <strong>QTY ON ORDER (in Sellby)</strong>
                      </div>
                      <div>{qtyOrdered}</div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <label
                        className="lblrequired lbltheme"
                        htmlFor="par_quantity"
                      >
                        Par Qty (in Default Units)
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="par_quantity"
                        name="par_quantity"
                        value={formik.values.par_quantity}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="row">
                    <div className="col-sm-10">
                      <label
                        className="lblrequired lbltheme"
                        // htmlFor="storage_location_id"
                      >
                        Storage Locations
                      </label>
                      <StorageLocations
                        setStorage={setStorageLocation}
                        value=""
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        storageSaved={storageSaved}
                      />
                    </div>
                    <div className="col-sm-2 d-flex">
                      <button
                        type="button"
                        className="btn  btn-link btn-just-icon"
                        onClick={addStorage}
                      >
                        <FaList className="themecolor" />
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-10 d-flex flex-wrap">
                      {ingLocations.length > 0 &&
                        ingLocations.map((ingLocation) => {
                          return (
                            <div
                              style={{
                                border: "1px solid #ddd",
                                padding: "8px",
                                margin: "5px",
                              }}
                            >
                              {ingLocation.name}
                              <span
                                className="badge btntheme m-1"
                                onClick={() =>
                                  removeStorageLocation(ingLocation.id)
                                }
                              >
                                X
                              </span>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </form>
          <div></div>
          <div>
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a class="nav-link active" aria-current="page" href="#">
                  <button
                    type="button"
                    className="btn btn-link btn-just-icon"
                    id="AddIngredCatPop"
                    name="AddIngredCatPop"
                    title="New Ingredient Category"
                  >
                    <FaList className="themecolor" />
                  </button>
                  SUPPLIER INGREDIENTS
                </a>
              </li>
              {/* <li class="nav-item">
                                                    <a class="nav-link" href="#">Link</a>
                                                </li> */}
            </ul>
            <div class="tab-content mt-4" id="myTabContent">
              <div
                class="tab-pane fade show active"
                id="supplieringreds-tab-pane"
                role="tabpanel"
                aria-labelledby="supplieringreds-tab"
                tabindex="0"
              >
                <SupplierIngredients
                  ingUnit={initialValues.default_unit_measure}
                  supplierIngSaved={supplierIngSaved}
                  ingId={ing_id}
                  onSupplierIngOpen={() => openAddSupplierIngredient(ing_id)}
                  updateBD={() => {}}
                  onSupplierIngEditOpen={openEditSupplierIngredient}
                  onSuppIngEditSave={saveEditSuppIng}
                />
              </div>
              <div
                class="tab-pane fade"
                id="inventory-tab-pane"
                role="tabpanel"
                aria-labelledby="inventory-tab"
                tabindex="0"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position="bottom-right" hideProgressBar />
    </>
  );
};

export default EditIngredientNewPage;
