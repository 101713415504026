import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useFormik, ErrorMessage } from "formik";
import * as Yup from "yup";
import useHttp from "../../hooks/useHttp";
import units from "../../utils/units";
import SelectIngredients from "../ingredients/SelectIngredients";
import useConversion from "../../hooks/useConversion";
import { IoIosSave } from "react-icons/io";

const AddRecipeItem = ({ recipe_id, saveClick }) => {
  const [hasError, handleHttpRequest] = useHttp();
  const [unitMeasure, setUnitMeasure] = useState("");
  const [IsStandardUnit, convertWeight] = useConversion();

  const addRecipeItem = async (values) => {
    // const response = await handleHttpRequest("recipeitem/add", "POST", values);
    const response = await handleHttpRequest({
      page: "recipeitem/add",
      method: "POST",
      data: values,
    });
    if (!hasError) {
      saveClick(response);
    } else {
      // closeModal()
    }
  };

  const formik = useFormik({
    initialValues: {
      recipe_id: recipe_id,
      ingredient_id: "",
      unit_size: "",
      unit_measure: "",
    },
    validationSchema: Yup.object({
      recipe_id: Yup.number().required("Select the Recipe."),
      ingredient_id: Yup.number().required("Select a the Ingredient."),
      unit_size: Yup.number()
        .typeError("Quantity must be a number")
        .required("Enter the quantity"),
      unit_measure: Yup.string().required(
        "Select a Measurement for the Ingredient."
      ),
    }),
    onSubmit: (values) => {
      addRecipeItem(values);
      formik.resetForm();
    },
  });

  const nonStandUnit = useRef({});
  const updateUnitMeasure = (value) => {
    setUnitMeasure(value);
    if (IsStandardUnit(value) != "Y") {
      nonStandUnit.current = units.filter((unit) => unit.unit == value)[0];
    }
  };

  return (
    <div className="modal-body row g-3">
      <form className="" onSubmit={formik.handleSubmit}>
        <small class="texttheme fw-bold">QUICK ADD - RECIPE INGREDIENT</small>
        <div className="row">
          <div className="col-sm-4">
            <fieldset>
              <label className="lblrequired lbltheme" htmlFor="name">
                Ingredient*
              </label>
              <SelectIngredients
                setIngredient={(value) => console.log("")}
                value={formik.values.ingredient_id}
                setUnitMeasure={updateUnitMeasure}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </fieldset>
          </div>
          <div className="col-sm-3">
            <fieldset>
              <label htmlFor="unit_measure" className="lblrequired lbltheme">
                Measurement*
              </label>
              <select
                className="form-select"
                name="unit_measure"
                id="unit_measure"
                value={formik.values.unit_measure}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option selected>Select Measurement</option>
                {IsStandardUnit(unitMeasure) == "Y" ? (
                  units.map((unit) => (
                    <option key={unit.id} value={unit.unit}>
                      {unit.name}
                    </option>
                  ))
                ) : (
                  <option
                    key={nonStandUnit.current.id}
                    value={nonStandUnit.current.unit}
                  >
                    {nonStandUnit.current.name}
                  </option>
                )}
              </select>
            </fieldset>
          </div>
          <div className="col-sm-3">
            <fieldset>
              <label htmlFor="unit_size" class="lblrequired lbltheme">
                Quantity
              </label>
              <input
                type="text"
                className="form-control"
                id="unit_size"
                name="unit_size"
                value={formik.values.unit_size}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </fieldset>
          </div>
          <div className="col-sm-1 d-flex align-self-end justify-content-end">
            <button
              type="submit"
              className="btn btn-link btn-just-icon"
              id="SaveIngred"
              name="SaveIngred"
              title="Save"
            >
              <IoIosSave className="save-icon" />
            </button>
          </div>
        </div>
        <div className="row mt-3">
          <div id="qadd-ingred-errmsg">
            {formik.errors.name
              ? formik.errors.name
              : formik.errors.unit_measure
              ? formik.errors.unit_measure
              : formik.errors.unit_size
              ? formik.errors.unit_size
              : null}
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddRecipeItem;
