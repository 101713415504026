import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useHttp from "../../hooks/useHttp";
import MainPanel from "../../components/MainPanel";
import Table from "../../components/Table";
import { ToastContainer, toast } from "react-toastify";
import { FaRegEdit } from "react-icons/fa";

const PurchaseOrder = () => {
  const [_, handleHttpRequest] = useHttp();
  const [purchaseOrders, setPurchaseOrders] = useState([]);

  const getPurchaseOrders = async () => {
    const response = await handleHttpRequest({
      page: "purchaseorder",
      method: "GET",
    });
    if (!response.state) {
      setPurchaseOrders(response);
    } else {
      //
    }
  };

  useEffect(() => {
    getPurchaseOrders();
  }, []);

  // ({ value }) => (value ? purchaseOrderStatus(value) : "")
  const purchaseOrderStatus = (purchaseorderitems) => {
    let part_received = purchaseorderitems?.some(
      (purchaseorderitem) => purchaseorderitem.order_status === "Part recvd"
    );
    let received;
    let not_received;

    if (!part_received) {
      //check if it's all received
      not_received = purchaseorderitems?.some(
        (purchaseorderitem) =>
          purchaseorderitem.order_status === "Not recvd" || "Voided"
      );
      received = !not_received; // if there is no "not recvd" or "voided" or"part rec", it's fully received.
    }

    return (
      <span
        className={`badge rounded-pill ${
          (received && "text-bg-primary") ||
          (part_received && "text-bg-danger") ||
          (not_received && "text-bg-primary")
        }`}
        style={{ color: "#000" }}
      >
        {(received && "Recvd") ||
          (part_received && "Part recvd") ||
          (not_received && "Not recvd")}
      </span>
    );
  };

  const COLUMNS = [
    {
      Header: "Order Number",
      accessor: "purchase_order_number",
    },
    {
      Header: "Order Date",
      accessor: "purchase_order_date",
    },
    {
      Header: "Supplier",
      accessor: "supplier.name",
    },
    {
      Header: "$Order Total",
      accessor: "total_ordered_qty",
      Cell: (cell) => (cell.value ? "$" + cell.value : ""),
    },
    {
      Header: "$Recvd Total",
      accessor: "received_total",
      Cell: (cell) => (cell.value ? "$" + cell.value : ""),
    },
    {
      Header: "Status",
      accessor: "order_status",
      disableSortBy: true,
      Cell: (cell) =>
        cell.row.original?.purchaseorderitem
          ? purchaseOrderStatus(cell.row.original?.purchaseorderitem)
          : "",
    },
    {
      Header: "Actions",
      accessor: "id",
      disableSortBy: true,
      Cell: (cell) => (
        <div>
          <button
            className="btn btn-link btn-just-icon icontheme editcount"
            style={{ color: "#FF7300" }}
          >
            <Link to={`/edit-order-items/${cell.value}`} className="themecolor">
              <FaRegEdit />{" "}
            </Link>
          </button>
        </div>
      ),
    },
  ];

  return (
    <>
      <MainPanel>
        <div className="card">
          <div className="card-header text-bg-secondary">
            <div className="row">
              <h4 className="card-title">ORDERS</h4>
            </div>
          </div>
          <div className="card-body">
            <div className="toolbar"></div>
            <Table data={purchaseOrders} columns={COLUMNS} />
          </div>
        </div>
        <ToastContainer position="bottom-right" hideProgressBar />
      </MainPanel>
    </>
  );
};

export default PurchaseOrder;
