import MainPanel from "../../components/MainPanel";
import Table from "../../components/Table";
import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaRegEdit, FaPlus, FaList } from "react-icons/fa";
import useHttp from "../../hooks/useHttp";
import UsersButtonGroup from "../../components/groupButtons/UsersButtonGroup";
import SetUserStatus from "./setUserStatus";
import Notification from "../../components/Notification";
import { Toast } from "bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Users = () => {
  const [hasError, handleHttpRequest] = useHttp();
  const [users, setUsers] = useState([]);
  const userIdIs = useRef(0);
  const userStatus = useRef();
  const [beUpdated, setBEUpdated] = useState(false);
  // const [toastUpdated, setToastUpdated] = useState(false);
  // const notificationStatus = useRef();
  // const notificationMessage = useRef();

  const getUsers = async () => {
    //const response = await handleHttpRequest({ page: "recipes", method: "GET" });
    const response = await handleHttpRequest({
      page: "users",
      method: "GET",
    });
    // console.log(response);
    if (!response.status) {
      setUsers(response);
    }
  };

  useEffect(() => {
    getUsers();
  }, [beUpdated]);

  // const toastRef = useRef();
  // var [toast, setToast] = useState(false);

  // useEffect(() => {
  //   var myToast = toastRef.current;
  //   var bsToast = Toast.getInstance(myToast);
  //   if (!bsToast) {
  //     // initialize Toast
  //     bsToast = new Toast(myToast, { delay: 4000, autohide: true });
  //     // hide after init
  //     bsToast.hide();
  //     setToast(false);
  //   } else {
  //     // toggle
  //     toast ? bsToast.show() : bsToast.hide();
  //     // setToast(state => !state);
  //   }
  // }, [toastUpdated, setToastUpdated]);

  //issetStatusModalOpen
  const [issetStatusModalOpen, setIssetStatusModalOpen] = useState(false);
  const openSetStatusModal = (id, status) => {
    userIdIs.current = id;
    userStatus.current = status;
    setIssetStatusModalOpen((state) => !state);
  };
  const setStatusHandler = (message, status) => {
    setIssetStatusModalOpen(false);
    setBEUpdated((state) => !state);
    toast.info(message);
    // notificationMessage.current = data[0];
    // notificationStatus.current = data[1];
    // setToast(true);
    // setToastUpdated((state) => !state);
    // setBEUpdated((state) => !state);
  };

  const COLUMNS = [
    {
      Header: "User Name",
      accessor: "name",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Role",
      accessor: "roles",
      Cell: (cell) => {
        return cell.value[0] ? cell.value[0].name : "";
      }, //cell[0].name},
    },
    {
      Header: "Status",
      accessor: "is_active",
      disableSortBy: true,
      Cell: (cell) => (
        <span
          className={`badge rounded-pill ${
            cell.value ? "text-bg-primary" : "text-bg-danger"
          }`}
          style={{ color: "#000" }}
        >
          {cell.value == 1 ? "Active" : "In-active"}
        </span>
      ),
    },
    {
      Header: "Actions",
      accessor: "id",
      disableSortBy: true,
      Cell: (cell) => (
        <div>
          <UsersButtonGroup
            cell={cell.value}
            isActive={cell.row.original.is_active}
            openSetStatusModal={openSetStatusModal}
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <MainPanel>
        {/* {
          isAddModalOpen && (
            <AddRecipe saveClick={saveRecipe} onClick={openAddModal} />
          )
          //recipe_id={recipeId}
        } */}
        {/* {isdeleteModalOpen && (
          <DeleteModal
            deleteId={deleteIdIs.current}
            onClick={openDeleteModal}
            onDelete={deleteHandler}
            item="recipe"
            name="recipe"
          />
        )} */}
        {issetStatusModalOpen && (
          <SetUserStatus
            userId={userIdIs.current}
            currentStatus={userStatus.current}
            onClick={openSetStatusModal}
            onToggle={setStatusHandler}
          />
        )}
        <div className="card">
          <div className="card-header text-bg-secondary">
            <div className="row">
              <h4 className="card-title">
                Users
                {/* <button
                  type="button"
                  className="btn btn-outline-light ms-3"
                  name="AddRecipe"
                  title="New Recipe"
                  style={{ padding: "4px 8px 7px" }}
                  onClick={openAddModal}
                >
                  <FaPlus />
                </button> */}
                <Link
                  type="button"
                  to={`/add-user`}
                  className="btn btn-outline-light ms-3"
                  style={{ padding: "4px 8px 7px" }}
                >
                  <FaPlus />{" "}
                </Link>
              </h4>
            </div>
          </div>
          <div className="card-body">
            <div className="toolbar"></div>
            <Table data={users} columns={COLUMNS} />
          </div>
        </div>
      </MainPanel>
      <ToastContainer position="bottom-right" hideProgressBar />
    </>
  );
};

export default Users;
