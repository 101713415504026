import React from "react";
import useHttp from "../../hooks/useHttp";
import { useFormik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { IoIosSave } from "react-icons/io";

const AddSale = ({ saveClick }) => {
  const [_, handleHttpRequest] = useHttp();

  const addSale = async (values) => {
    const response = await handleHttpRequest({
      page: "sale/add",
      method: "POST",
      data: values,
    });
    if (!response.status) {
      saveClick(response);
    }
  };

  const formik = useFormik({
    initialValues: {
      date: "",
    },
    validationSchema: Yup.object({
      date: Yup.date().required("Select a Sales Date."),
      // .date(100, "Must be a date"),
    }),
    onSubmit: (values) => {
      addSale(values);
      formik.resetForm();
    },
  });

  return (
    <div class="quickadd mb-4">
      <form onSubmit={formik.handleSubmit}>
        <small class="texttheme fw-bold">ADD SALES DATE</small>
        <div class="row">
          <div class="col-lg-3 col-sm-6">
            <fieldset>
              <label htmlFor="date" class="lblrequired lbltheme">
                Sales Date*
              </label>
              <input
                type="date"
                className="form-control"
                id="date"
                name="date"
                value={formik.values.date}
                onChange={formik.handleChange}
              />
            </fieldset>
          </div>
          <div class="col d-flex align-self-end justify-content-start">
            <button
              type="submit"
              class="btn btn-link btn-just-icon"
              id="addSaleDate"
              name="addSaleDate"
              title="Add"
            >
              {/* <FaPlus /> */}
              <IoIosSave className="save-icon" />
            </button>
          </div>
        </div>
      </form>
      <div class="row">
        <div id="qadd-ingred-errmsg">
          {formik.errors.date ? formik.errors.date : null}
        </div>
      </div>
    </div>
  );
};
export default AddSale;
