import React, { useState } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import useHttp from "../../hooks/useHttp";
import style from "../../assets/style/modal.module.css";
import { read, utils } from "xlsx";
import CSVReader from "react-csv-reader";

export const ImportIngredientsInventoryModal = ({
  onClose,
  onImport,
  onError,
}) => {
  const [hasError, handleHttpRequest] = useHttp();
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("auth_token");
  const [errorMessage, setErrorMessage] = useState("");

  const [data, setData] = useState([]);
  const [sliceData, setSliceData] = useState([]);
  const [file, setFile] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [fieldMapping, setFieldMapping] = useState({
    ingredient: "",
    qty_on_hand: "",
    size_on_hand: "",
    storage: "",
  });

  const handleFileLoad = (event) => {
    console.log(event.target.files[0]);
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const binaryStr = event.target.result;
        const workbook = read(binaryStr, { type: "binary" });
        //assuming the first sheet is the one you want to work with
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        const jsonData = utils.sheet_to_json(sheet, { header: 1 });

        const fileHeaders = jsonData[0];
        const fileData = jsonData.slice(1);
        const slice = jsonData.slice(1, 4);

        console.log("file headers", fileHeaders);
        setHeaders(fileHeaders);
        setData(fileData);
        setSliceData(slice);
      };
      reader.readAsBinaryString(file); //readAsArrayBuffer(fileInfo);
    }
    setFile(file);
  };

  const handleColumnSelection = (index) => {
    setSelectedColumns((prev) => {
      if (prev.includes(index)) {
        return prev.filter((i) => i !== index);
      } else {
        return [...prev, index];
      }
    });
  };
  const handleColumnMapping = (event) => {
    const { name, value } = event.target;
    setFieldMapping((prev) => ({ ...prev, [name]: value }));
  };

  const uploadFile = async () => {
    if (file.length == 0) {
      //data.length == 0 ||
      setErrorMessage("File is required.");
      return;
    }
    const formData = new FormData();
    formData.append("file", file);
    formData.append("rows", JSON.stringify(fieldMapping));
    console.log(...formData);

    const mappedData = data.map((row) => {
      return {
        ingredient: row[headers.indexOf(fieldMapping.ingredient)],
        qty_on_hand: row[headers.indexOf(fieldMapping.qty_on_hand)],
        size_on_hand: row[headers.indexOf(fieldMapping.size_on_hand)],
        storage: row[headers.indexOf(fieldMapping.storage)],
      };
    });
    axios
      .post(
        apiUrl + "/ingredients-inventory/import",
        formData,
        // [{ rows: fieldMapping }, formData],
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log("File successfully uploaded", response);
        onImport(response.data);
      })
      .catch((error) => {
        console.log("error:", error);
        // error.response.data.errors.file
        //   ? error.response.data.errors.file.map((element) => onError(element))
        //   : error.response.data.errors.map((element) =>
        //       onError(element.errors[0])
        //     );
        // console.log(
        //   "There was an error uploading the file!",
        //   error.response.data.errors.file
        // );
      });
  };

  return (
    <>
      <div className={style["modal-overlay"]}></div>
      <div className={style["modal-card-wrapper"]}>
        <div
          id={style["deleteModal"]}
          className={`card  ${style["modal-card"]} ${
            data.length > 0 && "w-75"
          }`}
          role="dialog"
        >
          <div className="modal-dialog w-100">
            <div className="modal-content">
              <div
                className={` ${style["modal-header"]} text-bg-secondary`}
                data-bs-theme="dark"
              >
                <h5 className="modal-title" id="staticBackdropLabel">
                  Import Ingredient Inventory
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={onClose}
                  aria-label="Close"
                ></button>
              </div>
              <div className={style["modal-content"]}>
                {/* <CSVReader onFileLoaded={handleFileLoad} /> */}
                <input
                  type="file"
                  name="file"
                  accept=".xlsx, .xls, .csv"
                  onChange={handleFileLoad}
                />
                <div className="row">
                  <div className="col-sm-12 mt-2">
                    <h5>Map import fields to the system</h5>
                  </div>
                  <div className="col-sm-9">
                    <div className="row m-2">
                      <div className="col-sm-6">
                        <strong>Fields in the system</strong>
                      </div>
                      <div className="col-sm-6">
                        <strong>Fields in file</strong>
                      </div>
                    </div>
                    {/* Ingredient Name */}
                    <div className="row m-2">
                      <div className="col-sm-6">
                        <label>Ingredient Name</label>
                      </div>
                      <div className="col-sm-6">
                        <select
                          className="form-select"
                          id="ingredient"
                          name="ingredient"
                          value={fieldMapping.ingredient}
                          onChange={handleColumnMapping}
                        >
                          <option value="">Select Column</option>
                          {headers.map((header) => {
                            return (
                              <option key={header} value={header}>
                                {header}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    {/* Quantity on hand */}
                    <div className="row m-2">
                      <div className="col-sm-6">
                        <label>Quantity on hand</label>
                      </div>
                      <div className="col-sm-6">
                        <select
                          className="form-select"
                          id="qty_on_hand"
                          name="qty_on_hand"
                          value={fieldMapping.qty_on_hand}
                          onChange={handleColumnMapping}
                        >
                          <option value="">Select Column</option>
                          {headers.map((header) => {
                            return (
                              <option key={header} value={header}>
                                {header}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    {/* Size on hand */}
                    <div className="row m-2">
                      <div className="col-sm-6">
                        <label>Size on hand</label>
                      </div>
                      <div className="col-sm-6">
                        <select
                          className="form-select"
                          id="size_on_hand"
                          name="size_on_hand"
                          value={fieldMapping.size_on_hand}
                          onChange={handleColumnMapping}
                        >
                          <option value="">Select Column</option>
                          {headers.map((header) => {
                            return (
                              <option key={header} value={header}>
                                {header}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    {/* Storage */}
                    <div className="row m-2">
                      <div className="col-sm-6">
                        <label>Storage</label>
                      </div>
                      <div className="col-sm-6">
                        <select
                          className="form-select"
                          id="storage"
                          name="storage"
                          value={fieldMapping.storage}
                          onChange={handleColumnMapping}
                        >
                          <option value="">Select Column</option>
                          {headers.map((header) => {
                            return (
                              <option key={header} value={header}>
                                {header}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>

                  {/* <div className="col-sm-3">
                    <table>
                      <thead>
                        <th>Select Header</th>
                      </thead>
                      <tbody>
                        {headers.map((header) => {
                          return (
                            <tr key={header}>
                              <td>
                                <input
                                  type="checkbox"
                                  checked={selectedColumns.includes(header)}
                                  onChange={() => handleColumnSelection(header)}
                                />
                                {header}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div> */}
                </div>
              </div>
              <div
                className={`${style["modal-footer"]} justify-content-between`}
              >
                <div className="d-flex">
                  {errorMessage != "" && (
                    <span className="error-msg"> {errorMessage}</span>
                  )}
                </div>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={uploadFile}
                >
                  Upload
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ImportIngredientsInventory = (props) => {
  return ReactDOM.createPortal(
    <ImportIngredientsInventoryModal
      onClose={props.onClose}
      onImport={props.onImport}
      onError={props.onError}
    />,
    document.getElementById("modal")
  );
};
export default ImportIngredientsInventory;
