import React, { useState } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import useHttp from "../../hooks/useHttp";
import style from "../../assets/style/modal.module.css";
import { read, utils } from "xlsx";
import CSVReader from "react-csv-reader";
// import style from '../../assets/style/table.module.css';

export const ImportSuppliersModal = ({ onClose, onImport, onError }) => {
  const [hasError, handleHttpRequest] = useHttp();
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("auth_token");
  const [errorMessage, setErrorMessage] = useState("");

  const [data, setData] = useState([]);
  const [sliceData, setSliceData] = useState([]);
  const [file, setFile] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [fieldMapping, setFieldMapping] = useState({
    name: "",
    account: "",
    address: "",
    note: "",
    sales_person: "",
    supplier_email: "",
    supplier_phone: "",
    sales_person_email: "",
    sales_person_phone: "",
    ar_email: "",
    ar_phone: "",
    is_active: "",
  });

  const handleFileLoad = (event) => {
    console.log(event.target.files[0]);
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const binaryStr = event.target.result;
        const workbook = read(binaryStr, { type: "binary" });
        //assuming the first sheet is the one you want to work with
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        const jsonData = utils.sheet_to_json(sheet, { header: 1 });

        const fileHeaders = jsonData[0];
        const fileData = jsonData.slice(1);
        const slice = jsonData.slice(1, 4);

        console.log("file headers", fileHeaders);
        setHeaders(fileHeaders);
        setData(fileData);
        setSliceData(slice);
      };
      reader.readAsBinaryString(file); //readAsArrayBuffer(fileInfo);
    }
    setFile(file);
  };

  const handleColumnSelection = (index) => {
    setSelectedColumns((prev) => {
      if (prev.includes(index)) {
        return prev.filter((i) => i !== index);
      } else {
        return [...prev, index];
      }
    });
  };
  const handleColumnMapping = (event) => {
    const { name, value } = event.target;
    setFieldMapping((prev) => ({ ...prev, [name]: value }));
  };

  const uploadFile = async () => {
    if (file.length == 0) {
      setErrorMessage("File is required.");
      return;
    }
    if (fieldMapping.name === "") {
      setErrorMessage("Select supplier name to import.");
      return;
    }
    const formData = new FormData();
    formData.append("file", file);
    formData.append("rows", JSON.stringify(fieldMapping));
    console.log(...formData);

    const mappedData = data.map((row) => {
      return {
        name: row[headers.indexOf(fieldMapping.name)],
        account: row[headers.indexOf(fieldMapping.account)],
        address: row[headers.indexOf(fieldMapping.address)],
        note: row[headers.indexOf(fieldMapping.note)],
        sales_person: row[headers.indexOf(fieldMapping.sales_person)],
        supplier_email: row[headers.indexOf(fieldMapping.supplier_email)],
        supplier_phone: row[headers.indexOf(fieldMapping.supplier_phone)],
        sales_person_email:
          row[headers.indexOf(fieldMapping.sales_person_email)],
        sales_person_phone:
          row[headers.indexOf(fieldMapping.sales_person_phone)],
        ar_email: row[headers.indexOf(fieldMapping.ar_email)],
        ar_phone: row[headers.indexOf(fieldMapping.ar_phone)],
        is_active: row[headers.indexOf(fieldMapping.is_active)],
      };
    });
    axios
      .post(
        apiUrl + "/supplier/import",
        formData,
        // [{ rows: fieldMapping }, formData],
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log("File successfully uploaded", response);
        onImport(response.data);
      })
      .catch((error) => {
        console.log("error:", error);
        // error.response.data.errors.file
        //   ? error.response.data.errors.file.map((element) => onError(element))
        //   : error.response.data.errors.map((element) =>
        //       onError(element.errors[0])
        //     );
        // console.log(
        //   "There was an error uploading the file!",
        //   error.response.data.errors.file
        // );
      });
  };

  return (
    <>
      <div className={style["modal-overlay"]}></div>
      <div className={style["modal-card-wrapper"]}>
        <div
          id={style["deleteModal"]}
          className={`card  ${style["modal-card"]} ${
            data.length > 0 && "w-75"
          }`}
          role="dialog"
        >
          <div className="modal-dialog w-100">
            <div className="modal-content">
              <div
                className={` ${style["modal-header"]} text-bg-secondary`}
                data-bs-theme="dark"
              >
                <h5 className="modal-title" id="staticBackdropLabel">
                  Import Suppliers
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={onClose}
                  aria-label="Close"
                ></button>
              </div>
              <div className={style["modal-content"]}>
                {/* <CSVReader onFileLoaded={handleFileLoad} /> */}
                <input
                  type="file"
                  name="file"
                  accept=".xlsx, .xls, .csv"
                  onChange={handleFileLoad}
                />
                <div className="row">
                  <div className="col-sm-12 mt-2">
                    <h5>Map import fields to the system</h5>
                  </div>
                  <div className="col-sm-10">
                    <div className="row m-2">
                      <div className="col-sm-6">
                        <strong>Fields in the system</strong>
                      </div>
                      <div className="col-sm-6">
                        <strong>Fields in file</strong>
                      </div>
                    </div>
                    {/* Supplier Name */}
                    <div className="row m-2">
                      <div className="col-sm-6">
                        <label>Supplier Name</label>
                      </div>
                      <div className="col-sm-6">
                        <select
                          className="form-select"
                          id="name"
                          name="name"
                          value={fieldMapping.name}
                          onChange={handleColumnMapping}
                        >
                          <option value="">Select Column</option>
                          {headers.map((header) => {
                            return (
                              <option key={header} value={header}>
                                {header}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    {/* Account */}
                    <div className="row m-2">
                      <div className="col-sm-6">
                        <label>Account</label>
                      </div>
                      <div className="col-sm-6">
                        <select
                          className="form-select"
                          id="account"
                          name="account"
                          value={fieldMapping.account}
                          onChange={handleColumnMapping}
                        >
                          <option value="">Select Column</option>
                          {headers.map((header) => {
                            return (
                              <option key={header} value={header}>
                                {header}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    {/* Address */}
                    <div className="row m-2">
                      <div className="col-sm-6">
                        <label>Address</label>
                      </div>
                      <div className="col-sm-6">
                        <select
                          className="form-select"
                          id="address"
                          name="address"
                          value={fieldMapping.address}
                          onChange={handleColumnMapping}
                        >
                          <option value="">Select Column</option>
                          {headers.map((header) => {
                            return (
                              <option key={header} value={header}>
                                {header}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    {/* Note */}
                    <div className="row m-2">
                      <div className="col-sm-6">
                        <label>Note</label>
                      </div>
                      <div className="col-sm-6">
                        <select
                          className="form-select"
                          id="note"
                          name="note"
                          value={fieldMapping.note}
                          onChange={handleColumnMapping}
                        >
                          <option value="">Select Column</option>
                          {headers.map((header) => {
                            return (
                              <option key={header} value={header}>
                                {header}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    {/* supplier_email */}
                    <div className="row m-2">
                      <div className="col-sm-6">
                        <label>Supplier Email</label>
                      </div>
                      <div className="col-sm-6">
                        <select
                          className="form-select"
                          id="supplier_email"
                          name="supplier_email"
                          value={fieldMapping.supplier_email}
                          onChange={handleColumnMapping}
                        >
                          <option value="">Select Column</option>
                          {headers.map((header) => {
                            return (
                              <option key={header} value={header}>
                                {header}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    {/* supplier_phone */}
                    <div className="row m-2">
                      <div className="col-sm-6">
                        <label>Supplier Phone</label>
                      </div>
                      <div className="col-sm-6">
                        <select
                          className="form-select"
                          id="supplier_phone"
                          name="supplier_phone"
                          value={fieldMapping.supplier_phone}
                          onChange={handleColumnMapping}
                        >
                          <option value="">Select Column</option>
                          {headers.map((header) => {
                            return (
                              <option key={header} value={header}>
                                {header}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    {/* sales_person */}
                    <div className="row m-2">
                      <div className="col-sm-6">
                        <label>Sales Person</label>
                      </div>
                      <div className="col-sm-6">
                        <select
                          className="form-select"
                          id="sales_person"
                          name="sales_person"
                          value={fieldMapping.sales_person}
                          onChange={handleColumnMapping}
                        >
                          <option value="">Select Column</option>
                          {headers.map((header) => {
                            return (
                              <option key={header} value={header}>
                                {header}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    {/*  Sales Person Email */}
                    <div className="row m-2">
                      <div className="col-sm-6">
                        <label>Sales Person Email</label>
                      </div>
                      <div className="col-sm-6">
                        <select
                          className="form-select"
                          id="sales_person_email"
                          name="sales_person_email"
                          value={fieldMapping.sales_person_email}
                          onChange={handleColumnMapping}
                        >
                          <option value="">Select Column</option>
                          {headers.map((header) => {
                            return (
                              <option key={header} value={header}>
                                {header}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    {/*  Sales Person Phone */}
                    <div className="row m-2">
                      <div className="col-sm-6">
                        <label>Sales Person Phone</label>
                      </div>
                      <div className="col-sm-6">
                        <select
                          className="form-select"
                          id="sales_person_phone"
                          name="sales_person_phone"
                          value={fieldMapping.sales_person_phone}
                          onChange={handleColumnMapping}
                        >
                          <option value="">Select Column</option>
                          {headers.map((header) => {
                            return (
                              <option key={header} value={header}>
                                {header}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    {/*  AR Person Email */}
                    <div className="row m-2">
                      <div className="col-sm-6">
                        <label>AR Person Email</label>
                      </div>
                      <div className="col-sm-6">
                        <select
                          className="form-select"
                          id="ar_email"
                          name="ar_email"
                          value={fieldMapping.ar_email}
                          onChange={handleColumnMapping}
                        >
                          <option value="">Select Column</option>
                          {headers.map((header) => {
                            return (
                              <option key={header} value={header}>
                                {header}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    {/*  AR Person Phone */}
                    <div className="row m-2">
                      <div className="col-sm-6">
                        <label>AR Person Phone</label>
                      </div>
                      <div className="col-sm-6">
                        <select
                          className="form-select"
                          id="ar_phone"
                          name="ar_phone"
                          value={fieldMapping.ar_phone}
                          onChange={handleColumnMapping}
                        >
                          <option value="">Select Column</option>
                          {headers.map((header) => {
                            return (
                              <option key={header} value={header}>
                                {header}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    {/*  Is Active */}
                    <div className="row m-2">
                      <div className="col-sm-6">
                        <label>Is Active</label>
                      </div>
                      <div className="col-sm-6">
                        <select
                          className="form-select"
                          id="is_active"
                          name="is_active"
                          value={fieldMapping.is_active}
                          onChange={handleColumnMapping}
                        >
                          <option value="">Select Column</option>
                          {headers.map((header) => {
                            return (
                              <option key={header} value={header}>
                                {header}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>

                  {/* <div className="col-sm-3">
                    <table>
                      <thead>
                        <th>Select Header</th>
                      </thead>
                      <tbody>
                        {headers.map((header) => {
                          return (
                            <tr key={header}>
                              <td>
                                <input
                                  type="checkbox"
                                  checked={selectedColumns.includes(header)}
                                  onChange={() => handleColumnSelection(header)}
                                />
                                {header}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div> */}
                </div>
              </div>
              <div
                className={`${style["modal-footer"]} justify-content-between`}
              >
                <div className="d-flex">
                  {errorMessage != "" && (
                    <span className="error-msg"> {errorMessage}</span>
                  )}
                </div>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={uploadFile}
                >
                  Upload
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ImportSuppliers = (props) => {
  return ReactDOM.createPortal(
    <ImportSuppliersModal
      onClose={props.onClose}
      onImport={props.onImport}
      onError={props.onError}
    />,
    document.getElementById("modal")
  );
};
export default ImportSuppliers;
