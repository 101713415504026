import React, { useState } from "react";
import ReactDOM from "react-dom";
import useHttp from "../hooks/useHttp";
import style from "../assets/style/modal.module.css";

export const DeleteItemModal = ({
  deleteId,
  onCancle,
  onDelete,
  item,
  name = "",
}) => {
  const [hasError, handleHttpRequest] = useHttp();
  const deleteItem = async (id) => {
    const response = await handleHttpRequest({
      page: item + "/" + id,
      method: "DELETE",
    });
    if (!response.status) {
      onDelete([response, 200]);
    } else {
      onDelete([response.data, response.status]);
    }
  };

  return (
    <>
      <div className={style["modal-overlay"]}></div>
      <div className={style["modal-card-wrapper"]}>
        <div
          id={style["deleteModal"]}
          className={`card  ${style["modal-card"]}`}
          role="dialog"
        >
          <div className="modal-dialog w-100">
            <div className="modal-content">
              <div
                className={` ${style["modal-header"]} text-bg-secondary`}
                data-bs-theme="dark"
              >
                <h5 className="modal-title" id="staticBackdropLabel">
                  Delete Item
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={onCancle}
                  aria-label="Close"
                ></button>
              </div>
              <div className={style["modal-content"]}>Delete this {name}?</div>
              <div className={style["modal-footer"]}>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => deleteItem(deleteId)}
                >
                  Confirm
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={onCancle}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const DeleteModal = (props) => {
  return ReactDOM.createPortal(
    <DeleteItemModal
      deleteId={props.deleteId}
      onCancle={props.onClick}
      onDelete={props.onDelete}
      item={props.item}
      name={props.name}
    />,
    document.getElementById("modal")
  );
};
export default DeleteModal;
