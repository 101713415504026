import MainPanel from "../../components/MainPanel";
import useHttp from "../../hooks/useHttp";
import QuickAddInventory from "./QuickAddInventory";
import { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Link, useParams, useNavigate } from "react-router-dom";
import { FaRegEdit, FaPlus, FaList, FaSave, FaMinus } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdOutlineDoNotDisturbAlt } from "react-icons/md";
import InventoryCountTable from "./InventoryCountTable";
import EditIngredientInventory from "./EditIngredientInventory";

const InventoryCount = () => {
  const { id: inventory_id } = useParams();
  const [_, handleHttpRequest] = useHttp();
  const [inventories, setInventories] = useState([]);
  // const [newInventories, setNewInventories] = useState([]);
  const [ingredientsForLocation, setIngredientsForLocation] = useState([]);
  const [initialValue, setInitialValue] = useState({
    date: "",
    location: "",
  });
  const navigate = useNavigate();

  const getInventory = async () => {
    const response = await handleHttpRequest({
      page: "inventory-count/" + inventory_id,
      method: "GET",
    });
    if (!response.status) {
      setInitialValue({
        date_time: response.date_time,
        location: response.storagelocation.name,
      });
      getIngredientsLocations(response.storage_location_id);
    } else {
      // saveClick(response, response.status); toast notification
    }
  };
  const getIngredientsLocations = async (location_id) => {
    const response = await handleHttpRequest({
      page: "ingredients-by-location/" + location_id,
      method: "GET",
    });
    if (!response.status) {
      setInventories(response);

      setIngredientsForLocation(response);
    } else {
      // saveClick(response, response.status); toast notification
    }
  };
  useEffect(() => {
    getInventory();
  }, []);

  const addIngredienttoInventory = (values) => {
    let ing_exists = inventories.find(
      (inv) => inv.ingredient.id == values.ingredient_id
    );
    //update the existing
    if (ing_exists) {
      const updateIng = inventories.map((inventory) =>
        inventory.ingredient_id == values.ingredient_id
          ? {
              ...inventory,
              qty_on_hand: inventory.qty_on_hand + values.qty_on_hand,
            }
          : inventory
      );
      // setInventories(updateIng);
      setInventories(updateIng);
    } else {
      setInventories((prevInv) => [
        ...prevInv,
        {
          // id: Math.floor(Math.random() * (10000 - 2)) + 1,
          // inventory_id: inventory_id,
          ingredient: values.ingredient,
          ingredient_id: values.ingredient_id,
          size_on_hand: values.size_on_hand,
          qty_on_hand: values.qty_on_hand,
        },
      ]);
    }
  };

  //update inventories from inventory count table
  const updateInventories = (value) => {
    setInventories(value);
  };

  const inventory_to_update = useRef({});
  const inventory_to_update_index = useRef({});
  const [editIngInventory, setEditIngInventory] = useState(false);
  const handleEditIngInventory = (inventory, index) => {
    inventory_to_update.current = inventory;
    inventory_to_update_index.current = index;
    setEditIngInventory(true);
  };
  const closeEditIngInventory = () => {
    setEditIngInventory(false);
  };
  const handleUpdateIngInventory = (inventory, index) => {
    setEditIngInventory(false);
    if (index !== -1) {
      const updatedInv = [...inventories];
      updatedInv[index] = { ...inventories[index], ...inventory };
      setInventories(updatedInv);
      toast.info("Ingredient count updated.");
    }
  };

  //**Finally save all the ing inventory list */
  const saveAllIngInventory = async () => {
    //save the inventories & newInventories
    const response = await handleHttpRequest({
      page: "ingredients-inventory/update/" + inventory_id,
      method: "PUT",
      data: { inventories: inventories },
    });
    if (!response.state) {
      //toast notification
      // toast.info(response);
      //redirect to home page
      return navigate("/inventorycount", { state: { response } });
    } else {
      //toast notification
      toast.error(response.data);
    }
  };
  const confirmSaveAll = async () => {
    await new Promise((resolve) => {
      alert("Save the ingredients to inventory count?");
      resolve();
    });
    saveAllIngInventory();
  };

  const deleteAllIngInventory = async () => {
    //save the inventories & newInventories
    const response = await handleHttpRequest({
      page: "inventory-count/" + inventory_id,
      method: "DELETE",
      data: inventories,
    });
    if (!response.state) {
      //toast notification
      // toast.info(response);
      //redirect to home page
      return navigate("/inventorycount", { state: { response } });
    } else {
      //toast notification
      toast.error(response.data);
    }
  };
  const confirmDeleteAll = async () => {
    await new Promise((resolve) => {
      alert("Delete the ingredients to inventory count?");
      resolve();
    });
    deleteAllIngInventory();
  };

  return (
    <>
      <MainPanel>
        {editIngInventory && (
          <EditIngredientInventory
            onClick={closeEditIngInventory}
            storagelocation={initialValue.location}
            IngInventory={inventory_to_update.current}
            IngInventoryIndex={inventory_to_update_index.current}
            updateIngInventory={handleUpdateIngInventory}
          />
        )}
        <div className="card">
          <div className="card-header text-bg-secondary">
            <div className="row">
              <h4 className="card-title">Inventory Count</h4>
            </div>
          </div>
          <div className="card-body">
            <div className="toolbar"></div>
            <div className="row">
              <div className="col-lg-3 col-sm-4">{initialValue.date_time}</div>
              <div className="col-lg-3 col-sm-4">{initialValue.location}</div>
              <div className="col-sm-3">
                <button
                  className="btn btn-link btn-just-icon icontheme editcount"
                  onClick={confirmSaveAll}
                >
                  <FaSave className="themecolor" style={{ fontSize: "24px" }} />
                </button>
                <button
                  className="btn btn-link btn-just-icon icontheme editcount"
                  onClick={confirmDeleteAll}
                >
                  <RiDeleteBin6Line
                    className="themecolor"
                    style={{ fontSize: "24px" }}
                  />
                </button>
              </div>
            </div>
            <QuickAddInventory
              ingredientsForLocation={ingredientsForLocation}
              addIngredienttoInventory={addIngredienttoInventory}
            />
            <hr></hr>
            <InventoryCountTable
              data={inventories}
              setdata={updateInventories}
              // newInventory={newInventories}
              // setNewInventory={updateNewInventories}
              storagelocation={initialValue.location}
              editIngInventory={handleEditIngInventory}
            />
          </div>
        </div>
      </MainPanel>
      <ToastContainer position="bottom-right" hideProgressBar />
    </>
  );
};

export default InventoryCount;
