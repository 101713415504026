import React, { useState, useEffect } from "react";
import useHttp from "../../hooks/useHttp";
import units from "../../utils/units";
import { useFormik } from "formik";
import * as Yup from "yup";
import { IoIosSave } from "react-icons/io";

const QuickAddIngredients = ({ saveIngredient }) => {
  //saveIngredient
  const [categories, setCategories] = useState([]);
  const [hasError, handleHttpRequest] = useHttp();

  const getCategories = async () => {
    // const response = await handleHttpRequest("categories");
    const response = await handleHttpRequest({
      page: "categories",
      method: "GET",
    });
    if (!hasError) {
      setCategories(response);
    }
  };

  const addIngredient = async (values) => {
    // const response = await handleHttpRequest("ingredient/add", "POST", values);
    const response = await handleHttpRequest({
      page: "ingredient/add",
      method: "POST",
      data: values,
    });
    if (!hasError) {
      saveIngredient(response);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  const formik = useFormik({
    initialValues: {
      name: "",
      default_unit_measure: "",
      default_unit_price: "",
      category_id: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required(" Ingredient Name is required.")
        .max(100, "Ingredient name is too long"),
      default_unit_measure: Yup.string().required(
        "Select a Default Unit of Measurement for the Ingredient."
      ),
      default_unit_price: Yup.number()
        .typeError("Price must be a number")
        .required("Last price is required."),
      category_id: Yup.number().required(
        "Select a category for the Ingredient."
      ),
    }),
    onSubmit: (values) => {
      addIngredient(values);
      formik.resetForm();
    },
  });

  return (
    <div class="quickadd">
      <form onSubmit={formik.handleSubmit}>
        <small class="texttheme fw-bold">QUICK ADD - INGREDIENT</small>
        <div class="row">
          <div class="col-sm-3">
            <fieldset>
              <label htmlFor="name" class="lblrequired lbltheme">
                Ingredient Name*
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
              />
            </fieldset>
          </div>
          <div class="col-sm-3">
            <fieldset>
              <label
                htmlFor="default_unit_measure"
                class="lblrequired lbltheme"
              >
                Default UOM*
              </label>
              <select
                class="form-select"
                name="default_unit_measure"
                id="default_unit_measure"
                value={formik.values.default_unit_measure}
                onChange={formik.handleChange}
              >
                <option selected>Select Measurement</option>
                {units.map((unit) => (
                  <option key={unit.id} value={unit.unit}>
                    {unit.name}
                  </option>
                ))}
              </select>
            </fieldset>
          </div>
          <div class="col-sm-3 col-lg-2">
            <fieldset>
              <label htmlFor="default_unit_price" class="lblrequired lbltheme">
                Last Price per Default Unit*
              </label>
              <input
                type="text"
                className="form-control"
                id="default_unit_price"
                name="default_unit_price"
                value={formik.values.default_unit_price}
                onChange={formik.handleChange}
              />
            </fieldset>
          </div>
          <div class="col-sm-3">
            <fieldset>
              <label htmlFor="category_id" class="lbltheme">
                Ingredient Category
              </label>
              <select
                class="form-select"
                name="category_id"
                id="category_id"
                value={formik.values.category_id}
                onChange={formik.handleChange}
              >
                <option selected>Select Category</option>
                {categories == undefined
                  ? ""
                  : categories.length == 0
                  ? "No data"
                  : categories.map((cateogry) => (
                      <option key={cateogry.id} value={cateogry.id}>
                        {cateogry.name}
                      </option>
                    ))}
              </select>
            </fieldset>
          </div>
          <div class="col d-flex align-self-end justify-content-start">
            <button
              type="submit"
              class="btn btn-link btn-just-icon"
              id="QuickAddIngred"
              name="QuickAddIngred"
              title="Add"
            >
              <IoIosSave className="save-icon" />
            </button>
          </div>
        </div>
      </form>
      <div class="row">
        <div id="qadd-ingred-errmsg" className="error-msg">
          {formik.errors.name
            ? formik.errors.name
            : formik.errors.default_unit_measure
            ? formik.errors.default_unit_measure
            : formik.errors.default_unit_price
            ? formik.errors.default_unit_price
            : formik.errors.category_id
            ? formik.errors.category_id
            : null}
        </div>
      </div>
    </div>
  );
};
export default QuickAddIngredients;
