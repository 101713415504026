import { useState, useEffect } from "react";
import useHttp from "../../hooks/useHttp";

const SelectRecipes = ({ value, onChange, onBlur, setUnitMeasure }) => {
  const [recipes, setRecipes] = useState([]);
  const [hasError, handleHttpRequest] = useHttp();

  const getRecipes = async () => {
    // const response = await handleHttpRequest('recipes')
    const response = await handleHttpRequest({
      page: "recipes",
      method: "GET",
    });
    if (!hasError) {
      setRecipes(response);
    }
  };

  useEffect(() => {
    getRecipes();
  }, []);

  const handleChange = (event) => {
    let rec = recipes.filter((recipe) => recipe.id == event.target.value);
    onChange(event);
    setUnitMeasure(rec[0].yeild_measure);
  };

  return (
    <>
      <select
        class="form-select"
        name="recipe_id"
        id="recipe_id"
        value={value}
        onChange={handleChange}
        onBlur={onBlur}
      >
        <option selected>Select recipes</option>
        {recipes == undefined
          ? ""
          : recipes.length == 0
          ? "No data"
          : recipes.map((recipe) => (
              <>
                <option key={recipe.id} value={recipe.id}>
                  {recipe.name}{" "}
                  <input
                    type="hidden"
                    name="yeild_measure"
                    value={recipe.yeild_measure}
                  />
                </option>
              </>
            ))}
      </select>
    </>
  );
};

export default SelectRecipes;
