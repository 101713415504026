import { useState } from "react";
import MainPanel from "../../components/MainPanel";
import useHttp from "../../hooks/useHttp";
import { read, utils } from "xlsx";
import axios from "axios";
import FileImportMapping from "./FileImportMapping";
import { ToastContainer, toast } from "react-toastify";

const Import = () => {
  const [selectedFile, setSelectedFile] = useState();
  const uploadFiles = [
    "Supplier",
    "Categories",
    "Ingredient",
    "Supplier ingredients",
    "Ingredients inventory",
    "Recipe",
    "Recipe Ingredients",
    "Menuitem",
    "Menuitem ingredients",
    "Menuitem recipes",
  ];

  const [hasError, handleHttpRequest] = useHttp();
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("auth_token");
  const [errorMessage, setErrorMessage] = useState("");

  const [data, setData] = useState([]);
  const [sliceData, setSliceData] = useState([]);
  const [file, setFile] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [fieldMapping, setFieldMapping] = useState({});
  const importFiles = {
    categories: [
      {
        name: "category",
        lable: "Category Name",
        optional: false,
      },
    ],
    supplier_ingredients: [
      {
        name: "ingredient",
        lable: "Ingredient",
        optional: false,
      },
      {
        name: "supplier",
        lable: "Supplier",
        optional: false,
      },
      {
        name: "supplier_item_number",
        lable: "Supplier item number",
        optional: true,
      },
      {
        name: "supplier_item_desc",
        lable: "Supplier item description",
        optional: false,
      },
      {
        name: "sold_by",
        lable: "Sold by",
        optional: false,
      },
      {
        name: "price_per_sold_by",
        lable: "Price per sold by",
        optional: false,
      },
      {
        name: "packs_per_sold_by",
        lable: "Packs per sold by",
        optional: false,
      },
      {
        name: "pack_unit_measure",
        lable: "Pack unit measure",
        optional: false,
      },
      {
        name: "pack_unit_size",
        lable: "Pack unit size",
        optional: false,
      },
      {
        name: "pack_unit_desc",
        lable: "Pack unit description",
        optional: true,
      },
      {
        name: "price_per_item",
        lable: "Price per item",
        optional: false,
      },
      {
        name: "price_opt",
        lable: "Price option",
        optional: true,
      },
      {
        name: "price_opt_price",
        lable: "Price option price",
        optional: true,
      },
      {
        name: "price_opt_measure",
        lable: "Price option measure",
        optional: true,
      },
      {
        name: "conv_factor",
        lable: "Conversion factor",
        optional: true,
      },
    ],
    menuitem_ingredients: [
      {
        name: "ingredient",
        lable: "Ingredient",
        optional: false,
      },
      {
        name: "menuitem",
        lable: "Menu item name",
        optional: false,
      },
      {
        name: "quantity",
        lable: "Quantity",
        optional: false,
      },
      {
        name: "unit_measure",
        lable: "Unit measure",
        optional: false,
      },
      {
        name: "type",
        lable: "Type",
        optional: false,
      },
    ],
    menuitem_recipes: [
      {
        name: "recipe",
        lable: "Recipe",
        optional: false,
      },
      {
        name: "menuitem",
        lable: "Menuitem",
        optional: false,
      },
      {
        name: "quantity",
        lable: "Quantity",
        optional: false,
      },
      {
        name: "unit_measure",
        lable: "Unit measure",
        optional: false,
      },
      {
        name: "type",
        lable: "Type",
        optional: false,
      },
    ],
    recipe_ingredients: [
      {
        name: "recipe",
        lable: "Recipe",
        optional: false,
      },
      {
        name: "ingredient",
        lable: "Ingredient",
        optional: false,
      },
      {
        name: "unit_size",
        lable: "Unit size",
        optional: false,
      },
      {
        name: "unit_measure",
        lable: "Unit measure",
        optional: false,
      },
    ],
    supplier: [
      {
        name: "name",
        lable: "Supplier name",
        optional: false,
      },
      {
        name: "account",
        lable: "Account",
        optional: true,
      },
      {
        name: "address",
        lable: "Address",
        optional: true,
      },
      {
        name: "note",
        lable: "Note",
        optional: true,
      },
      {
        name: "sales_person",
        lable: "Sales person",
        optional: true,
      },
      {
        name: "supplier_email",
        lable: "Supplier email",
        optional: true,
      },
      {
        name: "supplier_phone",
        lable: "Supplier phone",
        optional: true,
      },
      {
        name: "sales_person_email",
        lable: "Sales person email",
        optional: true,
      },
      {
        name: "sales_person_phone",
        lable: "Sales person phone",
        optional: true,
      },
      {
        name: "ar_email",
        lable: "AR email",
        optional: true,
      },
      {
        name: "ar_phone",
        lable: "AR phone",
        optional: true,
      },
      {
        name: "is_active",
        lable: "Is active",
        optional: true,
      },
    ],
    recipe: [
      {
        name: "name",
        lable: "Recipe name",
        optional: false,
      },
      {
        name: "yeild_qty",
        lable: "Yield quantity",
        optional: false,
      },
      {
        name: "yeild_measure",
        lable: "Yield measure",
        optional: false,
      },
    ],
    menuitem: [
      {
        name: "menuitem",
        lable: "Menu item",
        optional: false,
      },
      {
        name: "menugroup",
        lable: "Menu group",
        optional: true,
      },
      {
        name: "price",
        lable: "Price",
        optional: false,
      },
    ],
    ingredient: [
      {
        name: "name",
        lable: "Name",
        optional: false,
      },
      {
        name: "uom",
        lable: "UOM",
        optional: false,
      },
      {
        name: "price",
        lable: "Price",
        optional: true,
      },
      {
        name: "category",
        lable: "Category",
        optional: false,
      },
      {
        name: "primary_supplier",
        lable: "Primary supplier",
        optional: true,
      },
      {
        name: "secondary_supplier",
        lable: "Secondary supplier",
        optional: true,
      },
    ],
    ingredients_inventory: [
      {
        name: "ingredient",
        lable: "ingredient",
        optional: false,
      },
      {
        name: "qty_on_hand",
        lable: "Quantity on hand",
        optional: false,
      },
      {
        name: "size_on_hand",
        lable: "Size on hand",
        optional: false,
      },
      {
        name: "storage",
        lable: "Storage",
        optional: false,
      },
    ],
  };

  const handleFileLoad = (event) => {
    // console.log(event.target.files[0]);
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const binaryStr = event.target.result;
        const workbook = read(binaryStr, { type: "binary" });
        //assuming the first sheet is the one you want to work with
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        const jsonData = utils.sheet_to_json(sheet, { header: 1 });

        const fileHeaders = jsonData[0];
        const fileData = jsonData.slice(1);
        const slice = jsonData.slice(1, 4);

        // console.log("file headers", fileHeaders);
        setHeaders(fileHeaders);
        setData(fileData);
        setSliceData(slice);
      };
      reader.readAsBinaryString(file); //readAsArrayBuffer(fileInfo);
    }
    setFile(file);
  };
  const uploadFile = async () => {
    if (file.length == 0) {
      setErrorMessage("File is required.");
      return;
    }
    let imported_file_type =
      importFiles[selectedFile.toLowerCase().replace(" ", "_")];
    let error_message = "";
    imported_file_type.forEach((element) => {
      if (
        !element.optional &&
        !fieldMapping.hasOwnProperty(element.name) //Object.values(fieldMapping).includes(element.name)
      ) {
        error_message = element.lable + " needs to be included";
      }
    });
    if (error_message != "") {
      setErrorMessage(error_message);
      return;
    }
    setErrorMessage("");
    const formData = new FormData();
    formData.append("file", file);
    formData.append("rows", JSON.stringify(fieldMapping));

    axios
      .post(
        apiUrl + "/" + selectedFile.toLowerCase().replace(" ", "-") + "/import",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        toast.info("File imported successfully.");
        setSelectedFile(null);
        setFile([]);
        setHeaders([]);
        setData([]);
        setFieldMapping({});
      })
      .catch((error) => {
        // console.log("error:", error);
        // toast.error(error);
        toast.error("An error occured while importing the file");
      });
  };
  const selectImportFile = (event) => {
    setSelectedFile(null);
    setFieldMapping({});
    setSelectedFile(event.target.value);
  };
  const handleColumnMapping = (event) => {
    const { name, value } = event.target;
    setFieldMapping((prev) => ({ ...prev, [name]: value }));
    // console.log("fieldMapping ", fieldMapping);
  };

  return (
    <>
      <MainPanel>
        <div className="card">
          <div className="card-header text-bg-secondary">
            <div className="row">
              <h4 className="card-title">Import</h4>
            </div>
          </div>
          <div className="card-body">
            <div className="toolbar"></div>
            <div className="row">
              <div className="col-6">
                <input
                  type="file"
                  name="file"
                  accept=".xlsx, .xls, .csv"
                  onChange={handleFileLoad}
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-10">
                <label htmlFor="file_id" className="lblrequired lbltheme">
                  Select import*
                </label>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <select
                  class="form-select"
                  name="file_id"
                  id="file_id"
                  value={selectedFile}
                  onChange={selectImportFile}
                  // onBlur={onBlur}
                >
                  <option selected>Select import</option>
                  {uploadFiles.map((file) => (
                    <option value={file}>{file}</option>
                  ))}
                </select>
              </div>
              <div className="col-4">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={uploadFile}
                >
                  Upload
                </button>
              </div>
            </div>
            <div className="row mt-2">
              {errorMessage != "" && (
                <span className="error-msg"> {errorMessage}</span>
              )}
            </div>
            <hr></hr>
            <div className="row mt-2">
              {selectedFile && (
                <FileImportMapping //CategoriesImport
                  headers={headers}
                  fieldMapping={fieldMapping}
                  handleColumnMapping={handleColumnMapping}
                  files={
                    importFiles[selectedFile.toLowerCase().replace(" ", "_")]
                  }
                />
              )}{" "}
            </div>
          </div>
        </div>
      </MainPanel>
      <ToastContainer position="bottom-right" hideProgressBar />
    </>
  );
};

export default Import;
