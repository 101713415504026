import React from "react";
import ReactDOM from "react-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import useHttp from "../../hooks/useHttp";
import StorageLocations from "./StorageLocations";
import style from "../../assets/style/modal.module.css";

export const AddNewInventoryCountModal = ({ saveClick, onClick }) => {
  const [_, handleHttpRequest] = useHttp();

  const closeModal = () => {
    formik.resetForm();
    onClick();
  };

  const addInventoryCount = async (values) => {
    const response = await handleHttpRequest({
      page: "inventory-count/add",
      method: "POST",
      data: values,
    });
    if (!response.status) {
      saveClick(response, 200);
    } else {
      saveClick(response, response.status);
    }
  };

  const formik = useFormik({
    initialValues: {
      date: "",
      storage_location_id: "",
    },
    validationSchema: Yup.object({
      date: Yup.date().required("Select an Inventory Count Date"),
      storage_location_id: Yup.number(),
    }),
    onSubmit: (values) => {
      addInventoryCount(values);
    },
  });

  return (
    <>
      <div className={style["modal-overlay-overlay"]}></div>
      <div className={style["modal-card-wrapper"]}>
        <div className={`card  ${style["modal-card"]} w-75`} role="dialog">
          <div className="modal-dialog w-100">
            <div className="modal-content">
              <div
                className={` ${style["modal-header"]} text-bg-secondary `}
                data-bs-theme="dark"
              >
                <h5
                  className="modal-title text-uppercase"
                  id="staticBackdropLabel"
                >
                  INVENTORY COUNT
                </h5>
                <button
                  type="button"
                  className="btn-close dark-mode"
                  onClick={closeModal}
                  aria-label="Close"
                ></button>
              </div>
              <form class="" onSubmit={formik.handleSubmit}>
                <div className={style["modal-content"]}>
                  <div className="modal-body row g-3">
                    <div className="col-sm-6">
                      <label className="lblrequired lbltheme" htmlFor="name">
                        Inventory Count Date*
                      </label>
                      <input
                        type="datetime-local"
                        className="form-control"
                        id="date"
                        name="date"
                        value={formik.values.date}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                  <div className="modal-body row g-3">
                    <div className="col-sm-6">
                      <label
                        className="lblrequired lbltheme"
                        htmlFor="storage_location_id"
                      >
                        Location
                      </label>
                      <StorageLocations
                        setStorage={() => {}}
                        // onCategorySave={onCategorySave}
                        value={formik.values.storage_location_id}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        storageSaved={false}
                      />
                    </div>
                  </div>
                </div>
                <div className={style["modal-footer"]}>
                  <div>{formik.errors.date ? formik.errors.date : null}</div>
                  <button type="submit" className="btn btn-primary">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const AddNewInventoryCount = (props) => {
  return ReactDOM.createPortal(
    <AddNewInventoryCountModal
      saveClick={props.saveClick}
      onClick={props.onClick}
    />,
    document.getElementById("modal")
  );
};
export default AddNewInventoryCount;
