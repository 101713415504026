import { useState } from "react";
import { FaRegUserCircle } from "react-icons/fa";
import { FaRegUser } from "react-icons/fa";
import { RiLogoutCircleLine } from "react-icons/ri";

const UserBtnGroup = ({ onLogout }) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const ontoggle = () => {
    setShowDropDown((state) => !state);
  };
  return (
    <div class="btn-group">
      <button
        type="button"
        className={`btn dropdown-toggle  ${showDropDown ? "show" : ""}`}
        data-bs-toggle="dropdown"
        aria-expanded={`${showDropDown ? "true" : "false"}`}
        onClick={ontoggle}
        title="Logout"
      >
        <FaRegUserCircle style={{ color: "#fff", fontSize: "1.3em" }} />
        <span className="visually-hidden">Toggle Dropdown</span>
      </button>
      <ul
        className={`dropdown-menu dropdown-menu-end ${
          showDropDown ? "show" : ""
        }`}
      >
        {/* <li>
          <h5 class="text-center">User Name</h5>
          <hr></hr>
        </li> */}
        <li>
          <button
            type="button"
            className="dropdown-item btn btn-light"
            onClick={() => {
              alert("primar");
            }}
          >
            <FaRegUser /> Account
          </button>
        </li>
        <li>
          <button
            type="button"
            className="dropdown-item btn btn-light"
            onClick={onLogout}
          >
            <RiLogoutCircleLine /> Logout
          </button>
        </li>
      </ul>
    </div>
  );
};
export default UserBtnGroup;
