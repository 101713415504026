import { useState, useEffect } from "react";
import useHttp from "../../hooks/useHttp";
const SelectMenuItems = ({
  sold_qty,
  value,
  onChange,
  onBlur,
  handleSetMenuPrice,
}) => {
  const [_, handleHttpRequest] = useHttp();
  const [menuItems, setMenuItems] = useState([]);

  const getMenuItems = async () => {
    const response = await handleHttpRequest({
      page: "menuitems",
      method: "GET",
    });
    if (!response.status) {
      setMenuItems(response);
    }
  };
  useEffect(() => {
    getMenuItems();
  }, []);

  const handleChange = (event) => {
    let menu_price = menuItems.filter((menu) => menu.id == event.target.value);
    const ext_price =
      sold_qty != "" ? menu_price[0].price * sold_qty : menu_price[0].price;
    onChange(event);
    handleSetMenuPrice(menu_price[0].price);
  };

  return (
    <select
      className="form-select"
      name="menu_item_id"
      id="menu_item_id"
      value={value}
      onChange={handleChange} //{formik.handleChange}
      onBlur={onBlur}
    >
      <option selected>Select Menu Item</option>
      {menuItems == undefined
        ? ""
        : menuItems.length == 0
        ? "No data"
        : menuItems.map((menuitem) => (
            <option key={menuitem.id} value={menuitem.id}>
              {menuitem.name}
            </option>
          ))}
    </select>
  );
};

export default SelectMenuItems;
