import React, { useRef, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useFormik, ErrorMessage } from "formik";
import * as Yup from "yup";
import useHttp from "../../hooks/useHttp";
import style from "../../assets/style/modal.module.css";
import units from "../../utils/units";
import useConversion from "../../hooks/useConversion";
import { IoIosSave } from "react-icons/io";

const EditRecipeItemModal = ({
  recipe_id,
  recipeItem_id,
  onSave,
  onClick,
  recipe_name,
}) => {
  const [hasError, handleHttpRequest] = useHttp();
  const [initialValues, setInitialValues] = useState({
    recipe_id: recipe_id,
    ingredient_id: "",
    unit_size: "",
    unit_measure: "",
  });
  const [IsStandardUnit, convertWeight] = useConversion();

  const closeModal = () => {
    formik.resetForm();
    onClick();
  };

  const editRecipeItem = async (values) => {
    // const response = await handleHttpRequest(
    //   "recipeitem/edit/" + recipeItem_id,
    //   "PUT",
    //   values
    // );
    const response = await handleHttpRequest({
      page: "recipeitem/edit/" + recipeItem_id,
      method: "PUT",
      data: values,
    });
    if (!hasError) {
      onSave(response);
      formik.resetForm();
    } else {
      closeModal();
    }
  };

  const getRecipeItem = async () => {
    // const response = await handleHttpRequest("recipeitem/" + recipeItem_id);
    const response = await handleHttpRequest({
      page: "recipeitem/" + recipeItem_id,
      method: "GET",
    });
    if (!hasError) {
      setInitialValues({
        recipe_id: response.recipe_id,
        ingredient_id: response.ingredient_id,
        unit_size: response.unit_size,
        unit_measure: response.unit_measure,
      });
      updateUnitMeasure(response.ingredient.default_unit_measure);
    }
  };

  useEffect(() => {
    getRecipeItem();
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      unit_size: Yup.number()
        .typeError("Yeild quantity must be a number")
        .required("Enter the quantity"),
      unit_measure: Yup.string().required(
        "Select a Measurement for the Ingredient."
      ),
    }),
    onSubmit: (values) => {
      editRecipeItem(values);
    },
  });

  const nonStandUnit = useRef({});
  const [unitMeasure, setUnitMeasure] = useState("");
  const updateUnitMeasure = (value) => {
    setUnitMeasure(value);
    if (IsStandardUnit(value) != "Y") {
      nonStandUnit.current = units.filter((unit) => unit.unit == value)[0];
    }
  };

  return (
    <>
      <div className={style["modal-overlay"]}></div>
      <div className={style["modal-card-wrapper"]}>
        <div className={`card  ${style["modal-card"]} w-75`} role="dialog">
          <div className="modal-dialog w-100">
            <div className="modal-content">
              <div
                className={` ${style["modal-header"]} text-bg-secondary`}
                data-bs-theme="dark"
              >
                <h5 className="modal-title" id="staticBackdropLabel">
                  RECIPE INGREDIENT FOR {recipe_name}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={closeModal}
                  aria-label="Close"
                ></button>
              </div>
              <div className={style["modal-content"]}>
                <div className="modal-body row g-3">
                  <form className="" onSubmit={formik.handleSubmit}>
                    <div className="row">
                      <div className="col-lg-3 col-sm-6">
                        <fieldset>
                          <label
                            htmlFor="unit_measure"
                            className="lblrequired lbltheme"
                          >
                            Measurement*
                          </label>
                          <select
                            className="form-select"
                            name="unit_measure"
                            id="unit_measure"
                            value={formik.values.unit_measure}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          >
                            <option selected>Select Measurement</option>
                            {IsStandardUnit(unitMeasure) == "Y" ? (
                              units.map((unit) => (
                                <option key={unit.id} value={unit.unit}>
                                  {unit.name}
                                </option>
                              ))
                            ) : (
                              <option
                                key={nonStandUnit.current.id}
                                value={nonStandUnit.current.unit}
                              >
                                {nonStandUnit.current.name}
                              </option>
                            )}
                          </select>
                        </fieldset>
                      </div>
                      <div className="col-lg-3 col-sm-4">
                        <fieldset>
                          <label
                            htmlFor="unit_size"
                            class="lblrequired lbltheme"
                          >
                            Quantity
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="unit_size"
                            name="unit_size"
                            value={formik.values.unit_size}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </fieldset>
                      </div>
                      <div className="col-sm-1 d-flex align-self-end">
                        <button
                          type="submit"
                          className="btn btn-link btn-just-icon"
                          id="SaveIngred"
                          name="SaveIngred"
                          title="Save"
                        >
                          <IoIosSave className="save-icon" />
                        </button>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div id="qadd-ingred-errmsg">
                        {formik.errors.unit_measure
                          ? formik.errors.unit_measure
                          : formik.errors.unit_size
                          ? formik.errors.unit_size
                          : null}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const EditRecipeItem = (props) => {
  return ReactDOM.createPortal(
    <EditRecipeItemModal
      recipe_id={props.recipe_id}
      recipeItem_id={props.recipeItem_id}
      recipe_name={props.recipe_name}
      onSave={props.onSave}
      onClick={props.onClick}
    />,
    document.getElementById("modal")
  );
};

export default EditRecipeItem;
