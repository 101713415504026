import { Link } from "react-router-dom";
import useHttp from "../../hooks/useHttp";
import { useNavigate } from "react-router-dom";

export const EmailVerified = () => {
  return (
    <div className="d-flex justify-content-center mt-4">
      <div className="card w-50">
        <div className="card-body d-flex align-items-center flex-column p-5">
          <p class="fs-2">Congratulations!</p>
          <p>You can now use usl app. Please login</p>
          <Link className="btn btntheme" to="/login">
            Back to Login
          </Link>
        </div>
      </div>
    </div>
  );
};

export const VerifyEmail = () => {
  return (
    <div className="d-flex justify-content-center mt-4">
      <div className="card">
        <div className="card-body">
          <p>
            We have sent a confirmation email to your account please, confirm to
            proceed.
          </p>
        </div>
      </div>
    </div>
  );
};

export const ResendVerificationEmail = ({ children, email, password }) => {
  const [hasError, handleHttpRequest] = useHttp();
  const navigate = useNavigate();

  const handleEmailVerificationCodeResend = async () => {
    const response = await handleHttpRequest({
      page: "email/resend",
      method: "POST",
      data: { email, password },
    });
    if (!response.status) {
      return navigate("/verifiy-email");
    } else {
      alert(response.data.message);
    }
  };

  return (
    <button
      className="btn btn-secondary mx-2"
      onClick={handleEmailVerificationCodeResend}
    >
      {children}
    </button>
  );
};
