import MainPanel from "../../components/MainPanel";
import useHttp from "../../hooks/useHttp";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";

const ViewInventoryCount = () => {
  const { id: inventory_id } = useParams();
  const [_, handleHttpRequest] = useHttp();
  const [inventories, setInventories] = useState([]);
  const [initialValue, setInitialValue] = useState({
    date: "",
    location: "",
  });

  const getInventory = async () => {
    const response = await handleHttpRequest({
      page: "inventory-count/" + inventory_id,
      method: "GET",
    });
    if (!response.status) {
      setInitialValue({
        date: response?.date_time,
        location: response.storagelocation.name,
      });
    } else {
      // saveClick(response, response.status); toast notification
    }
  };
  const getIngredientsLocations = async () => {
    const response = await handleHttpRequest({
      page: "ingredients-inventory-count/" + inventory_id,
      method: "GET",
    });
    if (!response.status) {
      setInventories(response);
      //   setIngredientsForLocation(response);
    } else {
      // saveClick(response, response.status); toast notification
    }
  };
  useEffect(() => {
    getInventory();
    getIngredientsLocations();
  }, []);

  return (
    <>
      <MainPanel>
        <div className="card">
          <div className="card-header text-bg-secondary">
            <div className="row">
              <h4 className="card-title">Inventory Count</h4>
            </div>
          </div>
          <div className="card-body">
            <div className="toolbar"></div>
            <div className="row mb-4">
              <div className="col-sm-3">{initialValue.date}</div>
              <div className="col-sm-3">{initialValue.location}</div>
            </div>
            <table className="table">
              <thead>
                <tr>
                  <th>Location</th>
                  <th>Ingredient</th>
                  <th>Size On-Hand</th>
                  <th>Qty On-Hand</th>
                  <th>Qty in Dflt Unit</th>
                </tr>
              </thead>
              <tbody>
                {inventories.map((inventory) => {
                  return (
                    <tr>
                      <td>{initialValue.location}</td>
                      <td>{inventory.ingredient.name}</td>
                      <td>{inventory.size_on_hand}</td>
                      <td>{inventory.qty_on_hand}</td>
                      <td>
                        {inventory.qty_on_hand} {inventory.size_on_hand}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </MainPanel>
      <ToastContainer position="bottom-right" hideProgressBar />
    </>
  );
};

export default ViewInventoryCount;
