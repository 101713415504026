import { useState, useEffect, useRef, useContext } from "react";
import useHttp from "../../hooks/useHttp";
import CartTable from "./CartTable";
import MainPanel from "../../components/MainPanel";
import ListCardHeader from "../../templates/listpagetemplate/ListCardHeader";
import EditCartItem from "./EditCartItem";
import DeleteModal from "../DeleteModal";
import { ToastContainer, toast } from "react-toastify";
import { FaCloudUploadAlt } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import AuthContext from "../../store/LoginContext";

const ShoppingCart = () => {
  const [cartItems, setCartItems] = useState();
  const [_, handleHttpRequest] = useHttp();
  const [showEditItem, setShowEditItem] = useState();
  const cartItem = useRef();
  const [refreshBE, setRefreshBE] = useState();
  const { notification, updateNotification } = useContext(AuthContext);

  const getShoppingCart = async () => {
    const response = await handleHttpRequest({
      page: "cart",
      method: "GET",
    });
    if (!response.status) {
      setCartItems(response);
    } else {
      // toast.error(response);
    }
  };

  useEffect(() => {
    getShoppingCart();
  }, [refreshBE]);

  // Edit Cart Item
  const handleEditClick = (response) => {
    setShowEditItem(false);
    if (!response.status) {
      toast.info(response);
      setRefreshBE((state) => !state);
    } else {
      toast.error(response);
    }
  };
  const toggleEditClick = (item) => {
    // cartId.current = id;
    // ingredientId.current = ingId;
    cartItem.current = item;
    setShowEditItem((prev) => !prev);
  };

  // Delete Cart Item
  const deleteIdIs = useRef(0);
  const [isdeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const openDeleteModal = (id) => {
    deleteIdIs.current = id;
    setIsDeleteModalOpen((state) => !state);
  };
  const deleteHandler = (data) => {
    setIsDeleteModalOpen(false);
    toast.info(data[0]);
    setRefreshBE((state) => !state);
    // setIsCartUpdated((state) => !state);
  };

  // Submit purchase order to supplier
  const handleSubmitOrder = async (cartItem) => {
    const response = await handleHttpRequest({
      page: "purchaseorder",
      method: "POST",
      data: { supplierId: cartItem[0].supplierId },
    });
    if (!response.status) {
      toast.info(response);
      setRefreshBE((state) => !state);
      //update notification
      updateNotification(notification - 1);
    } else {
      toast.error(response);
    }
  };

  // Empty Cart
  const deleteCartIdIs = useRef(0);
  const [isdeleteCartModalOpen, setIsDeleteCartModalOpen] = useState(false);
  const openDeleteCartModal = (id) => {
    deleteCartIdIs.current = id;
    setIsDeleteCartModalOpen((state) => !state);
  };
  const deleteCartHandler = (data) => {
    setIsDeleteCartModalOpen(false);
    toast.info(data[0]);
    setRefreshBE((state) => !state);
    // setIsCartUpdated((state) => !state);
    updateNotification(notification - 1);
  };

  return (
    <>
      {showEditItem && (
        <EditCartItem
          // cartId={cartId.current}
          // ingredientId={ingredientId.current}
          cartItem={cartItem.current}
          onCancle={toggleEditClick}
          onEdit={handleEditClick}
        />
      )}
      {isdeleteModalOpen && (
        <DeleteModal
          deleteId={deleteIdIs.current}
          onClick={openDeleteModal}
          onDelete={deleteHandler}
          item="cart"
          name="cart item"
        />
      )}
      {isdeleteCartModalOpen && (
        <DeleteModal
          deleteId={deleteCartIdIs.current}
          onClick={openDeleteCartModal}
          onDelete={deleteCartHandler}
          item="emptycart"
          name="cart items by supplier"
        />
      )}
      <MainPanel>
        <div className="card">
          <ListCardHeader title="Carts"></ListCardHeader>
          <div className="card-body">
            {cartItems &&
              Object.values(cartItems)?.map((cartItem) => {
                return (
                  <div className="card mt-4">
                    <div className="card-header d-flex justify-content-between btntheme">
                      <div>
                        <h5>{cartItem[0]?.supplier}</h5>
                      </div>
                      <div>
                        {cartItem[0].supplierId && (
                          <>
                            <button
                              className="btn btn-icon text-light p-1"
                              title="Empty cart"
                            >
                              <RiDeleteBin6Line
                                className="fs-5"
                                onClick={() => {
                                  openDeleteCartModal(cartItem[0].supplierId);
                                }}
                              />
                            </button>
                            <button
                              className="btn btn-icon text-light p-1"
                              title="Submit order to Supplier"
                            >
                              <FaCloudUploadAlt
                                className="fs-4"
                                onClick={() => {
                                  handleSubmitOrder(cartItem);
                                }}
                              />
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="card-body overflow-auto">
                      <table className="table min-width w-100">
                        <thead>
                          <tr>
                            <th>Item Name</th>
                            <th>Qty to Order</th>
                            <th>Qty per sell by</th> {/*(e.g. 2case) */}
                            <th>Price per sell by</th>
                            <th>Extended Price</th>
                            <th>Minimum Order Qty</th>
                            <th>Description</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {cartItem?.map((item) => (
                            <CartTable
                              item={item}
                              onEditClick={toggleEditClick}
                              onDeleteClick={openDeleteModal}
                            />
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </MainPanel>
      <ToastContainer position="bottom-right" hideProgressBar />
    </>
  );
};

export default ShoppingCart;
