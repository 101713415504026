import { useState, useEffect } from "react";
import useHttp from "../../hooks/useHttp";

const StorageLocations = ({
  setStorage,
  value,
  onChange,
  onBlur,
  storageSaved,
}) => {
  const [storageLocations, setStorageLocations] = useState([]);
  const [_, handleHttpRequest] = useHttp();

  const getStorageLocations = async () => {
    const response = await handleHttpRequest({
      page: "storage-locations",
      method: "GET",
    });
    if (!response.status) {
      setStorageLocations(response);
    }
  };

  const handChange = (event) => {
    const storage = storageLocations.find(
      (location) => location.id == event.target.value
    );
    setStorage(storage);
    onChange(event);
  };
  useEffect(() => {
    getStorageLocations();
  }, [storageSaved]);

  return (
    <select
      class="form-select"
      name="storage_location_id"
      id="storage_location_id"
      value={value}
      onChange={handChange}
      onBlur={onBlur}
    >
      <option key="0" value="0" selected></option>
      {storageLocations == undefined
        ? ""
        : storageLocations.length == 0
        ? "No data"
        : storageLocations.map((storageLocation) => (
            <option key={storageLocation.id} value={storageLocation.id}>
              {storageLocation.name}
            </option>
          ))}
    </select>
  );
};

export default StorageLocations;
