import { useState, useEffect } from "react";
import useHttp from "../../hooks/useHttp";

const SelectIngredients = ({
  value,
  onChange,
  onBlur,
  setUnitMeasure,
  setIngredient,
}) => {
  const [ingredients, setIngredients] = useState([]);
  const [hasError, handleHttpRequest] = useHttp();

  const getIngredients = async () => {
    // const response = await handleHttpRequest('ingredients')
    const response = await handleHttpRequest({
      page: "ingredients",
      method: "GET",
    });
    if (!hasError) {
      setIngredients(response.message);
    }
  };

  useEffect(() => {
    getIngredients();
  }, []);

  const handleChange = (event) => {
    let ing = ingredients.filter(
      (ingredient) => ingredient.id == event.target.value
    );
    onChange(event);
    setUnitMeasure(ing[0].default_unit_measure);
    setIngredient(ing[0]);
  };

  return (
    <>
      <select
        class="form-select"
        name="ingredient_id"
        id="ingredient_id"
        value={value}
        onChange={handleChange}
        onBlur={onBlur}
      >
        <option selected>Select Ingredients</option>
        {ingredients == undefined
          ? ""
          : ingredients.length == 0
          ? "No data"
          : ingredients.map((ingredient) => (
              <>
                <option key={ingredient.id} value={ingredient.id}>
                  {ingredient.name}{" "}
                  <input
                    type="hidden"
                    name="ingredient"
                    value={ingredient.ingredient}
                  />
                </option>
              </>
            ))}
      </select>
    </>
  );
};

export default SelectIngredients;
