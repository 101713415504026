import MainPanel from "../../components/MainPanel";
import { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import useHttp from "../../hooks/useHttp";
import Table from "../../components/Table";
import { FaRegEdit, FaPlus } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import DeleteModal from "../DeleteModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddSale from "./AddSale";
import CSVUploader from "../../utils/csvUploader";
import UploadSaleHistoryModal from "./UploadSaleHistory";
import CustomImporter from "../../utils/CustomImporter";

const Sales = () => {
  const [hasError, handleHttpRequest] = useHttp();
  const [isSalesUpdated, setIsSalesUpdated] = useState();
  const [sales, setSales] = useState([]);
  const getSales = async () => {
    const response = await handleHttpRequest({
      page: "sales",
      method: "GET",
    });
    if (!response.status) {
      setSales(response);
    }
  };
  useEffect(() => {
    getSales();
  }, [isSalesUpdated]);

  const COLUMNS = [
    {
      Header: "Sales Date",
      accessor: "date",
    },
    {
      Header: "Sales Qty",
      accessor: "sold_qty",
    },
    {
      Header: "Sales Total",
      accessor: "sold_price",
      Cell: ({ value }) => (value != undefined ? "$" + value.toFixed(2) : ""),
    },
    {
      Header: "Actions",
      accessor: "id",
      disableSortBy: true,
      Cell: (cell) => (
        <div>
          <button
            className="btn btn-link btn-just-icon icontheme editcount"
            style={{ color: "#FF7300" }}
          >
            <Link to={`/edit-sale/${cell.value}`} className="themecolor">
              <FaRegEdit />{" "}
            </Link>
          </button>
          <button
            className="btn btn-link btn-just-icon iconthemedel delcount"
            style={{ color: "#676767" }}
            onClick={() => {
              openDeleteModal(cell.value);
            }}
          >
            <RiDeleteBin6Line />
          </button>
        </div>
      ),
    },
  ];

  const saveSale = (message) => {
    toast.info(message);
    setIsSalesUpdated((state) => !state);
  };

  /* Delete Modal */
  const deleteIdIs = useRef(0);
  const [isdeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const openDeleteModal = (id) => {
    deleteIdIs.current = id;
    setIsDeleteModalOpen((state) => !state);
  };
  const deleteHandler = (data) => {
    setIsDeleteModalOpen(false);
    toast.info(data[0]);
    setIsSalesUpdated((state) => !state);
  };
  const [isUploadModalOpen, setisUploadModalOpen] = useState(false);
  const toggleUpload = () => {
    setisUploadModalOpen((state) => !state);
  };
  const handleUploadResponse = (response) => {
    setisUploadModalOpen(false);
    toast.info(response);
    setIsSalesUpdated((state) => !state);
  };
  const handleErrorResponse = (message) => {
    setisUploadModalOpen(false);
    toast.error(message);
  };

  return (
    <>
      <MainPanel>
        {isUploadModalOpen && (
          // <UploadSaleHistoryModal
          //   onClose={toggleUpload}
          //   onUpload={handleUploadResponse}
          //   onError={handleErrorResponse}
          // />
          <CustomImporter
            onClose={toggleUpload}
            onUpload={handleUploadResponse}
            onError={handleErrorResponse}
          />
        )}
        {isdeleteModalOpen && (
          <DeleteModal
            deleteId={deleteIdIs.current}
            onClick={openDeleteModal}
            onDelete={deleteHandler}
            item="sale"
            name="sale history"
          />
        )}
        <div className="card">
          <div className="card-header text-bg-secondary">
            <div className="row">
              <h4 className="card-title">SALES HISTORY</h4>
              {/* <CSVUploader /> */}
            </div>
          </div>
          <div className="card-body">
            <button
              className="btn btntheme"
              onClick={toggleUpload}
              style={{ float: "right" }}
            >
              Upload Sale History
            </button>
            <AddSale saveClick={saveSale} />
            <Table data={sales} columns={COLUMNS} />
          </div>
        </div>
      </MainPanel>
      <ToastContainer position="bottom-right" hideProgressBar />
    </>
  );
};

export default Sales;
